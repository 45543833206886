import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import CookieConsent from 'react-cookie-consent';
import MetaTags from 'react-meta-tags';

import configureStore from './store/configureStore';
import Routes from './Routes';
import App from './containers/App';
import HeaderContainer from './containers/Header';
import Footer from './components/Footer';
import AuthModalContainer from './containers/AuthModal';
import Notifier from './containers/Notifier';
import Router from './Router';
import ActionCableProvider from './components/ActionCable/Provider';
import { WEB_SOCKET_URL, GOOGLE_ANALYTICS_ID } from './constants/utils';
import ScrollToTop from './components/ScrollToTop';
import PatreonModal from './components/PatreonModal';
import BnetAlert from './components/BnetAlert';
import 'normalize.css';
import './styles/global.scss';

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

const Root = ({ path, storeState }) => {
  const store = configureStore(storeState);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
      <MetaTags>
        <title>WoW Arena PvP Inspect, Rankings & LFG System | ArenaMaster</title>
        <meta
          name="description"
          content="ArenaMaster is a World of Warcraft (WoW) PvP Inspect & Rankings web app. You can easily check rating & experience of any player and use the LFG to find arena partners!"
        />
      </MetaTags>

      <ActionCableProvider url={WEB_SOCKET_URL}>
        <App>
          <Router path={path}>
            <ScrollToTop>
              <div className="wrapper">
                <div id="content">
                  <HeaderContainer />
                  <BnetAlert />
                  <Routes />
                  <AuthModalContainer />
                  <PatreonModal />
                  <Notifier />
                  <CookieConsent
                    containerClasses="cookie"
                    buttonClasses="cookie-btn"
                    buttonText="Got it!"
                    disableButtonStyles
                  >
                    This website uses cookies to improve your experience and enable certain
                    functionality. By continuing to use this website, you agree to our{' '}
                    <Link to="/cookie-policy" style={{ textDecoration: 'underline' }}>
                      Cookie Policy
                    </Link>
                    ,{' '}
                    <Link to="/privacy" style={{ textDecoration: 'underline' }}>
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link to="/terms" style={{ textDecoration: 'underline' }}>
                      Terms of Use
                    </Link>
                    .
                  </CookieConsent>
                </div>
                <Footer />
              </div>
            </ScrollToTop>
          </Router>
        </App>
      </ActionCableProvider>
    </Provider>
  );
};

Root.propTypes = {
  path: PropTypes.string,
  storeState: PropTypes.object,
};

Root.defaultProps = {
  path: '/',
  storeState: {
    auth: {
      currentAccount: {
        isLoading: false,
        isSignedIn: false,
        attributes: {
          username: '',
          email: '',
          name: '',
        },
      },
    },
  },
};

export default Root;
