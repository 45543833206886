import { Component } from 'react';
import PropTypes from 'prop-types';
import CableContext from './Context';

class Cable extends Component {
  static contextType = CableContext;

  static propTypes = {
    onReceived: PropTypes.func,
    onInitialized: PropTypes.func,
    onConnected: PropTypes.func,
    onDisconnected: PropTypes.func,
    onRejected: PropTypes.func,
    children: PropTypes.any,
    channel: PropTypes.object,
  };

  static defaultProps = {
    onReceived: () => {},
    onInitialized: () => {},
    onConnected: () => {},
    onDisconnected: () => {},
    onRejected: () => {},
    children: null,
    channel: {},
  };

  componentDidMount() {
    const {
      channel,
      onReceived,
      onInitialized,
      onConnected,
      onDisconnected,
      onRejected,
    } = this.props;

    this.cable = this.context.subscriptions.create(channel, {
      received(data) {
        onReceived(data);
      },
      initialized() {
        onInitialized();
      },
      connected() {
        onConnected();
      },
      disconnected() {
        onDisconnected();
      },
      rejected() {
        onRejected();
      },
    });
  }

  componentWillUnmount() {
    if (this.cable) {
      this.context.subscriptions.remove(this.cable);
      this.cable = null;
    }
  }

  send(data) {
    if (!this.cable) {
      throw new Error('ActionCable component unloaded');
    }

    this.cable.send(data);
  }

  perform(action, data) {
    if (!this.cable) {
      throw new Error('ActionCable component unloaded');
    }

    this.cable.perform(action, data);
  }

  render() {
    return this.props.children;
  }
}

export default Cable;
