import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { getRegionIcon } from '../../utils/helpers';
import MaterialDropdown from '../MaterialDropdown';
import './RegionChange.scss';

const RegionChange = ({ region, handleRegionChange }) => {
  const history = useHistory();
  const tbcRankings = useRouteMatch('/tbc-rankings/:region/:bracket');

  const regionChange = region => {
    handleRegionChange(region);

    if (tbcRankings) {
      const { bracket } = tbcRankings.params;
      history.replace(`/tbc-rankings/${region.toLowerCase()}/${bracket}`);
    }
  };

  return (
    <MaterialDropdown
      label={region}
      labelImage={getRegionIcon(region)}
      dropdownItems={[
        {
          name: 'US',
          image: getRegionIcon('US'),
        },
        {
          name: 'EU',
          image: getRegionIcon('EU'),
        },
      ]}
      onItemClicked={regionChange}
    />
  );
};

RegionChange.propTypes = {
  region: PropTypes.string.isRequired,
  handleRegionChange: PropTypes.func.isRequired,
};

export default RegionChange;
