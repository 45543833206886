import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import history from './store/history';
import GoogleAnalytics from './utils/GoogleAnalyticsListener';
import { isServer } from './utils/helpers';

const SSRouter = ({ children, path }) => {
  if (isServer()) {
    return (
      <StaticRouter location={path} context={{}}>
        {children}
      </StaticRouter>
    );
  }

  return (
    <Router history={history}>
      <GoogleAnalytics history={history}>{children}</GoogleAnalytics>
    </Router>
  );
};

SSRouter.propTypes = {
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default SSRouter;
