import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  root: {
    width: 69,
    height: 50,
  },

  switchBase: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },

    '&$checked': {
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },

      transform: 'translateX(24px)',
      '& + $track': {
        backgroundColor: '#c43244',
        borderColor: '#c43244',
        opacity: 1,
      },
    },
  },

  checked: {
    transform: 'translateX(23px)',
    '& + $track': {
      opacity: 1,
      border: '1px solid #0550a7',
    },
  },

  track: {
    borderRadius: 19,
    backgroundColor: 'rgba(83, 90, 98, 1)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },

  thumb: {
    width: 26,
    height: 24,
  },

  checked: {
    boxShadow: theme.shadows[1],
  },
}));

const SwitchButton = ({ styleSwitchBtn, checked, handleChange }) => {
  const classes = useStyles();

  return (
    <FormGroup row>
      <FormControlLabel
        style={
          styleSwitchBtn
            ? { marginRight: 0 }
            : { position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }
        }
        control={
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            disableRipple
            checked={checked}
            onChange={handleChange}
            value="checked"
          />
        }
      />
    </FormGroup>
  );
};

SwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  styleSwitchBtn: PropTypes.bool,
};

SwitchButton.defaultProps = {
  styleSwitchBtn: false,
};

export default SwitchButton;
