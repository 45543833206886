import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CharacterList from '../components/CharacterList/';
import {
  fetchCharacters as fetchCharactersAction,
  updateMainCharacter as updateMainCharacterAction,
  updateAltHidden as updateAltHiddenAction,
} from '../actions/characters';

class CharacterListContainer extends Component {
  componentDidMount() {
    const { fetchCharacters, characters, currentAccount, updateMainCharacter } = this.props;

    if (currentAccount.bnet_connected && characters.length === 0) {
      fetchCharacters().then(charList => {
        if (charList.length === 0 || charList.some(char => char.main)) return;

        const maxValue = charList.reduce(
          (max, char) => (char.exp_3v3 > max ? char.exp_3v3 : max),
          charList[0].exp_3v3
        );

        const character = charList.find(char => char.exp_3v3 === maxValue);

        if (character) updateMainCharacter(character);
      });
    }
  }

  render() {
    return <CharacterList {...this.props} />;
  }
}

CharacterListContainer.propTypes = {
  region: PropTypes.string.isRequired,
  characters: PropTypes.array,
  currentAccount: PropTypes.object.isRequired,
  fetchCharacters: PropTypes.func.isRequired,
  updateMainCharacter: PropTypes.func.isRequired,
  updateAltHidden: PropTypes.func.isRequired,
};

CharacterListContainer.defaultProps = {
  characters: [],
};

const mapStateToProps = ({
  characters: { connectedCharacters },
  auth: { currentAccount },
  utils: { region },
}) => ({
  characters: connectedCharacters,
  currentAccount,
  region,
});

export default connect(mapStateToProps, {
  fetchCharacters: fetchCharactersAction,
  updateMainCharacter: updateMainCharacterAction,
  updateAltHidden: updateAltHiddenAction,
})(CharacterListContainer);
