import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import { openAuthModal } from '../actions/auth';

const RegisterCtaButtonContainer = ({ handleClick }) => (
  <Button
    className="primary-btn"
    style={{ width: 230, marginTop: 30 }}
    variant="contained"
    onClick={handleClick}
  >
    Join now
  </Button>
);

RegisterCtaButtonContainer.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default connect(undefined, {
  handleClick: openAuthModal.bind(null, 'SignUp'),
})(RegisterCtaButtonContainer);
