import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MetaTags } from 'react-meta-tags';

import { isServer } from 'utils/helpers';
import Hero from 'components/Hero';
import AnvilIcon from 'assets/integrations/anvil.svg';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import DynamicAd300x250 from 'app/components/Ads/DynamicAd300x250';
import RichAd from 'app/components/Ads/RichAd';
import './Addon.scss';

const Addon = () => {
  useEffect(() => {
    const handleScroll = () => {
      if (isServer()) return;

      const buttons = document.getElementById('buttons');

      if (window.scrollY > 0) {
        buttons.classList.add('black-bg');
      } else {
        buttons.classList.remove('black-bg');
      }
    };

    if (!isServer()) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="addon-test">
      <RichAd />

      <MetaTags>
        <title>WoW PvP Inspect AddOn | ArenaMaster</title>
        <meta
          name="description"
          content="ArenaMaster World of Warcraft PvP Inspect AddOn lets you easily check any player's 2v2 & 3v3 arena and RBG stats and get a direct link to their profile"
        />
      </MetaTags>

      <Hero />

      <div className="addon-page">
        <Grid container justify="center">
          <Grid item xs={12} md={12} lg={3}>
            <DynamicAd300x250 className="addon" />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <div id="buttons">
              <a href="https://www.curseforge.com/wow/addons/arenamaster/download?client=y">
                <Button className="twitch-btn">
                  <AnvilIcon width={30} height={30} style={{ marginRight: '5px', fill: '#fff' }} />
                  Install with CurseForge
                </Button>
              </a>

              <a
                href="https://www.curseforge.com/wow/addons/arenamaster/download"
                target="_blank"
                rel="noopener"
              >
                <Button
                  className="curse-forge-purple"
                  variant="outlined"
                  style={{ borderWidth: '2px' }}
                >
                  <GetAppIcon width={30} height={30} style={{ marginRight: '5px', fill: '#fff' }} />
                  Download from CurseForge
                </Button>
              </a>
            </div>

            <h1>ArenaMaster PvP Inspect AddOn</h1>

            <h2>Overview</h2>

            <p>
              This is a utility addon for the ArenaMaster web app. By installing this addon, you'll
              be able to easily check any player's arena and rbg experience without having to leave
              the game as well as get a direct link to their ArenaMaster profile.
            </p>

            <p>
              With the new addon version, you can see even more PvP info, like current season
              performance, account achievements, and overall character stats like versatility and
              health.
            </p>

            <h2>Unit Frame Hover</h2>

            <p>
              You can hover over a player's unit frame in the open world and see at a glance the
              highest 2v2, 3v3 and rbg rating they've achieved on the character, or the overall best
              rating if the player has an ArenaMaster account.
            </p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/NewTooltip.png"
              alt="Unit Frame Hover"
            />

            <h2>Settings</h2>

            <p>
              With the new update you can control which info you're interested in seeing when in the
              open world / LFG and you also have a separate config for when you're in arenas or
              battlegrounds. Type <strong style={{ color: '#c72429' }}>/ampvp</strong> in chat to
              bring up the settings interface.
            </p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/TooltipSettings.png"
              alt="Unit Frame Settings"
            />

            <h2>Battle.net Friends Hover</h2>

            <p>
              You can also hover over your Battle.net friends list and quickly determine the
              experience of your friend's currently played character. Combine this with in-game
              notes for your friends and a couple of useful addons like{' '}
              <strong>Friend List Colors</strong> and <strong>Friend Groups</strong> and your friend
              list suddenly becomes a more personal LFG experience.
            </p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/BattleNetHoverTooltip.png"
              alt="Battle.net friends hover"
              style={{ maxWidth: '100%' }}
            />

            <h2>LFG Hovers</h2>

            <p>
              Whether you're creating a group or applying to someone else's, the ArenaMaster addon
              is here to help you determine the right fit as fast as possible.
            </p>

            <h2>As a group owner</h2>

            <p>
              When reviewing group applications, quickly check the experience level to determine a
              potential match, before going to the website to see their full profile info.
            </p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/LFGGroupOwner.png"
              alt="Group Application"
              style={{ maxWidth: '100%' }}
            />

            <h2>Applying to groups</h2>

            <p>When applying to groups, you can check the experience of the group owner.</p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/LFGHoverTooltip.png"
              alt="Group Application"
              style={{ maxWidth: '100%' }}
            />

            <h2>Copy ArenaMaster Profile</h2>

            <p>
              In addition, you can right-click any character's unit frame to get their ArenaMaster
              Profile URL where you can see their full PvP information including current rating, win
              rates, gear, talents, achievements and much more.
              <br />
              <br />
              Apart from unit frames, you can get the link from anywhere in-game by right-clicking
              the names in whispers, guild chat, LFG and other places.
              <br />
              <br />
              The URL is already selected. All you have to do is <code>CTRL + C</code> the link and
              paste it in your browser of choice to get directly to their ArenaMaster Profile.
            </p>

            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/InspectProfileLinkTooltip.png"
              alt="AremaMaster profile"
              style={{ width: '100%' }}
            />

            <h2>Installation</h2>

            <p>
              If you're using the Twitch app to manage your addons, open the app and navigate to the
              Mods -&gt; Get More Addons section where you can find the addon by name:{' '}
              <strong style={{ color: '#c72429' }}>ArenaMaster PvP Inspect</strong>
              <br />
              <br />
              In case you prefer to download the zip and unpack it in your addons directory yourself
              Download from CurseForge. After you unzip the folder move the inner 3 folders to your
              AddOns directory.
              <br />
              <br />
              Make sure to keep an eye out for updates and update when available. As the web app and
              addon are in the early stages, there is a chance you'll encounter players that have no
              data you can view with the addon. You can visit their ArenaMaster profiles in order to
              add them to our database, and their character data will be available in-game on the
              next update.
              <br />
              <br />
              As more players start to use the web app, the data will be updated more frequently and
              the addon will become more and more useful.
            </p>
          </Grid>

          <Grid item xs={12} md={12} lg={3}>
            <DynamicAd300x250 className="addon" />
          </Grid>
        </Grid>

        <DynamicAd970x250 />
      </div>
    </div>
  );
};

export default Addon;
