import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MetaTags } from 'react-meta-tags';
import get from 'lodash/get';

import Hero from 'components/Hero';
import Axios from 'utils/axios';
import { API_URL } from 'constants/auth';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import { successNotification } from 'actions/notifications';
import './ContactUs.scss';

const schema = yup.object().shape({
  name: yup.string().required('is required'),
  email: yup
    .string()
    .email('is not a valid email')
    .required('is required'),
  message: yup.string().required('is required'),
});

const bottomAdStyles = { marginTop: '-20px', marginBottom: 20 };

// eslint-disable-next-line react/prop-types, no-shadow
const ContactUsPage = ({ currentAccount, successNotification }) => {
  const handleSubmit = (values, { resetForm }) => {
    Axios.post(`${API_URL}/contact_messages`, {
      contact_message: values,
    }).then(() => {
      resetForm();
      successNotification("Thanks for reaching out. We'll get back to you soon.");
    });
  };

  return (
    <React.Fragment>
      <RichAd />

      <MetaTags>
        <title>Contact | ArenaMaster</title>
      </MetaTags>

      <Hero />

      <DynamicAd970x250 />

      <Grid container className="contact-us-container">
        <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className="contact-us-page">
          <h1>Contact Us</h1>
          <h3>If you have any questions, here's how to get in touch with us.</h3>

          <Formik
            initialValues={{
              name: '',
              email: get(currentAccount, 'email', ''),
              message: '',
              is_bot: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({ isSubmitting }) => (
              <Form id="contact-us-form">
                <div className="form-control">
                  <label htmlFor="name" style={{ fontWeight: '600' }}>
                    Name
                  </label>

                  <ErrorMessage name="name" component="span" className="error" />
                  <Field
                    name="name"
                    type="text"
                    placeholder="Name"
                    margin="normal"
                    className="mt-0 mb-4"
                    autoFocus
                  />
                </div>

                <div className="form-control">
                  <label htmlFor="email" style={{ fontWeight: '600' }}>
                    Email
                  </label>

                  <ErrorMessage name="email" component="span" className="error" />
                  <Field
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    margin="normal"
                    className="mt-0 mb-4"
                  />
                </div>

                <div className="message form-control">
                  <h3>Message</h3>

                  <ErrorMessage name="message" component="span" className="error" />
                  <Field component="textarea" name="message" rows="6" placeholder="Message" />
                </div>

                <div
                  className="form-control"
                  style={{
                    visibility: 'hidden',
                    width: 0,
                    height: 0,
                    position: 'absolute',
                  }}
                >
                  <Field type="checkbox" name="is_bot" />
                </div>

                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  id="save-button"
                  type="submit"
                  className="primary-btn"
                >
                  Send Message
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>

      <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth: { currentAccount } }) => ({
  currentAccount,
});

export default connect(mapStateToProps, { successNotification })(ContactUsPage);
