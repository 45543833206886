import React from 'react';
import PropTypes from 'prop-types';
import RankingsFilterButton from '../RankingsFilterButton';

const FactionFilters = ({ currentFaction, handleFactionChange }) => (
  <div className="filters-button">
    <RankingsFilterButton
      type="Both"
      currentType={currentFaction}
      handleChange={handleFactionChange}
      buttonText="BOTH"
    />
    <RankingsFilterButton
      type="Horde"
      currentType={currentFaction}
      handleChange={handleFactionChange}
      buttonText="HORDE"
    />
    <RankingsFilterButton
      type="Alliance"
      currentType={currentFaction}
      handleChange={handleFactionChange}
      buttonText="ALLIANCE"
    />
  </div>
);

FactionFilters.propTypes = {
  currentFaction: PropTypes.string.isRequired,
  handleFactionChange: PropTypes.func.isRequired,
};

export default FactionFilters;
