import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

import { isServer } from 'utils/helpers';
import SignUpButtonContainer from 'containers/SignUpButton';
import './LfgHero.scss';
import { useLocation } from 'react-router';

const LfgHero = ({ history, lfgCollapsed, toggleLfgCollapsed, currentAccount }) => {
  useEffect(() => {
    if (isServer()) return;

    const content = document.getElementById('content');

    content.classList.add('remove');

    return () => content.classList.remove('remove');
  }, []);

  return (
    <Collapse
      in={currentAccount.isSignedIn ? lfgCollapsed : true}
      className={`lfg-collapse ${lfgCollapsed ? 'open' : 'closed'}`}
      style={{ paddingTop: 0 }}
    >
      <div className="hero-content">
        {history.location.pathname !== 'lfg' && (
          <Grid item xs={11} lg={10} xl={7}>
            <div className="text">
              <div className="why-lfg">
                <h1>Why ArenaMaster LFG?</h1>
                <p>
                  If you're like me, you probably spent <strong>WAY</strong> too much time sitting
                  in LFG scrolling through groups that <strong>MIGHT</strong> be a fit for you. You
                  then apply, copy-pasting your current rating and experience and linking relevant
                  achievements.
                </p>
                <p style={{ marginTop: '30px' }}>
                  Then you repeat that over and over until you get frustrated and decide to create
                  your own group (because for some reason you can't have an active group and apply
                  to others). Now you're AFK and alt-tabbed waiting for your WoW icon to glow orange
                  so you can check who applied. You get back in the game only to see the CR and EXP
                  they noted/whispered and then tab again going to Armory to check if what they
                  wrote is actually true...
                </p>
                <p style={{ marginTop: '30px' }}>
                  So much time is wasted on everything except the one thing you logged on to do -
                  PLAYING WOW ARENA & RBG.
                </p>
                <p>
                  Wouldn't it be easier if you could find arena/rbg partners passively while you're
                  at school, work or actually playing some other content in the game we all love.
                </p>
              </div>

              <div className="lfg-features">
                <div>
                  <h2>Getting started</h2>
                  <p>
                    1. Sign Up for a <strong>FREE</strong> ArenaMaster account by providing an email
                    and password of your choice.
                    <br />
                    <br />
                    2. Click the "Connect with Battle Net" button on your settings integrations page
                    to import your max level characters.
                    <br />
                    <br />
                    <strong className="strong">
                      Now you've unlocked access to the LFG and can easily create and apply to
                      groups!
                    </strong>
                  </p>
                </div>

                <div>
                  <h2>Creating groups</h2>
                  <p>
                    You can create a group for any of you imported characters. You can select the
                    bracket, title, description as well as required current rating and experience of
                    players that you want to apply. You can also select a list of classes and
                    specializations that you're interested in playing with.
                    <br />
                    <br />
                    <strong className="strong">
                      Only players that match those criteria can SEE and APPLY to your group!
                    </strong>
                  </p>
                </div>

                <div>
                  <h2>Applying to groups</h2>
                  <p>
                    Applying to groups is really easy. You just select the character you want to
                    play and the bracket and you'll see a list of groups that you looking for your
                    class and spec with similar rating and experience.
                    <br />
                    <br />
                    <strong className="strong">
                      You can apply in one click and also send an optional note to the group leader
                      and all your relevant PvP info will be sent with your application.
                    </strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="buttons">
              {!currentAccount.isSignedIn && (
                <SignUpButtonContainer text="Get Started" action="SignUp" />
              )}

              {currentAccount.isSignedIn && (
                <Button className="ok-button primary-btn" onClick={toggleLfgCollapsed}>
                  OK, I got it. (hide this)
                </Button>
              )}
            </div>
          </Grid>
        )}
      </div>
    </Collapse>
  );
};

LfgHero.propTypes = {
  history: PropTypes.object.isRequired,
  lfgCollapsed: PropTypes.bool.isRequired,
  toggleLfgCollapsed: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default withRouter(LfgHero);
