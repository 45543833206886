import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  FETCH_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
} from '../constants/notifications';

const DEFAULT_STATE = {
  notifications: [],
  appNotifications: [],
};

export const notificationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      };

    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        appNotifications: action.payload,
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        appNotifications: [action.payload, ...state.appNotifications],
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        appNotifications: state.appNotifications.filter(n => n.id !== action.payload),
      };

    default:
      return state;
  }
};
