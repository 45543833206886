import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { handleForgottenPassword } from 'actions/auth';
import * as yup from 'yup'; // for everything

const schema = yup.object().shape({
  email: yup.string().required('is required'),
});

const ForgotPasswordForm = ({ openAuthModal }) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="title">
        <h1>Forgot password?</h1>

        <span>Give us your email address, and we will send you further instructions.</span>
      </div>

      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, actions) => dispatch(handleForgottenPassword(values, actions))}
        validationSchema={schema}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-control">
              <label>Email</label>

              <ErrorMessage name="email" component="span" className="error" />
              <Field name="email" label="email" placeholder="Account Email" autoFocus />
            </div>

            <Button
              className="primary-btn"
              type="submit"
              disabled={isSubmitting}
              style={{ width: '100%' }}
            >
              {isSubmitting ? <CircularProgress style={{ color: '#fff' }} /> : 'Reset Password'}
            </Button>

            <p className="mb-3">
              Already a member? Go to
              <Button
                onClick={() => openAuthModal('SignIn')}
                className="btn-link"
                style={{ marginLeft: '-8px' }}
              >
                login
              </Button>
            </p>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

ForgotPasswordForm.propTypes = {
  openAuthModal: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
