import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarImage from '../../assets/avatar.jpg';
import RaceIcon from '../../assets/races/blood_elf_male.png';
import ClassIcon from '../../assets/classes/demon_hunter.jpg';
import SpecIcon from '../../assets/specializations/demon_hunter/havoc.png';
import HolyPalIcon from '../../assets/classes/paladin.jpg';
import RestDruidIcon from '../../assets/classes/druid.jpg';

const members = [
  {
    id: 1,
    avatar: (
      <Avatar
        alt="Character\'s image"
        src={AvatarImage}
        style={{ width: '40px', height: '40px' }}
        className="char-image"
      />
    ),
    name: 'Antrix',
    title: 'The Wrathful Gladiator',
    raceIcon: <img alt="Character\'s race" src={RaceIcon} />,
    classIcon: <img alt="Character\'s class" src={ClassIcon} />,
    specIcon: <img alt="Character\'s specializations" src={SpecIcon} />,
    currentRating: '4530',
    highestRating: '4530',
  },

  {
    id: 2,
    avatar: (
      <Avatar
        alt="Character\'s image"
        src={AvatarImage}
        style={{ width: '40px', height: '40px' }}
        className="char-image"
      />
    ),
    name: 'Antrix',
    title: 'The Wrathful Gladiator',
    raceIcon: <img alt="Character\'s race" src={RaceIcon} />,
    classIcon: <img alt="Character\'s class" src={ClassIcon} />,
    specIcon: <img alt="Character\'s specializations" src={SpecIcon} />,
    currentRating: '2031',
    highestRating: '2031 ',
  },
];

const specs = [
  {
    id: 1,
    classIcon: <img alt="Character\'s class" src={HolyPalIcon} />,
    name: 'Holy Paladin',
  },

  {
    id: 2,
    classIcon: <img alt="Character\'s class" src={RestDruidIcon} />,
    name: 'Restoration Druid',
  },

  {
    id: 2,
    classIcon: <img alt="Character\'s class" src={RestDruidIcon} />,
    name: 'Restoration Druid',
  },
];

const groups = [
  {
    id: 1,
    title: 'Group title goes here',
    text:
      'In a game with 9 million subscribers one may think it is impossible to stand out from the crowd or to be recognized for some unique talent',
    members,
    specs,
  },

  {
    id: 2,
    title: 'Group title goes here',
    text:
      'In a game with 9 million subscribers one may think it is impossible to stand out from the crowd or to be recognized for some unique talent',
    members,
    specs,
  },
];

export default groups;
