import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GroupList from '../components/GroupList';

const GroupSearchContainer = ({ groups }) => <GroupList groups={groups} />;

GroupSearchContainer.propTypes = {
  groups: PropTypes.array,
};

GroupSearchContainer.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ groups: { searchedGroups } }) => ({ groups: searchedGroups });

export default connect(
  mapStateToProps,
  undefined,
)(GroupSearchContainer);
