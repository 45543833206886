import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import FactionIcon from '../FactionIcon';
import {
  getRatingColor,
  getWinRateColor,
  getFactionColor,
  getClassIcon,
  getRaceIcon,
  getClassColor,
  getWinPercentage,
} from 'app/utils/helpers';
import './TBCRow.scss';

const useRowStyles = makeStyles({
  cell: {
    color: '#fff',
    fontSize: 16,
    borderBottom: '1px solid #1f2527',
  },

  body: {
    borderBottom: 0,
  },

  collapseRow: {
    backgroundColor: '#1f2527',
  },

  collapseCell: {
    color: '#fff',
    fontSize: 16,
    borderBottom: '1px solid #151b20',
  },

  collapseHeader: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
    },
  },
});

const TBCRow = ({ team, index }) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        className="table-header"
        style={index % 2 ? { background: 'rgba(0, 0, 0, 0.07)' } : { background: '#151b20' }}
      >
        <TableCell className={classes.cell}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell className={classes.cell} align="center">
          {team.rank}
        </TableCell>

        <Tooltip title="Click to view Team" classes={{ tooltip: 'black-tooltip' }}>
          <TableCell className={classes.cell} align="center">
            <img
              src={team.emblem_image_url || team.emblem_url}
              onClick={() => history.push(`/tbc-rankings/teams/${team.id}`)}
              alt="Team Crest"
              width={120}
              className="emblem-img"
            />
          </TableCell>
        </Tooltip>

        <TableCell className={classes.cell}>
          <span style={{ color: getFactionColor(team.faction) }}>
            {team.name} - {team.realm}
          </span>
        </TableCell>

        <TableCell className={classes.cell} align="center">
          <FactionIcon faction={team.faction} />
        </TableCell>

        <TableCell className={classes.cell} align="center">
          <span style={{ color: getRatingColor(team.rating) }}>{team.rating}</span>
        </TableCell>

        <TableCell className={classes.cell} align="center">
          <span
            style={{
              color: getWinRateColor(getWinPercentage(team.season_won, team.season_played)),
            }}
          >
            {getWinPercentage(team.season_won, team.season_played)}%
          </span>
        </TableCell>

        <TableCell className={classes.cell} align="center">
          {team.season_won}
        </TableCell>

        <TableCell className={classes.cell} align="center">
          {team.season_lost}
        </TableCell>
      </TableRow>

      <TableRow className={classes.collapseRow}>
        <TableCell style={{ padding: 0 }} colSpan={12} className={classes.cell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                style={{
                  padding: 20,
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                Team Members
              </Typography>

              <Table size="small">
                <TableHead style={{ background: '#111516' }}>
                  <TableCell
                    className={classes.collapseCell}
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Name - Realm
                  </TableCell>

                  <TableCell
                    className={classes.collapseCell}
                    align="center"
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Character
                  </TableCell>

                  <TableCell
                    className={classes.collapseCell}
                    align="center"
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Rating
                  </TableCell>

                  <TableCell
                    className={classes.collapseCell}
                    align="center"
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Win %
                  </TableCell>

                  <TableCell
                    className={classes.collapseCell}
                    align="center"
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Won
                  </TableCell>

                  <TableCell
                    className={classes.collapseCell}
                    align="center"
                    style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}
                  >
                    Lost
                  </TableCell>
                </TableHead>

                <TableBody>
                  {team.members
                    .sort((a, b) => b.played - a.played)
                    .map(member => (
                      <Tooltip
                        title="Click to view Team Member"
                        classes={{ tooltip: 'black-tooltip' }}
                        key={member.id}
                      >
                        <TableRow
                          component={Link}
                          to={`/tbc-rankings/characters/${member.id}`}
                          className={classes.collapseHeader}
                        >
                          <TableCell className={classes.collapseCell} style={{ width: '20%' }}>
                            <span style={{ color: getClassColor(member.character_class) }}>
                              {member.name} - {member.realm}
                            </span>
                          </TableCell>

                          <TableCell
                            className={classes.collapseCell}
                            style={{ width: '15%' }}
                            align="center"
                          >
                            <div className="specs column">
                              <Tooltip title={member.race} classes={{ tooltip: 'black-tooltip' }}>
                                <img
                                  src={getRaceIcon(member.gender.toLowerCase(), member.race)}
                                  alt="Character Race"
                                />
                              </Tooltip>

                              {member.character_class && (
                                <Tooltip
                                  title={member.character_class}
                                  classes={{ tooltip: 'black-tooltip' }}
                                >
                                  <img
                                    src={getClassIcon(member.character_class)}
                                    className="class-icon"
                                    alt="Character Class"
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>

                          <TableCell
                            className={classes.collapseCell}
                            style={{ width: '10%' }}
                            align="center"
                          >
                            <span style={{ color: getRatingColor(member.rating) }}>
                              {member.rating}
                            </span>
                          </TableCell>

                          <TableCell
                            className={classes.collapseCell}
                            style={{ width: '10%' }}
                            align="center"
                          >
                            <span
                              style={{
                                color: getWinRateColor(getWinPercentage(member.won, member.played)),
                              }}
                            >
                              {getWinPercentage(member.won, member.played)}%
                            </span>
                          </TableCell>

                          <TableCell
                            className={classes.collapseCell}
                            style={{ width: '10%' }}
                            align="center"
                          >
                             {member.won}
                          </TableCell>

                          <TableCell
                            className={classes.collapseCell}
                            style={{ width: '10%' }}
                            align="center"
                          >
                            {member.lost}
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

TBCRow.propTypes = {
  team: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default TBCRow;
