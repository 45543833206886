import React, { useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { richMediaId, richMediaNoVerticalId } from 'app/constants/ads';

const RichAd = ({ vertical = true }) => {
  const patreon = useSelector(state => state.auth.currentAccount.patreon);
  const ref = createRef();

  useEffect(() => {
    if (patreon) return;

    window.__vm_add = window.__vm_add || [];
    window.__vm_add.push(ref.current);

    return () => {
      window.__vm_remove = window.__vm_remove || [];
      if (ref.current) window.__vm_remove.push(ref.current);

      const footer = document.getElementsByTagName('footer')[0];

      if (footer) {
        footer.style = '';
      }

      const skins = document.getElementsByClassName('vm-skin');

      if (skins.length) {
        [...skins].forEach(node => node.remove());
      }

      const fixedFooter = document.getElementsByClassName('vm-footer')[0];

      if (fixedFooter) {
        fixedFooter.remove();
      }
    };
  }, []);

  if (patreon) return null;

  return (
    <div id="rich-ad-container">
      <div
        className="vm-placement"
        data-id={vertical ? richMediaId : richMediaNoVerticalId}
        style={{ display: 'none' }}
        ref={ref}
      ></div>
    </div>
  );
};

export default RichAd;
