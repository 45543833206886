import React from 'react';
import Responsive from 'react-responsive';

export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
export const TabletAndUp = props => <Responsive {...props} minWidth={420} />;
export const Mobile = props => <Responsive {...props} maxWidth={992} />;
export const MobileOnly = props => <Responsive {...props} maxWidth={420} />;
export const MobileAndUp = props => <Responsive {...props} minWidth={421} />;
export const MobileToTablet = props => <Responsive {...props} maxWidth={899} />;
export const MobileToSmallLaptop = props => <Responsive {...props} maxWidth={1410} />;
export const SmallDesktop = props => <Responsive {...props} minWidth={900} />;
export const LargeDesktop = props => <Responsive {...props} minWidth={1200} />;
export const Default = props => <Responsive {...props} minWidth={768} />;
