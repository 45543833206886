import React from 'react';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import { getRatingColor, getClassColor, getCharacterInfo } from '../../utils/helpers';
import SwitchButton from 'components/SwitchButton';
import './AboutCharacter.scss';

const AboutCharacter = ({
  character,
  shouldUpdateMain,
  updateMainCharacter,
  updateAltHidden,
  displayButtons,
  children,
  charDetailsId,
  buttonsId,
  aboutCharId,
}) => {
  const classColor = getClassColor(character.character_class);
  const matchDashboard = useRouteMatch('/dashboard');

  return (
    <div
      className={`about-character ${character.main && shouldUpdateMain ? 'main' : ''}`}
      style={character.main ? { borderColor: classColor } : {}}
      id={aboutCharId}
    >
      <div className="char-details" id={charDetailsId}>
        <div className="character-info" style={character.level < 60 ? { padding: 0 } : {}}>
          <Avatar
            alt="Characters image"
            src={character.avatar_url}
            style={{ width: '55px', height: '55px', marginRight: 10 }}
          />

          <div className="character-name">
            <h2 style={{ color: classColor }}>
              {character.name} - {character.realm_name}
            </h2>

            <span className="badge" style={{ backgroundColor: classColor, color: '#000' }}>
              {getCharacterInfo(character)}
            </span>
          </div>
        </div>

        {shouldUpdateMain && character.level >= 60 && (
          <div className="main-toggle">
            <Radio checked={character.main} onChange={() => updateMainCharacter(character)} />
            <span>{character.main ? 'Current Main' : 'Make Main'}</span>

            {character.level >= 60 && (
              <div className="hide-alt" style={{ position: 'relative' }}>
                <SwitchButton
                  checked={character.alt_hidden}
                  handleChange={() => {
                    updateAltHidden(character);
                  }}
                  styleSwitchBtn
                />

                <span style={{ width: 70 }}>
                  {character.alt_hidden ? 'Alt Hidden' : 'Hide Alt'}
                </span>
              </div>
            )}
          </div>
        )}
      </div>

      {matchDashboard && (
        <div className="char-exp-table">
          <span className="first-column" />

          <span className="column bottom-border">
            <strong>2v2</strong>
          </span>

          <span className="bottom-border">
            <strong>3v3</strong>
          </span>

          <span className="bottom-border">
            <strong>RBG</strong>
          </span>

          <span className="row1">
            <strong>CR</strong>
          </span>

          <span className="row2">
            <strong>EXP</strong>
          </span>

          <span className="items" style={{ color: getRatingColor(character.rating_2v2) }}>
            {get(character, 'rating_2v2') || 0}
          </span>

          <span style={{ color: getRatingColor(character.rating_3v3) }}>
            {get(character, 'rating_3v3') || 0}
          </span>

          <span style={{ color: getRatingColor(character.rating_rbg) }}>
            {get(character, 'rating_rbg') || 0}
          </span>

          <span className="items" style={{ color: getRatingColor(character.exp_2v2) }}>
            {get(character, 'exp_2v2') || 0}
          </span>

          <span style={{ color: getRatingColor(character.exp_3v3) }}>
            {get(character, 'exp_3v3') || 0}
          </span>

          <span style={{ color: getRatingColor(character.exp_rbg) }}>
            {get(character, 'exp_rbg') || 0}
          </span>
        </div>
      )}

      {children}

      {displayButtons && (
        <div className="buttons" id={buttonsId}>
          <a href={character.armory_url} target="_blank" rel="noopener noreferrer">
            <Button className="armory-btn">View on Armory</Button>
          </a>

          <Link to={character.arenamaster_path}>
            <Button className="arenamaster-btn">View on ArenaMaster</Button>
          </Link>
        </div>
      )}
    </div>
  );
};

AboutCharacter.propTypes = {
  character: PropTypes.object.isRequired,
  updateMainCharacter: PropTypes.func,
  updateAltHidden: PropTypes.func,
  shouldUpdateMain: PropTypes.bool,
  displayButtons: PropTypes.bool,
  children: PropTypes.node,
  styles: PropTypes.object,
  charDetailsId: PropTypes.string,
  buttonsId: PropTypes.string,
  aboutCharId: PropTypes.string,
};

AboutCharacter.defaultProps = {
  updateMainCharacter: id => {}, // eslint-disable-line
  updateAltHidden: id => {}, // eslint-disable-line
  shouldUpdateMain: false,
  displayButtons: true,
  children: null,
  styles: {},
  charDetailsId: '',
  buttonsId: '',
  aboutCharId: '',
};

export default AboutCharacter;
