import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TBCRankings from 'components/TBCRankings';
import TbcProfile from 'components/TbcProfile';
import TbcTeamProfile from 'components/TbcTeamProfile';
import HeroTbc from 'assets/hero-tbc.jpeg';
import Hero from 'components/Hero';

const TbcPage = () => {
  return (
    <React.Fragment>
      <Hero bgImage={HeroTbc} />

      <Switch>
        <Route path="/tbc-rankings/characters/:id" component={TbcProfile} />
        <Route path="/tbc-rankings/teams/:id" component={TbcTeamProfile} />
        <Route path="/tbc-rankings/:region/:bracket" component={TBCRankings} />
      </Switch>
    </React.Fragment>
  );
};

export default TbcPage;
