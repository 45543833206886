import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';
import LfgHero from '../../components/LfgHero';
import LfgHeader from '../../components/LfgHeader';
import NewGroupPageContainer from '../../containers/NewGroupPage';
import EditGroupPageContainer from '../../containers/EditGroupPage';
import SearchGroups from './SearchGroups';
import Hero from '../../components/Hero';
import MyGroupsPageContainer from '../../containers/MyGroupsPage';
import AppliedGroupsPageContainer from '../../containers/AppliedGroupsPage';
import GroupDetailsPage from '../../containers/GroupDetailsPage';
import './Lfg.scss';

const LfgPage = ({ toggleLfgCollapsed, lfgCollapsed, currentAccount, characters }) => (
  <React.Fragment>
    <MetaTags>
      <title>Looking For Group (LFG) System for WoW PvP Community | ArenaMaster</title>
      <meta
        name="description"
        content="ArenaMaster World of Warcraft LFG System is the best way to find arena partners for the WoW PvP Community"
      />
    </MetaTags>

    <LfgHero
      toggleLfgCollapsed={toggleLfgCollapsed}
      lfgCollapsed={lfgCollapsed}
      currentAccount={currentAccount}
    />

    {currentAccount.isSignedIn && (
      <LfgHeader currentAccount={currentAccount} characters={characters} />
    )}

    {currentAccount.isSignedIn && (
      <Grid container className="lfg-page-container">
        <Hero />

        <Switch>
          <Route path="/lfg" exact component={SearchGroups} />
          <Route path="/lfg/new" exact component={NewGroupPageContainer} />
          <Route path="/lfg/edit/:id" exact component={EditGroupPageContainer} />
          <Route path="/lfg/groups/:id" component={GroupDetailsPage} />
          <Route path="/lfg/my-groups" exact component={MyGroupsPageContainer} />
          <Route path="/lfg/applied-groups" exact component={AppliedGroupsPageContainer} />
        </Switch>
      </Grid>
    )}
  </React.Fragment>
);

LfgPage.propTypes = {
  lfgCollapsed: PropTypes.bool.isRequired,
  toggleLfgCollapsed: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default LfgPage;
