import React from 'react';
import PropTypes from 'prop-types';
import RankingsFilterButton from '../RankingsFilterButton';

// eslint-disable-next-line react/prop-types

const BracketFilters = ({ currentBracket, handleBracketChange }) => (
  <div className="filters-button">
    {/* <RankingsFilterButton
      type="solo"
      currentType={currentBracket}
      handleChange={handleBracketChange}
      buttonText="solo"
    /> */}
    <RankingsFilterButton
      type="2v2"
      currentType={currentBracket}
      handleChange={handleBracketChange}
      buttonText="2v2"
    />
    <RankingsFilterButton
      type="3v3"
      currentType={currentBracket}
      handleChange={handleBracketChange}
      buttonText="3v3"
    />
    <RankingsFilterButton
      type="rbg"
      currentType={currentBracket}
      handleChange={handleBracketChange}
      buttonText="RBG"
    />
  </div>
);

BracketFilters.propTypes = {
  currentBracket: PropTypes.string.isRequired,
  handleBracketChange: PropTypes.func.isRequired,
};

export default BracketFilters;
