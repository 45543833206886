import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleSignUp as handleSignUpAction } from '../actions/auth';
import SignUpForm from '../components/SignUpForm';

const SignUpFormContainer = props => <SignUpForm {...props} />;

SignUpFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    handleSubmit: handleSignUpAction,
  },
)(SignUpFormContainer);
