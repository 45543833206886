import {
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  SIGN_IN,
  SIGN_OUT,
  UPDATE_ACCOUNT,
} from '../constants/auth';

const INITIAL_STATE = {
  modalOpen: false,
  modalTab: 'SignIn',
  currentAccount: {
    isLoading: false,
    isSignedIn: false,
    id: '',
    username: '',
    name: '',
    email: '',
    role_id: '',
  },
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_AUTH_MODAL:
      return {
        ...state,
        modalOpen: true,
        modalTab: action.payload,
      };

    case CLOSE_AUTH_MODAL:
      return {
        ...state,
        modalOpen: false,
      };

    case SIGN_IN:
      return {
        ...state,
        currentAccount: {
          isSignedIn: true,
          isLoading: false,
          ...action.payload,
        },
      };

    case SIGN_OUT:
      return {
        ...state,
        currentAccount: {
          ...INITIAL_STATE.currentAccount,
        },
      };

    case UPDATE_ACCOUNT:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...action.payload,
        },
      };

    default:
      break;
  }

  return state;
};
