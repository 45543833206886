import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import CharacterSearchResult from '../CharacterSearchResult/';
import './CharacterSearch.scss';

class CharacterSearch extends Component {
  static propTypes = {
    region: PropTypes.string.isRequired,
    characters: PropTypes.array.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.searchInput = React.createRef();
    this.debouncedHandleChange = debounce(e => {
      this.props.handleSearch(e.target.value);
    }, 200);
  }

  componentWillReceiveProps(nextProps) {
    const { region, handleSearch } = this.props;
    const search = get(this.searchInput, 'current.refs.input.value', '');

    if (region !== nextProps.region && search.length) {
      handleSearch(search);
      this.searchInput.current.handleInputFocus();
    }
  }

  handleChange(e) {
    e.persist();
    this.debouncedHandleChange(e);
  }

  handleSelect(value, character) {
    this.searchInput.current.refs.input.value = '';
    this.props.handleSelect(character);
  }

  render() {
    const { characters, autoFocus, region } = this.props;

    const menuStyle = {
      position: 'relative',
      left: 0,
      top: 0,
      minWidth: 0,
    };

    return (
      <Autocomplete
        ref={this.searchInput}
        items={characters}
        getItemValue={character => `${character.name}`}
        renderInput={({ value, ...props }) => (
          <div style={{ position: 'relative' }}>
            <SearchIcon style={{ fill: '#fff', position: 'absolute' }} className="search-icon" />

            <input {...props} defaultValue="" autoFocus={autoFocus} />
          </div>
        )}
        renderItem={(character, isHighlighted) => (
          <CharacterSearchResult
            character={character}
            key={character.id}
            isHighlighted={isHighlighted}
            handleClick={() => this.handleSelect('', character)}
          />
        )}
        renderMenu={(items, value, style) => (
          <div
            className={`search-results ${items.length * 55 > 0 ? 'visible' : ''}`}
            style={{ height: items.length * 60, zIndex: 99 }}
          >
            <div className="search-result-items">{items}</div>
          </div>
        )}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        wrapperProps={{ id: 'character-search-wrapper' }}
        inputProps={{
          placeholder: region === 'EU' ? 'Whaazz-Ravencrest' : 'Tmpikaboo-Tichondrius',
        }}
        menuStyle={menuStyle}
        autoHighlight
      />
    );
  }
}

export default CharacterSearch;
