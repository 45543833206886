import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MetaTags } from 'react-meta-tags';

import ModifiedAlert from '../components/Alert';
import AboutCharacter from '../components/AboutCharacter/';
import { fetchFollowingCharacters as fetchFollowingCharactersAction } from '../actions/characters';
import LoaderContainer from './Loader';

class FollowingPageContainer extends Component {
  static propTypes = {
    characters: PropTypes.array.isRequired,
    fetchFollowingCharacters: PropTypes.func.isRequired,
    followingCharactersFetched: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchFollowingCharacters, followingCharactersFetched } = this.props;

    if (!followingCharactersFetched) {
      fetchFollowingCharacters();
    }
  }

  render() {
    const { characters } = this.props;

    return (
      <React.Fragment>
        <MetaTags>
          <title>Followed Characters | ArenaMaster</title>
        </MetaTags>

        <h1 className="title" style={characters.length === 0 ? { paddingBottom: '5px' } : {}}>
          FOLLOWING CHARACTERS
        </h1>

        <LoaderContainer styleLoader={{ marginTop: '80px' }} />

        <ModifiedAlert
          severity="info"
          text="You are not following anyone yet."
          styleAlert={{ marginTop: '10px' }}
          data={characters}
        />

        {characters.length > 0 && (
          <React.Fragment>
            {characters.map((character, index) => (
              <AboutCharacter
                styles={{ marginTop: index === 0 ? 0 : '5%' }}
                key={character.id}
                character={character}
                shouldUpdateMain={false}
                charDetailsId="dashboard-char-details"
                buttonsId="dashboard-buttons"
              />
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ characters: { followingCharacters, followingCharactersFetched } }) => ({
  characters: followingCharacters,
  followingCharactersFetched,
});

export default connect(mapStateToProps, {
  fetchFollowingCharacters: fetchFollowingCharactersAction,
})(FollowingPageContainer);
