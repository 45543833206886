import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import get from 'lodash/get';

import { getClassColor, getRatingColor } from '../../utils/helpers';
import FactionIcon from '../FactionIcon/';
import './CharacterDropdown.scss';

// eslint-disable-next-line
const CharacterOption = ({ char, bracket }) => {
  const matchNew = useRouteMatch('/lfg/new');
  const rating = get(char, [`rating_${bracket}`]);
  const experience = get(char, [`exp_${bracket}`]);

  if (!char) return null;

  return (
    <div className="character-option">
      <div className="char-name" id={matchNew ? 'char-name-new' : ''}>
        <Avatar
          alt="Character's image"
          src={char.avatar_url}
          style={{ width: '40px', height: '40px' }}
          className="char-image"
        />
        <div className="char-details">
          <h3 style={{ color: getClassColor(char.character_class) }}>
            {char.name}-{char.realm_name}
          </h3>
          {char.level} {char.specialization} {char.character_class}
        </div>
      </div>

      <FactionIcon faction={char.faction} />

      <div className="rating">
        <span style={{ color: getRatingColor(rating) }}>
          CR: &nbsp; {get(char, [`rating_${bracket}`], 0)}
        </span>

        <span style={{ color: getRatingColor(experience) }}>
          EXP: {get(char, [`exp_${bracket}`], 0)}
        </span>
      </div>
    </div>
  );
};

const CharacterDropdown = ({ handleSelectCharacter, bracket, characters, selectedCharacterId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <div className="choose-char-menu">
      <div className={isOpen ? 'open' : 'close'}>
        <Button
          className={characters.length === 1 ? 'char-button no-hover' : 'char-button'}
          onClick={handleClick}
        >
          {selectedCharacterId && (
            <div className="selected">
              <CharacterOption
                bracket={bracket}
                char={characters.find(c => c.id === selectedCharacterId)}
              />

              {characters.length > 1 && <ArrowDropDownIcon className="arrow-down" />}
            </div>
          )}

          {!selectedCharacterId && (
            <React.Fragment>
              Choose Character <ArrowDropDownIcon />
            </React.Fragment>
          )}
        </Button>

        <ul>
          {characters
            .filter(char => char.id !== selectedCharacterId && char.level >= 60)
            .map(char => (
              <li
                key={char.id}
                onClick={() => {
                  handleSelectCharacter(char.id);
                  handleClick();
                }}
              >
                <CharacterOption char={char} bracket={bracket} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

CharacterDropdown.propTypes = {
  bracket: PropTypes.string.isRequired,
  handleSelectCharacter: PropTypes.func,
  characters: PropTypes.array,
  selectedCharacterId: PropTypes.any,
};

CharacterDropdown.defaultProps = {
  selectedCharacterId: null,
  characters: [],
  handleSelectCharacter: null,
};

export default CharacterDropdown;
