import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Axios from '../../utils/axios';

const Conduit = ({ conduit }) => {
  const [spellId, setSpellId] = useState(null);
  const rank = get(conduit, 'conduit_socket.socket.rank', null);
  const hasTrait = conduit.hasOwnProperty('trait');
  const id = get(conduit, 'trait.id') || get(conduit, 'conduit_socket.socket.conduit.id');
  const url = hasTrait ? `/api/v1/conduits/${id}/tech_talent` : `/api/v1/conduits/${id}`;

  useEffect(() => {
    if (!id) return;

    Axios.get(url).then(response => setSpellId(response.data));
  }, [id]);

  if (!spellId || !id) return null;

  return (
    <div className="conduit">
      <a
        data-wh-icon-size="large"
        data-wh-rename-link="false"
        data-wh-icon-added="true"
        href={
          rank
            ? `https://wowhead.com/spell=${spellId}/?rank=${rank - 1}`
            : `https://wowhead.com/spell=${spellId}`
        }
        target="_blank"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={hasTrait ? 'trait' : 'conduit-ring'}>
            <img
              src={`/api/v1/spells/${spellId}`}
              alt="Item Icon"
              width="44"
              height="44"
              style={{ borderRadius: '100%' }}
            />
          </span>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
            <span>
              {hasTrait ? conduit.trait.name : conduit.conduit_socket.socket.conduit.name}
            </span>

            {rank && <span style={{ fontSize: 12 }}>Rank {rank}</span>}
          </div>
        </div>
      </a>
    </div>
  );
};

Conduit.propTypes = {
  conduit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Conduit;
