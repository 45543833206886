import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';
import TimeAgo from 'react-timeago';

import ActionCable from '../ActionCable/Cable';
import { fetchNotifications, addNotification, markAsRead } from 'actions/notifications';
import { fetchGroup } from 'actions/groups';
import './NotificationsButton.scss';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },

  tooltip: {
    fontSize: 14,
    padding: '10px 15px',
  },
});

const NotificationsButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { notifications } = useSelector(state => ({
    notifications: state.notifications.appNotifications,
  }));

  const onReceived = notification => {
    dispatch(addNotification(notification));
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!notifications.length) {
      dispatch(fetchNotifications());
    }
  }, []);

  return (
    <div className={classes.root}>
      <ActionCable
        channel={{
          channel: 'NotificationChannel',
        }}
        onReceived={onReceived}
      />

      <div id="notifications-container">
        <IconButton
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {notifications.length > 0 ? (
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsIcon style={{ color: '#fff' }} />
            </Badge>
          ) : (
            <NotificationsIcon style={{ color: '#fff' }} />
          )}
        </IconButton>
      </div>

      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        className="notifications-dropdown"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom',
            }}
          >
            <Paper
              style={{
                background: '#0c0d0e',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className="menu-items">
                  {notifications.length === 0 && (
                    <MenuItem style={{ borderBottom: 0, textAlign: 'center' }}>
                      You have no notifications.
                    </MenuItem>
                  )}

                  {notifications.map(notification => (
                    <div
                      onClick={() =>
                        notification.type === 'GroupApplication'
                          ? dispatch(fetchGroup(+notification.path.split('/')[3]))
                          : null
                      }
                      key={notification.id}
                    >
                      <Link to={notification.path}>
                        <Tooltip
                          title="Click to view the notification"
                          placement="bottom-start"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <MenuItem>
                            <div className="notifications-body">
                              <Avatar
                                alt="Characters image"
                                src={notification.avatar_url}
                                style={{ width: '40px', height: '40px' }}
                                className="char-image"
                              />

                              <div className="text">
                                {notification.action}

                                <div className="mark-as-read">
                                  <TimeAgo
                                    style={{ color: 'silver' }}
                                    date={notification.created_at}
                                  />
                                  <span
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      dispatch(markAsRead(notification.id));
                                    }}
                                  >
                                    Mark as Read
                                  </span>
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        </Tooltip>
                      </Link>
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NotificationsButton;
