import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup'; // for everything

import './SignInForm.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('is not a valid email')
    .required('is required'),
  password: yup
    .string()
    .required('is required')
    .min(6, 'must contain at least 6 characters'),
});

const SignInForm = ({ handleSubmit }) => (
  <div className="SignInForm">
    <h1>Login</h1>
    <p>Already a member? Welcome back!</p>

    <Formik
      initialValues={{ email: '', password: '', remember_me: false }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-control">
            <label>Email</label>

            <ErrorMessage name="email" component="span" className="error" />
            <Field name="email" label="email" placeholder="Your Email" autoFocus />
          </div>

          <div className="form-control">
            <label>Password</label>

            <ErrorMessage name="password" component="span" className="error" />
            <Field name="password" label="password" type="password" placeholder="Your Password" />
          </div>

          <div className="checkbox-field">
            <Field
              name="remember_me"
              type="checkbox"
              component={CheckboxWithLabel}
              Label={{ label: 'Remember Me' }}
            />
          </div>

          <Button
            type="submit"
            disabled={isSubmitting}
            style={{ width: '100%', marginBottom: 20 }}
            className="primary-btn"
          >
            {isSubmitting ? <CircularProgress style={{ color: '#fff' }} /> : 'Login'}
          </Button>
        </Form>
      )}
    </Formik>
  </div>
);

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default SignInForm;
