import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Menu from '../Menu/';
import logo from '../../assets/logo.png';
import './Header.scss';

const Header = ({ currentAccount, handleSignOut, history, region, location }) => (
  <header>
    <Grid container justify="space-between" alignItems="center" style={{ height: '100%' }}>
      <Link className="logo" to="/">
        <img src={logo} alt="logo" style={{ width: 60, height: 'auto' }} />
        <span className="beta-info">Beta</span>
      </Link>

      <Menu
        currentAccount={currentAccount}
        handleSignOut={handleSignOut}
        history={history}
        location={location}
        region={region}
      />
    </Grid>
  </header>
);

Header.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
};

export default Header;
