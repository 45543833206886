import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import TimeAgo from 'react-timeago';

import { getRaceIcon, getClassIcon, getSpecIcon } from '../../utils/helpers';
import AboutCharacter from '../AboutCharacter';
import { acceptGroupApplication, declineGroupApplication } from '../../actions/groups';
import ModifiedAlert from '../Alert';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 14,
  },
});

const GroupApplications = ({ group, groupOwner }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {group.applications.length > 0 ? (
        <div>
          {group.applications.map(application => (
            <AboutCharacter
              key={application.id}
              character={application.character}
              buttonsId="group-buttons"
              charDetailsId="group-char-details"
              aboutCharId="group-about-char"
            >
              <div className="group-ratings">
                <div className="member-icons">
                  <ul>
                    <li>
                      <img
                        src={getRaceIcon(application.character.gender, application.character.race)}
                        alt="Character Race"
                      />
                    </li>
                    <li>
                      <img
                        src={getClassIcon(application.character.character_class)}
                        alt="Character Class"
                      />
                    </li>
                    <li>
                      <img
                        src={getSpecIcon(
                          application.character.character_class,
                          application.character.specialization
                        )}
                        alt="Specialization"
                      />
                    </li>
                  </ul>
                </div>

                <div className="ratings">
                  <p>
                    Current Rating:
                    <span className="rating">{get(application, 'character.cr', 0)}</span>
                  </p>

                  <p>
                    Highest Rating:
                    <span className="rating">{get(application, 'character.exp', 0)}</span>
                  </p>
                </div>
              </div>
              <div className="actions">
                <Tooltip
                  title={groupOwner ? 'Accept' : 'Only the group owner can accept an application'}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    onClick={() => {
                      groupOwner ? dispatch(acceptGroupApplication(application.id)) : null;
                    }}
                    style={{ marginRight: 10, border: '2px solid #27ae60' }}
                  >
                    <DoneIcon style={{ fill: '#27ae60' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={groupOwner ? 'Decline' : 'Only the group owner can decline an application'}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    onClick={() => {
                      groupOwner ? dispatch(declineGroupApplication(application.id)) : null;
                    }}
                    style={{ border: '2px solid #c72429' }}
                  >
                    <CloseIcon style={{ fill: '#c72429' }} />
                  </IconButton>
                </Tooltip>
              </div>
              {application.message && (
                <div className="message-box">
                  <span
                    style={{ display: 'block', marginBottom: 20, color: 'silver', fontSize: 14 }}
                  >
                    Applied {<TimeAgo date={application.created_at} />}
                  </span>
                  <span style={{ fontWeight: '700' }}>
                    Message from {application.character.name}:
                  </span>{' '}
                  <p className="message">{application.message}</p>
                </div>
              )}
            </AboutCharacter>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0c0d0e',
            height: 150,
            border: '1px solid #151b20',
            padding: '0 25px',
          }}
        >
          <ModifiedAlert text="You have no Group Applications." severity="info" />
        </div>
      )}
    </Grid>
  );
};

GroupApplications.propTypes = {
  group: PropTypes.object.isRequired,
  groupOwner: PropTypes.bool.isRequired,
};

export default GroupApplications;
