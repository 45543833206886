import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import HealthIcon from 'assets/stats/health.svg';
import CritIcon from 'assets/stats/crit.svg';
import HasteIcon from 'assets/stats/haste.svg';
import VersatilityIcon from 'assets/stats/versatility.svg';
import MasteryIcon from 'assets/stats/mastery.svg';
import './CharacterStats.scss';

const CharacterStats = ({ character }) => {
  const { stats } = character;

  if (isEmpty(stats)) return null;

  const { health, versatility, crit, mastery, haste } = stats;

  if (!health || !versatility || !crit || !mastery || !haste) return null;

  return (
    <Grid container className="bracket-stats-container character-stats">
      <div className="bracket stat-list">
        <h3 className="header">Stats</h3>

        <div className="content">
          <div className="stat stat-health">
            <HealthIcon />

            <p>
              <strong>Health</strong>
              <span>{health.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            </p>
          </div>

          <div className="stat stat-versatility">
            <VersatilityIcon />

            <p>
              <strong>Versatility</strong>
              <span>{versatility.value.toFixed(2)}%</span>
            </p>
          </div>

          <div className="stat stat-crit">
            <CritIcon />

            <p>
              <strong>Critical Strike</strong>
              <span>{crit.value.toFixed(2)}%</span>
            </p>
          </div>

          <div className="stat stat-mastery">
            <MasteryIcon />

            <p>
              <strong>Mastery</strong>
              <span>{mastery.value.toFixed(2)}%</span>
            </p>
          </div>

          <div className="stat stat-haste">
            <HasteIcon />

            <p>
              <strong>Haste</strong>
              <span>{haste.value.toFixed(2)}%</span>
            </p>
          </div>
        </div>
      </div>
    </Grid>
  );
};

CharacterStats.propTypes = {
  character: PropTypes.object.isRequired,
};

export default CharacterStats;
