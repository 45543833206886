import ReactGA from 'react-ga4';
import capitalize from 'lodash/capitalize';
import Axios from '../utils/axios';
import { NotificationManager } from './notifications';
import {
  SIGN_UP,
  SIGN_IN,
  SIGN_OUT,
  FORGOT_PASSWORD_REDIRECT_URL,
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
} from '../constants/auth';
import { SHOW_MESSAGE, HIDE_MESSAGE } from '../constants/utils';
import { showLoader } from './utils';
import { formatErrors } from '../utils/helpers';
import history from '../store/history';
import { ADD_CHARACTERS } from '../constants/characters';
import { SEARCH_GROUPS } from '../constants/groups';

// Plain Redux Actions

export const signUp = account => ({
  type: SIGN_UP,
  payload: account,
});

export const signIn = account => ({
  type: SIGN_IN,
  payload: account,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const showAuthMessage = message => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});

export const openAuthModal = tab => ({
  type: OPEN_AUTH_MODAL,
  payload: tab,
});

export const closeAuthModal = () => ({
  type: CLOSE_AUTH_MODAL,
});

// Async Redux Actions

export const handleSignIn = (values, { setSubmitting }) => async dispatch => {
  setSubmitting(true);

  return Axios.post('/accounts/sign_in', { account: values })
    .then(response => {
      setSubmitting(false);
      dispatch(closeAuthModal());
      dispatch(signIn(response.data));
      dispatch({ type: ADD_CHARACTERS, payload: response.data.characters });
    })
    .catch(error => {
      const { response } = error;

      if (response.status === 401) {
        dispatch(NotificationManager.error(response.data.error));
      } else {
        dispatch(NotificationManager.error('There was an error processing your request.'));
      }

      setSubmitting(false);
    });
};

export const handleSignUp = (values, { setSubmitting }) => async dispatch => {
  setSubmitting(true);

  return Axios.post('/accounts/sign_up', { account: values })
    .then(response => {
      setSubmitting(false);
      dispatch(closeAuthModal());
      dispatch(signIn(response.data));
      history.push('/dashboard/integrations');
      dispatch(
        NotificationManager.success(
          'Thanks for joining us! Please set up your profile to maximize the effectivness of the app!'
        )
      );

      ReactGA.event({
        category: 'User',
        action: 'Sign Up',
        label: response.data.email,
        value: response.data.id,
      });
    })
    .catch(error => {
      const { response } = error;
      // Rollbar.error('Failed Sign Up', error, { ...values, password: '' }); // eslint-disable-line no-undef
      console.log('response status', 422);

      if (response.status === 422) {
        const { errors } = response.data;
        Object.keys(errors).map(key =>
          dispatch(NotificationManager.error(`${capitalize(key)} ${errors[key]}`))
        );
      } else {
        dispatch(NotificationManager.error('There was an error processing your request.'));
      }

      setSubmitting(false);
    });
};

export const handleSignOut = () => async dispatch =>
  Axios.delete('/accounts/sign_out').then(() => {
    dispatch(signOut());
    dispatch({ type: SEARCH_GROUPS, payload: [] });
  });

export const handleForgottenPassword = ({ email }, formik) => dispatch =>
  Axios.post('/accounts/password', {
    account: {
      email,
      redirect_url: FORGOT_PASSWORD_REDIRECT_URL,
    },
  })
    .then(response => {
      dispatch(openAuthModal('SignIn'));
      dispatch(
        NotificationManager.success(
          'We just sent you a reset password link. Please check your email.'
        )
      );
    })
    .catch(error => {
      const { response } = error;

      if (response.status > 400 && response.status < 500) {
        formik.setSubmitting(false);

        response.data.errors.map(message => dispatch(NotificationManager.error(message)));
      }
    });

export const handleResetPassword = (data, formik) => dispatch => {
  dispatch(showLoader());

  return Axios.put('/accounts/password', { account: { ...data } })
    .then(response => {
      dispatch(signIn(response.data));
      dispatch(NotificationManager.success('Successfully reset the password.'));
    })
    .catch(error => {
      const { response } = error;

      if (response.status > 400 && response.status < 500) {
        formik.setSubmitting(false);

        const { errors } = response.data;
        const formattedErrors = formatErrors(errors);
        formik.setErrors(formattedErrors);
      }
    });
};
