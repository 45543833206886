import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Group from '../components/Group';
import {
  deleteGroup as deleteGroupAction,
  applyToGroup as applyToGroupAction,
  removeFromGroup as removeFromGroupAction,
} from '../actions/groups';

const GroupContainer = props => {
  const groupOwner = props.group.account_id === props.currentAccount.id;

  return <Group {...props} editable={groupOwner} deletable={groupOwner} />;
};

GroupContainer.propTypes = {
  group: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  applyToGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentAccount } }) => ({ currentAccount });

export default connect(mapStateToProps, {
  deleteGroup: deleteGroupAction,
  applyToGroup: applyToGroupAction,
  removeFromGroup: removeFromGroupAction,
})(GroupContainer);
