import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/';

export default function configureStore(initialState) {
  const middlewares = [thunk];

  const composedEnhancers = compose(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, initialState, composedEnhancers);

  return store;
}
