import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';

const StyledAlert = withStyles({
  root: {
    width: '100%',
  },

  icon: {
    fontSize: '25px',
  },

  message: {
    fontSize: '18px',
    color: '#2196f3',
  },
})(Alert);

const ModifiedAlert = ({ text, severity, variant, styleAlert, data, classes }) => {
  const isLoading = useSelector(state => state.utils.loader);

  return (
    <React.Fragment>
      {!isLoading && data.length === 0 && (
        <StyledAlert severity={severity} variant={variant} style={styleAlert} classes={classes}>
          {text}
        </StyledAlert>
      )}
    </React.Fragment>
  );
};

ModifiedAlert.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  severity: PropTypes.string.isRequired,
  variant: PropTypes.string,
  styleAlert: PropTypes.object,
  data: PropTypes.array,
  classes: PropTypes.object,
};

ModifiedAlert.defaultProps = {
  variant: 'outlined',
  styleAlert: {},
  data: [],
  classes: {},
};

export default ModifiedAlert;
