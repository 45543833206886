import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';
import AccountIntegrationsContainer from '../../../containers/AccountIntegrations';
import RichAd from 'app/components/Ads/RichAd';

const IntegrationsPage = () => (
  <Grid container>
    {/* <RichAd vertical={false} /> */}

    <MetaTags>
      <title>Account Integrations | ArenaMaster</title>
    </MetaTags>

    <Grid item sm={12}>
      <h1 className="title">INTEGRATIONS</h1>

      <AccountIntegrationsContainer />
    </Grid>
  </Grid>
);

export default IntegrationsPage;
