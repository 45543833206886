import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/';
import { handleSignOut as handleSignOutAction } from '../actions/auth';

const HeaderContainer = props => <Header {...props} />;

HeaderContainer.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { currentAccount }, utils: { region } }) => ({
  currentAccount,
  region,
});

export default withRouter(
  connect(mapStateToProps, {
    handleSignOut: handleSignOutAction,
  })(HeaderContainer)
);
