import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Axios from 'utils/axios';
import { getClassIcon, getSpecIcon, getClassColor, getPlayersRatingColor } from 'app/utils/helpers';

const SpecRepresentation = ({ region, queryString }) => {
  const [meleeList, setMeleeList] = useState([]);
  const [rangedList, setRangedList] = useState([]);
  const [healingList, setHealingList] = useState([]);
  const types = ['&type=melee', '&type=ranged', ''];

  const getClasses = (title, typeList) => (
    <React.Fragment>
      <div className="title">
        <h2>{title}</h2>
        <h2>Players / Percent</h2>
      </div>

      {typeList.map((type, index) => (
        <div key={index} className="classes">
          <img src={getClassIcon(type.class)} alt={type.class} className="class-icon" />
          <img src={getSpecIcon(type.class, type.spec)} alt={type.spec} className="spec-icon" />

          <div className="spec-representation">
            <span style={{ color: getClassColor(type.class) }} className="name">
              {type.spec} {type.class}
            </span>

            <span style={{ color: getPlayersRatingColor(type.percentage) }} className="rating">
              {type.count} / {type.percentage}%
            </span>
          </div>
        </div>
      ))}
    </React.Fragment>
  );

  useEffect(() => {
    types.forEach(type => {
      Axios.get(`/api/v1/${region}/spec_representation?${queryString}${type}`).then(response => {
        if (type === '&type=melee') {
          setMeleeList(response.data);
        } else if (type === '&type=ranged') {
          setRangedList(response.data);
        } else {
          setHealingList(response.data);
        }
      });
    });
  }, [region, queryString]);

  return (
    <React.Fragment>
      <h2 className="subtitle">Specialization Representation</h2>

      <Grid container className="groups">
        <Grid item xs={12} md={6} lg={4} className="type">
          {getClasses('Melee', meleeList)}
        </Grid>

        <Grid item xs={12} md={6} lg={4} className="type">
          {getClasses('Ranged', rangedList)}
        </Grid>

        <Grid item xs={12} md={6} lg={4} className="type">
          {getClasses('Healing', healingList)}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

SpecRepresentation.propTypes = {
  region: PropTypes.string.isRequired,
  queryString: PropTypes.string.isRequired,
};

export default SpecRepresentation;
