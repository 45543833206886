import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import {
  getRaceIcon,
  getClassIcon,
  getRatingColor,
  getClassColor,
  getFactionColor,
  getWinRateColor,
  getWinPercentage,
} from 'utils/helpers';
import Axios from 'app/utils/axios';
import { getRegionIcon } from 'app/utils/helpers';
import FactionIcon from '../FactionIcon';
import RichAd from '../Ads/RichAd';
import DynamicAd970x250 from '../Ads/DynamicAd970x250';
import StaticAd728x90 from '../Ads/StaticAd768x90';
import '../TBCRow/TBCRow.scss';
import './TbcTeamProfile.scss';

const bottomAdStyles = { margin: '0 auto 20px auto' };
const topAdStyles = { marginTop: 20 };

const TbcTeamProfile = () => {
  const [team, setTeam] = useState(null);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    Axios.get(`/api/v1/tbc_teams/${id}`).then(response => {
      setTeam(response.data);
    });
  }, []);

  if (!team) return null;

  return (
    <>
      <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

      <Grid container className="tbc-team-profile">
        <RichAd />

        <Grid item xs={11} lg={10} xl={7} className="profile-card">
          <div className="team-name">
            <div className="name">
              <img
                src={getRegionIcon(team.region.toUpperCase())}
                width={35}
                style={{ marginRight: 10 }}
                alt="Region"
              />

              <h2>
                <span style={{ color: getFactionColor(team.faction) }}>
                  {team.name} - {team.realm}
                </span>
              </h2>

              <FactionIcon faction={team.faction} />
            </div>

            <img src={team.emblem_image_url || team.emblem_url} alt="Team Crest" width="120"></img>
          </div>

          <div className="team">
            <Grid item xs={12} md={5} lg={3} style={{ width: '100%' }}>
              <h3>Team</h3>

              <div className="team-header">
                <span className="team-bracket">{team.bracket}</span>

                <div className="team-rank">
                  Rank: <span style={{ color: getRatingColor(team.rating) }}>{team.rank}</span>
                </div>
              </div>

              <div className="team-body">
                <div className="row">
                  <span>Rating</span>
                  <span
                    style={{
                      color: getRatingColor(team.rating),
                      fontWeight: 'bold',
                      fontSize: 36,
                    }}
                  >
                    {team.rating}
                  </span>
                </div>

                <div className="divider" />

                <div>
                  <span
                    style={{
                      color: getWinRateColor(getWinPercentage(team.season_won, team.season_played)),
                    }}
                  >
                    {getWinPercentage(team.season_won, team.season_played)}% win rate
                  </span>
                </div>

                <div className="divider" />

                <div
                  className="row"
                  style={{ lineHeight: '22px', paddingTop: 0, paddingBottom: 20 }}
                >
                  <span style={{ color: '#53ca41' }}>{team.season_won} won</span>
                  <span style={{ color: '#c43244' }}>{team.season_lost} lost</span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={9} style={{ width: '100%' }} className="team-members">
              <h3>Team Members</h3>

              <div style={{ overflowX: 'auto' }}>
                <table id="members-table">
                  <thead>
                    <tr className="team-header" style={{ margin: 0 }}>
                      <th>Name - Realm</th>
                      <th>Character</th>
                      <th>Rating</th>
                      <th>Win%</th>
                      <th>Won</th>
                      <th>Lost</th>
                    </tr>
                  </thead>

                  <tbody className="team-body">
                    {team.members.map((member, index) => (
                      <tr
                        key={member.id}
                        style={
                          index % 2
                            ? { backgroundColor: 'rgba(21, 27, 32, 0.6)' }
                            : { backgroundColor: '#111516' }
                        }
                        onClick={() => history.push(`/tbc-rankings/characters/${member.id}`)}
                      >
                        <td style={{ color: getClassColor(member.character_class) }}>
                          {member.name} - {member.realm}
                        </td>

                        <td className="specs">
                          <Tooltip title={member.race} classes={{ tooltip: 'black-tooltip' }}>
                            <img
                              src={getRaceIcon(member.gender.toLowerCase(), member.race)}
                              alt="Character Race"
                            />
                          </Tooltip>

                          {member.character_class && (
                            <Tooltip
                              title={member.character_class}
                              classes={{ tooltip: 'black-tooltip' }}
                            >
                              <img
                                src={getClassIcon(member.character_class)}
                                className="class-icon"
                                alt="Character Class"
                              />
                            </Tooltip>
                          )}
                        </td>

                        <td>{member.rating}</td>
                        <td
                          style={{
                            color: getWinRateColor(getWinPercentage(member.won, member.played)),
                          }}
                        >
                          {getWinPercentage(member.won, member.played)}%
                        </td>
                        <td>{member.won}</td>
                        <td>{member.lost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
    </>
  );
};

export default TbcTeamProfile;
