import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthModal from '../components/AuthModal';
import {
  openAuthModal as openAuthModalAction,
  closeAuthModal as closeAuthModalAction,
} from '../actions/auth';

class AuthModalContainer extends Component {
  static propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    modalTab: PropTypes.string.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
  };

  render() {
    return <AuthModal {...this.props} />;
  }
}

const mapStateToProps = ({ auth }) => {
  const { modalOpen, modalTab } = auth;

  return { modalOpen, modalTab };
};

export default connect(
  mapStateToProps,
  {
    closeAuthModal: closeAuthModalAction,
    openAuthModal: openAuthModalAction,
  },
)(AuthModalContainer);
