/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import CharacterDropdown from '../CharacterDropdown';
import ShowLfgHeroButtonContainer from 'containers/ShowLfgHeroButton';
import DynamicAd300x250 from 'app/components/Ads/DynamicAd300x250';
import BnetIcon from 'assets/integrations/bnet.svg';
import './LfgFilters.scss';

const bottomAdStyles = { marginLeft: 'auto', marginRight: 'auto' };

const LfgFilters = ({ currentAccount, characters, region, filters, setLfgFilters }) => (
  <div className="lfg-filters">
    <div style={{ width: '100%', borderBottom: '1px solid #1f2527', marginBottom: 20 }}>
      <Button
        className={`select-button ${filters.bracket_type === 'arena' ? 'selected-button' : ''}`}
      >
        Arena
      </Button>

      <Tooltip title="Coming soon" placement="top">
        <Button className="select-button coming-soon">Rated Battleground</Button>
      </Tooltip>

      <Button
        onClick={() => setLfgFilters({ bracket: '2v2' })}
        className={`select-button ${filters.bracket === '2v2' ? 'selected-button' : ''}`}
      >
        2v2
      </Button>

      <Button
        onClick={() => setLfgFilters({ bracket: '3v3' })}
        className={`select-button ${filters.bracket === '3v3' ? 'selected-button' : ''}`}
      >
        3v3
      </Button>
    </div>

    {currentAccount.bnet_connected && characters.length > 0 && (
      <CharacterDropdown
        characters={characters}
        bracket={filters.bracket}
        handleSelectCharacter={id => setLfgFilters({ character_id: id })}
        selectedCharacterId={filters.character_id}
      />
    )}

    {!currentAccount.bnet_connected && (
      <a style={{ width: '100%' }} href={`/accounts/auth/bnet?region=${region}`}>
        <Button className="bnet-btn" variant="contained">
          <div style={{ display: 'flex', marginRight: '10px' }}>
            <BnetIcon width={32} height={32} fill="#fff" />
          </div>
          Connect with Battle Net
        </Button>
      </a>
    )}

    <ShowLfgHeroButtonContainer />

    <DynamicAd300x250 style={bottomAdStyles} />
  </div>
);

LfgFilters.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  characters: PropTypes.array.isRequired,
  region: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setLfgFilters: PropTypes.func.isRequired,
};

export default LfgFilters;
