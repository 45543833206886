import {
  SET_REGION,
  HIDE_LOADER,
  SHOW_LOADER,
  HANDLE_ASIDE_TOGGLE,
  TOGGLE_LFG_COLLAPSED,
} from '../constants/utils';
import { setCookie } from '../utils/cookies';

export const handleRegionChange = region => dispatch => {
  setCookie('arenaMasterBnetRegion', region);

  dispatch({
    type: SET_REGION,
    region,
  });
};

export const showLoader = () => ({
  type: SHOW_LOADER,
});

export const hideLoader = () => ({
  type: HIDE_LOADER,
});

export const handleAsideToggle = () => ({
  type: HANDLE_ASIDE_TOGGLE,
});

export const toggleLfgCollapsed = () => (dispatch, getState) => {
  const state = getState();
  const { lfgCollapsed } = state.utils;
  const nextCollapsed = !lfgCollapsed;

  setCookie('arenaMasterLfgCollapsed', nextCollapsed);
  dispatch({ type: TOGGLE_LFG_COLLAPSED, payload: nextCollapsed });
};

export const setLfgCollapsed = collapsed => dispatch => {
  dispatch({
    type: TOGGLE_LFG_COLLAPSED,
    payload: collapsed,
  });
};
