import { NotificationManager } from './notifications';
import Axios from '../utils/axios';
import { formatErrors } from '../utils/helpers';
import { UPDATE_ACCOUNT, API_URL } from '../constants/auth';
import { showLoader, hideLoader } from './utils';

export const updateAccount = data => ({
  type: UPDATE_ACCOUNT,
  payload: data,
});

export const updateAccountSettings = (data, formik) => dispatch =>
  Axios.patch('/accounts', { account: data })
    .then(response => {
      formik.setSubmitting(false);
      dispatch(updateAccount(response.data));
      dispatch(NotificationManager.success('Successfully updated account info!'));
    })
    .catch(error => {
      const { errors } = error.response.data;

      if (error.response.status === 422) {
        const formattedErrors = formatErrors(errors);
        formik.setErrors(formattedErrors);
      }

      formik.setSubmitting(false);
    });

export const updateNotificationSettings = data => dispatch => {
  dispatch(showLoader());

  Axios.patch(`${API_URL}/notifications`, { account: data })
    .then(response => {
      dispatch(hideLoader());
      dispatch(updateAccount(response.data));
      dispatch(NotificationManager.success('Successfully updated notification settings!'));
    })
    .catch(() => {
      dispatch(hideLoader());
      dispatch(NotificationManager.error('There was an error saving notification settings.'));
    });
};

export const updateDiscordRoles = currentAccount => dispatch => {
  if (!currentAccount.bnet_connected && !currentAccount.discord_connected) {
    return;
  }

  Axios.post(`${API_URL}/update_discord_roles`)
    .then(() => {
      dispatch(
        NotificationManager.success(
          'Successfully updated your Discord roles. Visit the server to check it out!'
        )
      );
    })
    .catch(() => {
      dispatch(NotificationManager.error('There was an error updating your Discord roles.'));
    });
};
