import React, { useEffect, useState } from 'react';
import { useParams, Route, NavLink } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';

import { fetchGroup, fetchGroupApplications } from '../actions/groups';
import GroupContainer from './Group';
import GroupApplications from '../components/GroupApplications';
import PatreonCTA from 'app/components/PatreonCTA';

const GroupDetailsPage = () => {
  const [fetched, setFetched] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentAccount, group } = useSelector(
    state => ({
      currentAccount: state.auth.currentAccount,
      group: state.groups[id],
    }),
    shallowEqual
  );

  const tabs = [
    { title: 'Group Details', value: `/lfg/groups/${id}` },
    { title: 'Group Applications', value: `/lfg/groups/${id}/applications` },
  ];

  const groupOwner = get(group, 'account_id', '') === currentAccount.id;

  useEffect(() => {
    if (!group && !fetched) {
      dispatch(fetchGroup(id)).then(() => setFetched(true));
      dispatch(fetchGroupApplications());
    }
  }, [group]);

  if (!group) return <CircularProgress style={{ margin: '0 auto' }} />;

  return (
    <Grid container>
      <PatreonCTA />

      <Grid item xs={12}>
        <div className="group-links">
          {tabs.map((title, index) => (
            <div className="link" key={index}>
              <NavLink to={title.value} activeClassName="active" exact>
                <li>{title.title}</li>
              </NavLink>
            </div>
          ))}

          <div className="link">
            <Tooltip title="Coming Soon">
              <a href="javascript:void(0)">
                <li>Group Chat</li>
              </a>
            </Tooltip>
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Route path="/lfg/groups/:id" exact>
          <GroupContainer
            group={group}
            viewable={false}
            editable={groupOwner}
            deletable={groupOwner}
            removable
            applicable={false}
            cancelApplication={false}
          />
        </Route>

        <Route path="/lfg/groups/:id/applications">
          <GroupApplications group={group} groupOwner={groupOwner} />
        </Route>
      </Grid>
    </Grid>
  );
};

export default GroupDetailsPage;
