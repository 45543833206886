import { combineReducers } from 'redux';
import { utilsReducer as utils } from './utils';
import { authReducer as auth } from './auth';
import { charactersReducer as characters } from './characters';
import { notificationsReducer as notifications } from './notifications';
import { groupsReducer as groups } from './groups';

const createRootReducer = combineReducers({
  auth,
  characters,
  utils,
  notifications,
  groups,
});

export default createRootReducer;
