import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FactionIcon from '../FactionIcon';
import {
  getClassIcon,
  getRatingColor,
  getRaceIcon,
  getSpecIcon,
  getClassColor,
  getCovenantIcon,
  getWinRateColor,
} from '../../utils/helpers';
import './RankingsTable.scss';

const columns = [
  {
    name: 'rank',
    label: 'Rank',
  },
  {
    name: 'rating',
    label: 'Rating',
    render: char => <span style={{ color: getRatingColor(char.rating) }}>{char.rating}</span>,
  },
  {
    name: 'name',
    label: 'Name - Realm',
    render: char => (
      <span style={{ color: getClassColor(char.character_class) }}>
        {char.name} - {char.realm_name}
      </span>
    ),
  },

  {
    name: 'character',
    label: 'Character Info',
    render: char => (
      <div style={{ display: 'flex', marginLeft: '-13px' }}>
        <Tooltip title={char.race} classes={{ tooltip: 'black-tooltip' }}>
          <img
            src={getRaceIcon(char.gender, char.race)}
            className="icon-style"
            alt="Character Race"
          />
        </Tooltip>

        <Tooltip title={char.character_class} classes={{ tooltip: 'black-tooltip' }}>
          <img
            src={getClassIcon(char.character_class)}
            className="icon-style"
            alt="Character Class"
          />
        </Tooltip>

        <Tooltip title={char.specialization} classes={{ tooltip: 'black-tooltip' }}>
          <img
            src={getSpecIcon(char.character_class, char.specialization)}
            className="icon-style"
            alt="Character Specialization"
          />
        </Tooltip>
      </div>
    ),
  },
  {
    name: 'faction',
    label: 'Faction',
    minWidth: 70,
    render: char => <FactionIcon faction={char.faction} styleIcon={{ marginTop: 8 }} />,
    align: 'center',
  },
  {
    name: 'win_percentage',
    label: 'Win %',
    render: char => (
      <span style={{ color: getWinRateColor(char.win_percentage) }}>{char.win_percentage}%</span>
    ),
    align: 'center',
  },
  { name: 'won', label: 'Won', align: 'center' },
  {
    name: 'lost',
    label: 'Lost',
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#151b20',
    border: '1px solid #1f2527',
  },
});

const RankingsTable = ({
  characters,
  handlePageChange,
  handlePerPageChange,
  perPage,
  currentPage,
  totalCount,
  region,
  handleSort,
  columnToSort,
  sortDirection,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className="table" style={{ borderCollapse: 'separate' }}>
        <TableHead id="table-header">
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.name}
                align={column.align}
                className="table-header-cell"
                style={{
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                onClick={() => handleSort(column.name)}
              >
                {column.label}
                {column.name === columnToSort &&
                  (sortDirection === 'asc' ? <ExpandMoreIcon /> : <ExpandLessIcon />)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {characters.length === 0 ? (
          <CircularProgress style={{ marginTop: 6 }} />
        ) : (
          <TableBody className="table-body">
            {characters.map(char => (
              <TableRow
                component={Link}
                to={`/${region.toLowerCase()}/${char.realm_slug}/${char.lowercase_name}`}
                target="_blank"
                hover
                role="checkbox"
                tabIndex={-1}
                key={char.id}
                className="table-row"
              >
                {columns.map(column => {
                  const value = column.render ? column.render(char) : char[column.name];

                  return (
                    <TableCell
                      key={column.name}
                      align={column.align}
                      style={{
                        borderBottom: '1px solid #1f2527',
                      }}
                    >
                      {column.format && typeof value === 'number' ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      <TablePagination
        classes={{
          caption: classes.caption,
          input: classes.input,
          selectIcon: classes.selectIcon,
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={(event, page) => {
          handlePageChange(page);
        }}
        onChangeRowsPerPage={handlePerPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          `Page ${currentPage + 1}, Characters ${from}-${to} of ${count}`
        }
        style={{
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRight: 0,
          borderLeft: 0,
        }}
      />
    </Paper>
  );
};

RankingsTable.propTypes = {
  characters: PropTypes.array.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePerPageChange: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  region: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  columnToSort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

const mapStateToProps = ({ utils: { region } }) => ({
  region,
});

export default connect(mapStateToProps)(RankingsTable);
