export const SET_LOCALE = 'SET_LOCALE';
export const SET_REGION = 'SET_REGION';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const HANDLE_ASIDE_TOGGLE = 'HANDLE_ASIDE_TOGGLE';
export const TOGGLE_LFG_COLLAPSED = 'TOGGLE_LFG_COLLAPSED';
export const PATREON_MODAL = 'PATREON_MODAL';

export const webSocketUrl = () => {
  const { location } = window; // eslint-disable-line no-undef
  const { host } = location;
  const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';

  return `${protocol}//${host}/cable`;
};

export const WEB_SOCKET_URL =
  process.env.NODE_ENV === 'production'
    ? 'wss://arenamaster.io/cable'
    : 'ws://localhost:3000/cable';

export const GOOGLE_ANALYTICS_ID =
  process.env.NODE_ENV === 'production'
    ? 'G-KK5Q9P22GJ' // Production KEY
    : ''; // Development KEY

export const changeRegion = region => ({ type: SET_REGION, region });
