import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import orderBy from 'lodash/orderBy';
import { MetaTags } from 'react-meta-tags';

import Hero from 'components/Hero';
import BracketFilters from 'components/BracketFilters';
import FactionFilters from 'components/FactionFilters';
import RankingsTable from 'components/RankingsTable';
import CharacterClassesFilters from 'components/CharacterClassesFilters';
import RichAd from 'app/components/Ads/RichAd';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import StaticAd728x90 from 'app/components/Ads/StaticAd768x90';
import './Rankings.scss';

const bottomAdStyles = { marginLeft: 'auto', marginRight: 'auto' };
const topAdStyles = { marginTop: 20 };

const RankingsPage = ({
  currentBracket,
  currentFaction,
  perPage,
  currentPage,
  totalCount,
  characters,
  handleBracketChange,
  handleFactionChange,
  handlePageChange,
  handlePerPageChange,
  currentCharacterClassId,
  characterClasses,
  handleCharacterClassChange,
  handleSpecializationChange,
  specializationIds,
  toggleClass,
  handleSort,
  columnToSort,
  sortDirection,
}) => (
  <Fragment>
    <RichAd />

    <MetaTags>
      <title>WoW Arena & RBG Ladder | ArenaMaster</title>
      <meta
        name="description"
        content="ArenaMaster World of Warcraft PvP Rankings for 2v2 & 3v3 Arena and Rated Battlegrounds"
      />
    </MetaTags>

    <Hero />

    <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

    <Grid item className="filters-container" xs={11} xl={8}>
      <Grid item className="filters">
        <BracketFilters currentBracket={currentBracket} handleBracketChange={handleBracketChange} />

        <div className="title">
          <h1>World of Warcraft Arena & RBG Ladder</h1>

          <p>Filter by bracket, faction and character class</p>
        </div>

        <FactionFilters currentFaction={currentFaction} handleFactionChange={handleFactionChange} />
      </Grid>

      <Grid item className="character-classes-container">
        <CharacterClassesFilters
          currentCharacterClassId={currentCharacterClassId}
          characterClasses={characterClasses}
          handleCharacterClassChange={handleCharacterClassChange}
          handleSpecializationChange={handleSpecializationChange}
          specializationIds={specializationIds}
          toggleClass={toggleClass}
        />
      </Grid>

      <Grid item className="rankings-table">
        <RankingsTable
          perPage={perPage}
          currentPage={currentPage}
          totalCount={totalCount}
          characters={orderBy(characters, columnToSort, sortDirection)}
          handlePageChange={handlePageChange}
          handlePerPageChange={handlePerPageChange}
          handleSort={handleSort}
          columnToSort={columnToSort}
          sortDirection={sortDirection}
        />
      </Grid>

      <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
    </Grid>
  </Fragment>
);

RankingsPage.propTypes = {
  currentBracket: PropTypes.string.isRequired,
  currentFaction: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  characters: PropTypes.array.isRequired,
  handleBracketChange: PropTypes.func.isRequired,
  handleFactionChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePerPageChange: PropTypes.func.isRequired,
  currentCharacterClassId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  characterClasses: PropTypes.array.isRequired,
  handleCharacterClassChange: PropTypes.func.isRequired,
  handleSpecializationChange: PropTypes.func.isRequired,
  toggleClass: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  columnToSort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

RankingsPage.defaultProps = {
  currentCharacterClassId: null,
};

export default RankingsPage;
