import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isServer } from '../../utils/helpers';

class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && !isServer()) {
      window.scrollTo(0, 0); // eslint-disable-line no-undef
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
