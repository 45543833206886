import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import ModifiedAlert from '../components/Alert';
import { fetchMyGroups as fetchMyGroupsAction } from '../actions/groups';
import GroupList from '../components/GroupList';
import { warningNotification } from 'app/actions/notifications';
import PatreonCTA from 'app/components/PatreonCTA';

class MyGroupsPageContainer extends Component {
  static propTypes = {
    groups: PropTypes.array.isRequired,
    fetchMyGroups: PropTypes.func.isRequired,
    myGroupsFetched: PropTypes.bool.isRequired,
    currentAccount: PropTypes.object.isRequired,
    warningNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchMyGroups, myGroupsFetched, warningNotification, currentAccount } = this.props;

    if (!currentAccount.bnet_connected) {
      warningNotification('You must connect with Battle.net to use the LFG.');
      return;
    }

    if (!myGroupsFetched) {
      fetchMyGroups();
    }
  }

  render() {
    const { currentAccount } = this.props;

    return (
      <React.Fragment>
        <MetaTags>
          <title>My Groups | ArenaMaster</title>
        </MetaTags>

        <PatreonCTA />

        {!currentAccount.bnet_connected ? (
          <Grid item md={12} style={{ width: '100%' }}>
            <ModifiedAlert text="You need to connect with Battle.net first." severity="info" />
          </Grid>
        ) : (
          <Grid item md={12} style={{ width: '100%' }}>
            <GroupList {...this.props} viewable removable applicable={false} />
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ groups: { myGroups, myGroupsFetched }, auth }) => {
  const { currentAccount } = auth;

  return {
    groups: myGroups,
    myGroupsFetched,
    currentAccount,
  };
};

export default connect(mapStateToProps, {
  fetchMyGroups: fetchMyGroupsAction,
  warningNotification,
})(MyGroupsPageContainer);
