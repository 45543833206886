import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import BracketStat from '../BracketStat';
import CharacterInfoContainer from 'containers/CharacterInfo';
import CharacterItems from '../CharacterItems';
import CharacterTalents from '../CharacterTalents';
import PatreonCTA from '../PatreonCTA';
import CharacterStats from 'app/components/CharacterStats';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import DynamicAd300x250 from '../Ads/DynamicAd300x250';
import './CharacterProfile.scss';
import StaticAd728x90 from '../Ads/StaticAd768x90';

const topAdStyles = { marginTop: 10, marginBottom: 20 };
const mobTopAdStyles = { marginTop: 0, marginBottom: 20 };

class CharacterProfile extends Component {
  static propTypes = {
    character: PropTypes.object.isRequired,
  };

  render() {
    const { character } = this.props;

    return (
      <main id="character-profile">
        <RichAd vertical={false} />

        <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={mobTopAdStyles} />

        <Grid container>
          <Grid item xs={12} lg={4} style={{ marginBottom: 20 }}>
            <CharacterInfoContainer character={character} />

            <DynamicAd300x250 />
          </Grid>

          <Grid item xs={12} lg={8} className="right-side">
            <Grid container className="bracket-stats-container">
              {Object.keys(character.bracket_stats)
                .sort((a, b) => a.order - b.order)
                .map(bracketKey => (
                  <Grid item xs={12} lg={4} key={bracketKey}>
                    <BracketStat
                      title={bracketKey === 'rbg' ? bracketKey.toUpperCase() : bracketKey}
                      bracket={character.bracket_stats[bracketKey]}
                    />
                  </Grid>
                ))}
            </Grid>

            <CharacterStats character={character} />
            <CharacterItems character={character} />

            {character.talents_valid && (
              <CharacterTalents talents={character.talents} character={character} />
            )}

            <PatreonCTA />

            <DynamicAd970x250 />
          </Grid>
        </Grid>
      </main>
    );
  }
}

export default CharacterProfile;
