import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderContainer = ({ styleLoader }) => {
  const loader = useSelector(state => state.utils.loader);
  const isLoading = useSelector(state => state.auth.currentAccount.isLoading);

  if (loader || isLoading) {
    return <CircularProgress style={styleLoader} />;
  }

  return <div />;
};

LoaderContainer.propTypes = {
  styleLoader: PropTypes.object,
};

LoaderContainer.defaultProps = {
  styleLoader: {},
};

export default LoaderContainer;
