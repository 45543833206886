import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import HomePage from './pages/Home/';
import CharacterProfileContainer from './containers/CharacterProfile';
import SettingsPage from './containers/SettingsPage';
import NotFoundPage from './pages/NotFound';
import ResetPasswordForm from './components/ResetPasswordForm';
import Addon from './components/Addon';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';
import LfgPageContainer from './containers/LfgPageContainer';
import ContactUsPage from './pages/ContactUs';
import RankingsPageContainer from './containers/Rankings';
import CookiePolicy from './pages/CookiePolicy';
import LadderStats from './pages/LadderStats';
import TwitchPage from './pages/Twitch';
import TbcPage from './pages/TbcPage';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/reset-password" component={ResetPasswordForm} />
        <Route path="/dashboard" component={SettingsPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/terms" component={TermsPage} />
        <Route path="/not-found" component={NotFoundPage} />
        <Route path="/lfg" component={LfgPageContainer} />
        <Route path="/tbc-rankings" component={TbcPage} />
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/rankings" component={RankingsPageContainer} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/addon" component={Addon} />
        <Route path="/ladder-stats" component={LadderStats} />
        <Route path="/twitch" component={TwitchPage} />
        <Route path="/:region/:realm/:name" component={CharacterProfileContainer} />
        <Redirect to="/not-found" />
      </Switch>
    );
  }
}
