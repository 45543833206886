import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import DynamicAd300x250 from 'app/components/Ads/DynamicAd300x250';
import RichAd from 'app/components/Ads/RichAd';
import NotificationSettingsFormContainer from 'containers/NotificationSettingsForm';

const NotificationsPage = () => (
  <React.Fragment>
    {/* <RichAd vertical={false} /> */}

    <MetaTags>
      <title>Account Notifications | ArenaMaster</title>
    </MetaTags>

    <Grid container>
      <Grid item sm={12} lg={7} xl={6}>
        <h1 className="title">NOTIFICATIONS</h1>
        <NotificationSettingsFormContainer />
      </Grid>

      <Grid style={{ margin: '0 auto' }} item lg={5}>
        {/* <DynamicAd300x250 className="dashboard-ads" /> */}
      </Grid>
    </Grid>
  </React.Fragment>
);

export default NotificationsPage;
