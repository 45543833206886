import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import Hero from 'components/Hero';

const bottomAdStyles = { marginTop: '-20px', marginBottom: 20 };

const Privacy = () => (
  <React.Fragment>
    <RichAd />

    <MetaTags>
      <title>Privacy Policy | ArenaMaster</title>
    </MetaTags>

    <Hero />

    <DynamicAd970x250 />

    <div className="footer-page">
      <Grid container justify="center">
        <Grid item xs={12} lg={8} xl={6}>
          <h2>Privacy Policy</h2>
          <p>
            Your privacy is important to us. It is ArenaMaster's policy to respect your privacy
            regarding any information we may collect from you across our website,&nbsp;
            <a href="https://arenamaster.io">https://arenamaster.io</a>, and other sites we own and
            operate.
          </p>

          <h3>1. What information do we collect?</h3>

          <p>
            ArenaMaster collects very little personal information. You are free to use the site
            without giving us any personal information but if you register we need your email
            address and password. If you connect with social media we associate and store your
            social media account with your ArenaMaster account. We collect information from you when
            you register on our site. We also record IP addresses for general security purposes. You
            may, however, visit our site anonymously.
          </p>

          <h3>2. What do we use your information for?</h3>

          <ol type="a">
            <li>
              To personalize your experience (your information helps us to better respond to your
              individual needs);
            </li>

            <li>
              To improve our website (we continually strive to improve our website offerings based
              on the information and feedback we receive from you);
            </li>

            <li>To administer a contest, promotion, survey or other site feature;</li>

            <li>
              To provide advertisements targeted to your interests. Advertising supports our network
              and pays the bills to keep our services running at not cost to our users.
            </li>
          </ol>

          <h3>3. How do we protect your information?</h3>

          <p>
            The website is hosted by top tier service providers who take privacy and security as
            seriously as you do. In order to protect the limited personal data we collect we
            implement physical, technial and administrative safeguards designed to keep data
            protected. Access to personal information is restricted to people who need it only.
          </p>

          <h3>4. Do we use cookies?</h3>

          <p>
            Yes - Cookies are small files that a site or its service provider transfers to your
            computers hard drive through your Web browser (if you allow) that enables the sites or
            service providers systems to recognize your browser and capture and remember certain
            information.
            <br />
            We use cookies to understand and save your preferences for future visits, keep track of
            advertisements and compile aggregate data about site traffic and site interaction so
            that we can offer better site experiences and tools in the future. We may contract with
            third-party service providers to assist us in better understanding our site visitors.
            These service providers are not permitted to use the information collected on our behalf
            except to help us conduct and improve our business.
          </p>

          <h3>5. 3rd Parties and Privacy</h3>

          <p>
            We do not sell, trade, or otherwise transfer to outside parties your personally
            identifiable information. This does not include trusted third parties who assist us in
            operating the site, conducting our business, or servicing you, so long as those parties
            agree to keep this information confidential. We may also release your information when
            we believe release is appropriate to comply with the law, enforce our site policies, or
            protect ours or others rights, property, or safety. However, non-personally identifiable
            visitor information may be provided to other parties for marketing, advertising, or
            other uses. The following 3rd parties are considered trusted and are used throughout the
            ArenaMaster:
            <ol type="a">
              <li>
                <a
                  href="https://www.blizzard.com/en-us/legal/a4380ee5-5c8d-4e3b-83b7-ea26d01a9918/blizzard-entertainment-online-privacy-policy"
                  target="blank"
                >
                  Battle.net
                </a>
                {', '}
                <a href="https://discord.com/privacy" target="blank">
                  Discord
                </a>
                {', '}
                and{' '}
                <a href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="blank">
                  Twitch
                </a>{' '}
                are all used for social logins and for sharing media. They all have their own
                privacy policies that you would have agreed to in order to use their services on our
                network. We do not have access to any account, except from the data you permitted
                when connecting with each service. You can always revoke any data or permissions on
                each related service settings.
              </li>

              <li>
                Google Analytics: The site uses Google Analytics to process data about how the site
                is used. We transmit usage data to Google in the United States and Europe for
                processing. Google stores this data for us to generate reports to help us understand
                how the site is accessed and used. Google Analytics does not identify individual
                users or associate your IP address with any other data held by Google. See Google's
                privacy policy. You can opt out of Google Analytics by disabling or blocking the
                cookie, disabling JavaScript or use Google's opt-out service.
              </li>

              <li>
                Venatus Media handles all advertising on our network. They collect data for direct
                and programmatic advertising sales through use of pixel trackers and ad tags. Learn
                more about{' '}
                <a href="https://venatus.com/" target="blank">
                  Venatus Media
                </a>{' '}
                and their{' '}
                <a href="https://venatus.com/privacy/" target="blank">
                  Privacy Policy
                </a>
                .
              </li>
            </ol>
          </p>

          <h3>6. Advertising</h3>

          <p>
            Through Venatus Media we use a wide variety of other companies under agreements with us
            to serve third-party advertisements when you visit and use the site. These companies may
            collect and use click stream information, browser type, time and date, subject of
            advertisements clicked or scrolled over during your visits to our network and other Web
            sites in order to provide advertisements about goods and services likely to be of
            greater interest to you. These companies typically use tracking technologies to collect
            this information. Other companies' use of their tracking technologies is subject to
            their own privacy policies, not this one.
          </p>

          <h3>7. Third party links</h3>

          <p>
            Occasionally, at our discretion, we may include or offer third party products or
            services on our website. These third party sites have separate and independent privacy
            policies. We therefore have no responsibility or liability for the content and
            activities of these linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>

          <h3>8. Compliance with regional privacy protection laws</h3>

          <ol type="a">
            <li>
              <h3>GDPR</h3>
              <br /> We strive to comply with the EU GDPR laws. We only collect personally
              identifiable information when registering on our site and this information only
              includes a username, email and password. You may request the removal of this personal
              information at any time by emailing us at support@arenamaster.io regardelss of your
              location. Content created on our website is not considered personally identifiable and
              is not included as part of this agreement.
            </li>

            <li>
              <h3>California Online Privacy Protection Act</h3>
              <br /> Because we value your privacy we have taken the necessary precautions to be in
              compliance with the California Online Privacy Protection Act. We therefore will not
              distribute your personal information to outside parties without your consent. We do
              not collect age, gender, location or other personal information about anyone.
            </li>

            <li>
              <h3>Childrens Online Privacy Protection Act</h3>
              <br /> The site is intended for general audiences. We do not knowingly collect
              personal information (as defined by the U.S. Children’s Privacy Protection Act, or
              “COPPA”) from children under 13 years of age. If you are a parent or guardian and
              believe we have collected personal information in violation of COPPA, contact us at
              support@arenamaster.io. We will remove the personal information in accordance with
              COPPA. We do not knowingly “sell”, as that term is defined under the CCPA, the
              personal information of minors under 16 years old who are California residents. By
              using the site, you represent that you are at least 18. If we learn that information
              from users less than 18 years of age has been collected, we will deactivate the
              account and take reasonable measures to promptly delete such data from our records. If
              you become aware of any data we have collected from individuals under age 18, please
              contact us at support@arenamaster.io.
            </li>
          </ol>

          <h3>9. Online Privacy Policy</h3>

          <p>
            This online privacy policy applies only to information collected through our website and
            not to information collected offline.
          </p>

          <h3>10. Your Consent</h3>

          <p>
            By using our site, you consent to our privacy policy, the use of cookies and the use of
            our 3rd party partners. Information collected by registering on this site, specifically
            email, username and password may be removed by emailing support@arenamaster.io.
          </p>

          <h3>11. Changes to our Privacy Policy</h3>

          <p>
            Any updates to our privacy policy will be posted on this page, and/or we'll update the
            Privacy Policy modification date below.
            <span
              style={{
                marginTop: 60,
                display: 'block',
                textDecoration: 'underline',
                textUnderlineOffset: 2,
              }}
            >
              This policy was last modified on June 25, 2021.
            </span>
          </p>
        </Grid>
      </Grid>
    </div>

    <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
  </React.Fragment>
);

export default Privacy;
