import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import './Achievements.scss';

const Achievements = ({ title, achievements, achievementMappings }) => {
  if (achievements.length < 1) return null;

  const completedDate = wowId => {
    const achievement = achievementMappings.find(a => a.id === wowId);

    return achievement ? achievement.date : '';
  };

  return (
    <Grid container className="achievements">
      <h5 className="text-shadow">{title}</h5>

      {achievements.map(achievement => (
        <Tooltip
          title={completedDate(achievement.wow_id)}
          placement="top"
          key={achievement.id}
          classes={{ tooltip: 'achievements-tooltip' }}
        >
          <Grid key={achievement.id} item xs={6} className="achievement">
            <img src={achievement.icon_url} alt={achievement.name} />

            <div>
              <span className="name">{achievement.name}</span>
              <span className="desc">{achievement.description}</span>
            </div>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

Achievements.propTypes = {
  title: PropTypes.string.isRequired,
  achievements: PropTypes.array.isRequired,
  achievementMappings: PropTypes.array.isRequired,
};

export default Achievements;
