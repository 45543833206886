import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import NotificationIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import LayersIcon from '@material-ui/icons/Layers';
import ViewListIcon from '@material-ui/icons/ViewList';
import FollowIcon from '@material-ui/icons/Favorite';
import Drawer from '@material-ui/core/Drawer';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { isServer } from 'utils/helpers';
import './SettingsAside.scss';

const styles = () => ({
  drawerPaper: {
    zIndex: 9,
    position: 'relative',
    border: 0,
  },
});

class SettingsAside extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    isAsideOpen: PropTypes.bool.isRequired,
  };

  wrappedHandleClick = () => {
    // eslint-disable-next-line no-undef
    if (!isServer() && window.innerWidth < 992) {
      return this.props.handleClick();
    }

    return null;
  };

  render() {
    const { classes, handleClick, isAsideOpen } = this.props;
    const isMobile = !isServer() && window.innerWidth < 768; // eslint-disable-line no-undef

    return (
      <Drawer
        anchor="left"
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isAsideOpen}
        onClose={handleClick}
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{
          height: '100%',
          position: 'sticky',
          top: '60px',
          bottom: '60px',
          left: '0',
        }}
      >
        <aside>
          <div className="settings">
            <h2>
              SETTINGS
              {isMobile ? (
                <IconButton onClick={handleClick} className="menu-icon">
                  <KeyboardArrowLeftIcon />
                </IconButton>
              ) : (
                ''
              )}
            </h2>

            <ul>
              <NavLink
                exact
                to="/dashboard"
                activeClassName="active"
                onClick={this.wrappedHandleClick}
              >
                <li>
                  <SettingsIcon className="settings-icon" />
                  <div>My Account</div>
                </li>
              </NavLink>

              <NavLink
                exact
                to="/dashboard/notifications"
                activeClassName="active"
                onClick={this.wrappedHandleClick}
              >
                <li>
                  <NotificationIcon className="settings-icon" />
                  <div>Notifications</div>
                </li>
              </NavLink>

              <NavLink
                exact
                to="/dashboard/integrations"
                activeClassName="active"
                onClick={this.wrappedHandleClick}
              >
                <li>
                  <LayersIcon className="settings-icon" />
                  <div>Integrations</div>
                </li>
              </NavLink>

              <NavLink
                exact
                to="/dashboard/characters"
                activeClassName="active"
                onClick={this.wrappedHandleClick}
              >
                <li>
                  <ViewListIcon className="settings-icon" />
                  <div>Character List</div>
                </li>
              </NavLink>

              <NavLink
                exact
                to="/dashboard/following"
                activeClassName="active"
                onClick={this.wrappedHandleClick}
              >
                <li>
                  <FollowIcon className="settings-icon" />
                  <div>Following</div>
                </li>
              </NavLink>
            </ul>

            {/* <h4>HELP</h4>

            <ul>
              <NavLink to="/settings/contact-us">
                <li>
                  Contact Us
                  <EmailIcon className="settings-icon" />
                </li>
              </NavLink>
              <NavLink to="/settings/report-bug">
                <li>
                  Report Bug
                  <BugReportIcon className="settings-icon" />
                </li>
              </NavLink>
            </ul> */}
          </div>
        </aside>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(SettingsAside));
