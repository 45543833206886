import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dynamic300x250Id } from 'app/constants/ads';

const DynamicAd300x250 = ({ style = {}, className = '' }) => {
  const patreon = useSelector(state => state.auth.currentAccount.patreon);
  const ref = createRef();

  useEffect(() => {
    if (patreon) return;

    window.__vm_add = window.__vm_add || [];
    window.__vm_add.push(ref.current);

    return () => {
      window.__vm_remove = window.__vm_remove || [];
      if (ref.current) window.__vm_remove.push(ref.current);
    };
  }, []);

  if (patreon) return null;

  return (
    <div style={style} className={`dynamic-300x250-container ${className}`}>
      <div className="vm-placement" data-id={dynamic300x250Id} ref={ref}></div>
    </div>
  );
};

export default DynamicAd300x250;
