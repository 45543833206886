/* eslint-disable camelcase */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import get from 'lodash/get';

import { cancelGroupApplication } from '../actions/groups';
import AboutCharacter from '../components/AboutCharacter';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 14,
  },
});

const CancelGroupApplicationContainer = ({ group }) => {
  const [open, setOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);

  const classes = useStyles();
  const { applications } = useSelector(state => ({
    applications: state.groups.myApplications.filter(a => a.group_id === group.id),
  }));
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSelectedApp(null);
  };

  return (
    <React.Fragment>
      <Button className="manage-apps-btn outlined-primary" onClick={handleOpen}>
        Manage Application{applications.length > 1 ? 's' : ''}
      </Button>

      <Dialog open={open} onClose={handleClose} className="dialog" style={{ minWidth: 600 }}>
        <DialogTitle className="dialog-title" style={{ paddingRight: 120 }}>
          Remove the character you applied with from this group.
        </DialogTitle>

        <DialogContent>
          {applications.map(a => (
            <AboutCharacter
              key={a.id}
              character={a.character}
              shouldUpdateMain={false}
              displayButtons={false}
              styles={{ marginTop: 15, padding: '0 15px' }}
            >
              {!selectedApp && (
                <Tooltip title="Cancel Application" classes={{ tooltip: classes.tooltip }}>
                  <IconButton
                    onClick={() => {
                      setSelectedApp(a);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </AboutCharacter>
          ))}
        </DialogContent>

        {selectedApp && (
          <DialogActions className="actions-buttons">
            <h3>
              Are you sure you want to cancel the group application for{' '}
              {get(selectedApp, 'character.name', null)}-
              {get(selectedApp, 'character.realm_name', null)}?
            </h3>

            <Button
              onClick={() => {
                dispatch(cancelGroupApplication(selectedApp, applications.length)).then(() =>
                  handleClose()
                );
              }}
              className="confirm-button"
            >
              Yes
            </Button>

            <Button onClick={handleClose} className="outlined-primary" style={{ marginLeft: 10 }}>
              No
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

CancelGroupApplicationContainer.propTypes = {
  group: PropTypes.object.isRequired,
};

export default CancelGroupApplicationContainer;
