import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, BarChart, Bar, Cell } from 'recharts';

import { getClassColor } from 'utils/helpers';

const CustomBarChart = ({ chartData, ratingComplete, currentBracket, currentFaction }) => {
  const totalCount = chartData.reduce((acc, curr) => acc + curr.count, 0);

  const customTooltip = ({ payload, active }) => {
    if (!active || payload === undefined || payload === null) {
      return null;
    }

    return (
      <div className="custom-tooltip">
        <div className="title">
          <span
            style={{
              color: getClassColor(payload[0].payload.class),
            }}
          >
            {currentBracket === 'solo'
              ? `${payload[0].payload.label} ${payload[0].payload.class}`
              : payload[0].payload.label}
          </span>

          <span>Count: {payload[0].payload.count}</span>
        </div>

        <p>
          <strong>{payload[0].payload.count}</strong> players out of <strong>{totalCount}</strong>{' '}
          are {payload[0].payload.label} players.
          <br /> They represent <strong>{payload[0].payload.percentage}%</strong> of{' '}
          {currentFaction === 'Both' ? 'total' : currentFaction} players on the{' '}
          <strong>{currentBracket}</strong> ladder
          <br /> from <strong>{ratingComplete.min_cr}</strong> to{' '}
          <strong>{ratingComplete.max_cr}</strong> rating.
        </p>
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={chartData}
        margin={{
          top: 50,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <XAxis dataKey={currentBracket === 'solo' ? 'label' : 'class'} interval={0} />
        <YAxis />
        <Tooltip
          contentStyle={{ backgroundColor: '#0c0d0e', borderColor: '#0c0d0e' }}
          cursor={false}
          content={customTooltip}
        />
        <Bar dataKey="count">
          {chartData.map((entry, index) => (
            <Cell fill={getClassColor(entry.class)} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

CustomBarChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  ratingComplete: PropTypes.object.isRequired,
  currentBracket: PropTypes.string.isRequired,
  currentFaction: PropTypes.string.isRequired,
};

export default CustomBarChart;
