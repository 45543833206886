import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { getRatingColor, getWinRateColor, getWinPercentage } from 'utils/helpers';

const TbcTeam = ({ bracket, member }) => {
  const history = useHistory();
  const team = get(member, `team_${bracket}_data`);

  if (isEmpty(team)) return null;

  return (
    <Grid item className="team" xs={12} sm={10} md={4} key={team.id}>
      <div className="team-title">
        <img
          src={team.emblem_image_url || team.emblem_url}
          onClick={() => history.push(`/tbc-rankings/teams/${team.id}`)}
          alt="Team Crest"
          width="120"
        ></img>

        <div className="member-team-name">
          <span className="name">{team.name} </span>
          <span style={{ color: getRatingColor(team.rating), fontSize: 24 }}>({team.rating})</span>
        </div>
      </div>

      <div className="team-header">
        <span>{team.bracket}</span>

        <div>
          Rank: <span style={{ color: getRatingColor(team.rating) }}>{team.rank}</span>
        </div>
      </div>

      <Tooltip title="Click to View Team and Team Members" classes={{ tooltip: 'big-tooltip' }}>
        <div className="team-body" onClick={() => history.push(`/tbc-rankings/teams/${team.id}`)}>
          <div className="row">
            <span>Player Rating</span>
            <span
              style={{
                color: getRatingColor(member[`rating_${bracket}`]),
                fontWeight: 'bold',
                fontSize: 36,
              }}
            >
              {member[`rating_${bracket}`]}
            </span>
          </div>

          <div className="divider" />

          <div>
            <span
              style={{
                color: getWinRateColor(
                  getWinPercentage(member[`won_${bracket}`], member[`played_${bracket}`])
                ),
              }}
            >
              {getWinPercentage(member[`won_${bracket}`], member[`played_${bracket}`])}% win rate
            </span>
          </div>

          <div className="divider" />

          <div className="row" style={{ lineHeight: '22px', paddingTop: 0, paddingBottom: 20 }}>
            <span style={{ color: '#53ca41' }}>{member[`won_${bracket}`]} won</span>
            <span style={{ color: '#c43244' }}>{member[`lost_${bracket}`]} lost</span>
          </div>
        </div>
      </Tooltip>
    </Grid>
  );
};

TbcTeam.propTypes = {
  bracket: PropTypes.string.isRequired,
  member: PropTypes.object,
};

TbcTeam.defaultProps = {
  member: null,
};

export default TbcTeam;
