import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { fetchGroup as fetchGroupAction } from '../actions/groups';
import NewGroupPageContainer from '../containers/NewGroupPage';

class EditGroupPageContainer extends Component {
  static propTypes = {
    group: PropTypes.object,
    fetchGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    group: null,
  };

  componentDidMount() {
    const {
      group,
      fetchGroup,
      match: { params },
    } = this.props;

    if (!group) {
      fetchGroup(params.id);
    }
  }

  render() {
    const { group } = this.props;

    if (!group) return <CircularProgress style={{ margin: '0 auto' }} />;
    return <NewGroupPageContainer group={group} />;
  }
}

const mapStateToProps = ({ groups }, { match }) => ({
  group: groups[match.params.id],
});

export default withRouter(connect(
  mapStateToProps,
  {
    fetchGroup: fetchGroupAction,
  },
)(EditGroupPageContainer));
