import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { saveBnetAccepted } from '../../utils/localStorage';
import bnet_allow from '../../assets/integrations/bnet_allow.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    padding: '10px 24px',
  },

  paperWidthSm: {
    maxWidth: '600px !important',
    padding: 10,
  },

  closeButton: {
    position: 'absolute',
    right: 18,
    top: 8,
  },

  closeIcon: {
    '&:hover': {
      color: '#c43244',
    },
  },
});

const BnetDialog = ({ open, handleClose, currentAccount, accepted, region }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <DialogTitle id="alert-dialog-slide-title" classes={{ root: classes.root }}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ color: '#fff', fontSize: 18, marginBottom: 0, marginTop: 15 }}
          className="description"
        >
          For the integration with Battle.net to be successful, you should{' '}
          <span>enable Your Blizzard Account ID and Battle Tag,</span> and{' '}
          <span>Your World of Warcraft profile</span> like in the picture below.
          <img src={bnet_allow} className="bnet-allow-img" />
          <span>
            We don't have access to the email you use for those services or the ability to perform
            any action on behalf of you.
          </span>{' '}
          We only need your character profile and Battle Tag so we can associate the character's
          ownership with your ArenaMaster account.
        </DialogContentText>
      </DialogContent>

      {!accepted && (
        <DialogActions className="got-it">
          {currentAccount.bnet_connected ? (
            <Button
              onClick={() => {
                saveBnetAccepted(true);
                handleClose();
              }}
              className="got-it-btn primary-btn"
            >
              Got it!
            </Button>
          ) : (
            <Button
              href={`/accounts/auth/bnet?region=${region}`}
              onClick={() => {
                saveBnetAccepted(true);
              }}
              className="got-it-btn primary-btn"
            >
              Continue
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

BnetDialog.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  accepted: PropTypes.bool,
};

BnetDialog.defaultProps = {
  accepted: undefined,
};

export default BnetDialog;
