import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import qs from 'qs';
import { MetaTags } from 'react-meta-tags';
import upperCase from 'lodash/upperCase';

import { isServer } from 'app/utils/helpers';
import Axios from 'app/utils/axios';
import FactionFilters from 'components/FactionFilters';
import ModifiedAlert from '../Alert';
import TBCRow from '../TBCRow';
import RichAd from '../Ads/RichAd';
import DynamicAd970x250 from '../Ads/DynamicAd970x250';
import StaticAd728x90 from '../Ads/StaticAd768x90';
import 'pages/Rankings/Rankings.scss';
import './TBCRankings.scss';

const useStyles = makeStyles({
  root: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#000',
    zIndex: 2,
  },
});

const bottomAdStyles = { margin: '-10px auto 20px auto' };
const topAdStyles = { marginTop: 20 };

const TBCRankings = () => {
  const [teams, setTeams] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFaction, setCurrentFaction] = useState('Both');
  const region = useSelector(state => state.utils.region).toLowerCase();
  const classes = useStyles();
  const { bracket } = useParams();

  const handleFactionChange = faction => setCurrentFaction(faction);

  const goToTop = () => {
    if (!isServer()) window.scrollTo(0, 0); // eslint-disable-line no-undef
  };

  const formatData = (teams, page, perPage) =>
    teams.map((team, index) => ({
      ...team,
      rank: page * perPage + index + 1,
    }));

  useEffect(() => {
    const handleScroll = () => {
      if (isServer() && window.innerWidth > 1024) return;

      const cells = Array.from(document.getElementsByClassName('table-header-cell')); // eslint-disable-line no-undef
      // eslint-disable-next-line no-undef
      if (window.scrollY >= 131) {
        cells.forEach(cell => cell.classList.add('sticky-header'));
      } else {
        cells.forEach(cell => cell.classList.remove('sticky-header'));
      }
    };

    if (!isServer()) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const query = {
      bracket: bracket,
      faction: currentFaction,
      region: region,
      per_page: perPage,
      page: currentPage + 1,
    };

    const queryString = qs.stringify(query, { arrayFormat: 'brackets' });

    Axios.get(
      `/api/v1/tbc_teams?${queryString}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then(response => {
      setTeams(formatData(response.data, currentPage, perPage));
      setTotalCount(+response.headers['pagination-count']);
      setIsLoaded(true);
      goToTop();
    });
  }, [region, bracket, perPage, currentPage, currentFaction]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [bracket, currentFaction, region]);

  return (
    <>
      <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

      <Grid item xl={8} className="tbc-rankings">
        <RichAd />

        <MetaTags>
          <title>
            TBC {upperCase(region)} {bracket} Arena Rankings | ArenaMaster
          </title>

          <meta name="description" content="The Burning Crusade Classic Arena Teams Leaderboard" />
        </MetaTags>

        <div className="content">
          <h1 style={{ textAlign: 'center' }}>The Burning Crusade Season 4 Arena Ladder</h1>

          <div className="container">
            <div className="filters">
              <NavLink
                to={`/tbc-rankings/${region}/2v2`}
                className="filter"
                activeClassName="active"
              >
                2v2
              </NavLink>

              <NavLink
                to={`/tbc-rankings/${region}/3v3`}
                className="filter"
                activeClassName="active"
              >
                3v3
              </NavLink>

              <NavLink
                to={`/tbc-rankings/${region}/5v5`}
                className="filter"
                activeClassName="active"
              >
                5v5
              </NavLink>
            </div>

            <div className="filters">
              <FactionFilters
                currentFaction={currentFaction}
                handleFactionChange={handleFactionChange}
              />
            </div>
          </div>

          {teams.length === 0 && isLoaded ? (
            <ModifiedAlert
              severity="info"
              text={`There are no ${bracket} ${
                currentFaction === 'Both' ? 'Horde or Alliance' : currentFaction
              } teams.`}
            />
          ) : (
            <TableContainer component={Paper} className="paper">
              <Table aria-label="collapsible table" className="table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ width: '5%' }}
                      className="table-header-cell"
                      classes={{ root: classes.root }}
                    />

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '5%' }}
                    >
                      Rank
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '15%' }}
                    >
                      Crest
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      style={{ width: '20%' }}
                    >
                      Team Name - Realm
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '15%' }}
                    >
                      Faction
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '10%' }}
                    >
                      Rating
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '10%' }}
                    >
                      Win %
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '10%' }}
                    >
                      Won
                    </TableCell>

                    <TableCell
                      classes={{ root: classes.root }}
                      className="table-header-cell"
                      align="center"
                      style={{ width: '10%' }}
                    >
                      Lost
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {teams.length === 0 && !isLoaded ? (
                    <CircularProgress style={{ marginTop: 7 }} />
                  ) : (
                    teams.map((team, index) => <TBCRow team={team} key={team.id} index={index} />)
                  )}
                </TableBody>
              </Table>

              <TablePagination
                classes={{
                  caption: classes.caption,
                  input: classes.input,
                  selectIcon: classes.selectIcon,
                }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={totalCount}
                rowsPerPage={perPage}
                page={currentPage}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={(event, page) => setCurrentPage(page)}
                onChangeRowsPerPage={event => setPerPage(+event.target.value)}
                labelDisplayedRows={({ from, to, count }) =>
                  `Page ${currentPage + 1}, Teams ${from}-${to} of ${count}`
                }
                style={{
                  border: '1px solid rgba(224, 224, 224, 1)',
                  borderRight: 0,
                  borderLeft: 0,
                }}
              />
            </TableContainer>
          )}
        </div>
      </Grid>

      <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
    </>
  );
};

export default TBCRankings;
