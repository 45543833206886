import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import BnetIcon from 'assets/integrations/bnet.svg';
import AboutCharacter from '../AboutCharacter/';
import ModifiedAlert from '../Alert';
import LoaderContainer from 'containers/Loader';
import './CharacterList.scss';

const CharacterList = ({
  characters,
  currentAccount,
  region,
  updateMainCharacter,
  updateAltHidden,
}) => (
  <div id="character-list">
    <div className="info">
      <div className="alert">
        {!currentAccount.bnet_connected && (
          <ModifiedAlert
            severity="info"
            text="You need to visit the integrations page and connect with your Battle.net account before you can manage your characters."
            styleAlert={{ margin: '10px 0 40px 0' }}
          />
        )}

        {currentAccount.bnet_connected && (
          <ModifiedAlert
            severity="info"
            text="You can manage your character list here. You can use ArenaMaster LFG with any of your connected characters. Make sure to select your main!"
            styleAlert={{ margin: '10px 0 20px 0' }}
          />
        )}
      </div>

      <div className="refresh">
        {currentAccount.bnet_connected && currentAccount.bnet_token_expired && (
          <a href={`/accounts/auth/bnet?region=${region}`} style={{ position: 'relative' }}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#01adff', textTransform: 'none', fontSize: '16px' }}
              className="battle-net-btn"
            >
              <div>
                <BnetIcon width={32} height={32} className="bnet-icon" fill="#fff" />
              </div>
              Refresh from Battle Net
            </Button>
          </a>
        )}
      </div>
    </div>

    <LoaderContainer styleLoader={{ top: '250px' }} />

    {characters
      .sort((a, b) => {
        if (a.main) {
          return -1;
        } else if (a.exp_3v3 > b.exp_3v3) {
          return 0;
        }
        return 1;
      })
      .map(character => (
        <AboutCharacter
          character={character}
          key={character.id}
          updateMainCharacter={updateMainCharacter}
          updateAltHidden={updateAltHidden}
          shouldUpdateMain
          charDetailsId="dashboard-char-details"
          buttonsId="dashboard-buttons"
        />
      ))}
  </div>
);

CharacterList.propTypes = {
  characters: PropTypes.array.isRequired,
  currentAccount: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  updateMainCharacter: PropTypes.func.isRequired,
  updateAltHidden: PropTypes.func.isRequired,
};

export default CharacterList;
