import React from 'react';
import { NavLink } from 'react-router-dom';

import kappa from 'assets/kappa.png';
import './Footer.scss';

const Footer = () => (
  <footer>
    <div className="kappa">
      ArenaMaster © 2021. Made with <img src={kappa} alt="Kappa" width="30px" />
      by ArenaMaster Team
    </div>

    <div className="footer-links">
      {/* <Link to="/faq">FAQ</Link> */}
      <NavLink to="/privacy" activeClassName="selected-link">
        Privacy Policy
      </NavLink>
      <NavLink to="/terms" activeClassName="selected-link">
        Terms and Conditions
      </NavLink>
      <NavLink to="/cookie-policy" activeClassName="selected-link">
        Cookie Policy
      </NavLink>
      <NavLink to="/contact-us" activeClassName="selected-link">
        Contact Us
      </NavLink>
    </div>
  </footer>
);

export default Footer;
