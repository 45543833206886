/* eslint-disable camelcase */

import { NotificationManager } from './notifications';
import Axios from '../utils/axios';
import { API_URL } from '../constants/auth';
import history from '../store/history';
import {
  SEARCH_GROUPS,
  FETCH_MY_GROUPS,
  FETCH_APPLIED_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  APPLY_TO_GROUP,
  SET_LFG_FILTERS,
  FETCH_GROUP_APPLICATIONS,
  FETCH_GROUP,
  CANCEL_GROUP_APPLICATION,
  REMOVE_APPLIED_GROUP,
  ACCEPT_GROUP_APPLICATION,
  DECLINE_GROUP_APPLICATION,
} from '../constants/groups';
import { hideLoader, showLoader } from './utils';

const handleErrors = (error, dispatch) => {
  const {
    response: {
      status,
      data: { errors },
    },
  } = error;

  if (status > 400 && status < 500) {
    errors.map(message => dispatch(NotificationManager.error(message)));
  }
};

export const resetSearchGroups = () => dispatch => {
  dispatch({ type: SEARCH_GROUPS, payload: [] });
};

export const setLfgFilters = filters => dispatch =>
  dispatch({ type: SET_LFG_FILTERS, payload: filters });

export const searchGroups = ({ bracket, character_id }) => dispatch => {
  Axios.get(`${API_URL}/groups/search?bracket=${bracket}&character_id=${character_id}`)
    .then(response => {
      dispatch({ type: SEARCH_GROUPS, payload: response.data });
    })
    .catch(error => {
      handleErrors(error, dispatch);
    });
};

export const createGroup = values => dispatch => {
  dispatch(showLoader());

  Axios.post(`${API_URL}/groups`, { group: values })
    .then(response => {
      dispatch(hideLoader());
      dispatch(NotificationManager.success('Successfully created your group!'));
      dispatch({ type: CREATE_GROUP, payload: response.data });
      history.push(`/lfg/groups/${response.data.id}`);
    })
    .catch(error => {
      dispatch(hideLoader());
      handleErrors(error, dispatch);
    });
};

export const updateGroup = (id, values) => dispatch => {
  dispatch(showLoader());

  Axios.put(`${API_URL}/groups/${id}`, { group: values })
    .then(response => {
      dispatch(hideLoader());
      dispatch({ type: UPDATE_GROUP, payload: response.data });
      dispatch(NotificationManager.success('Successfully updated your group!'));
      history.push('/lfg/my-groups');
    })
    .catch(error => {
      dispatch(hideLoader());
      handleErrors(error, dispatch);
    });
};

export const fetchGroup = id => dispatch => {
  dispatch(showLoader());

  return Axios.get(`${API_URL}/groups/${id}`)
    .then(response => {
      dispatch({ type: FETCH_GROUP, payload: response.data });
      dispatch(hideLoader());
      return response.data;
    })
    .catch(() => {
      history.replace('/lfg');
      dispatch(NotificationManager.warning('Error fetching the group.'));
      dispatch(hideLoader());
    });
};

export const fetchMyGroups = () => dispatch =>
  Axios.get(`${API_URL}/groups`)
    .then(response => {
      dispatch({ type: FETCH_MY_GROUPS, payload: response.data });
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your groups'));
    });

export const fetchAppliedGroups = () => dispatch =>
  Axios.get(`${API_URL}/groups/applied`)
    .then(response => {
      dispatch({ type: FETCH_APPLIED_GROUPS, payload: response.data });
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your applied groups'));
    });

export const deleteGroup = id => dispatch =>
  Axios.delete(`${API_URL}/groups/${id}`)
    .then(() => {
      dispatch({ type: DELETE_GROUP, payload: id });
      dispatch(NotificationManager.info('Successfully deleted your group'));
      history.replace('/lfg/my-groups');
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error deleting your group'));
    });

export const applyToGroup = (group, character_id, message) => dispatch =>
  Axios.post(`${API_URL}/group_applications`, {
    group_application: { group_id: group.id, character_id, message },
  })
    .then(response => {
      dispatch({
        type: APPLY_TO_GROUP,
        payload: {
          application: response.data,
          group,
        },
      });
      dispatch(NotificationManager.success('Successfully applied to group!'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error applying to group'));
    });

export const fetchGroupApplications = () => dispatch =>
  Axios.get(`${API_URL}/group_applications`)
    .then(response => {
      dispatch({ type: FETCH_GROUP_APPLICATIONS, payload: response.data });
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your group applications'));
    });

export const cancelGroupApplication = (app, size) => dispatch =>
  Axios.delete(`${API_URL}/group_applications/${app.id}`)
    .then(() => {
      if (size === 1) dispatch({ type: REMOVE_APPLIED_GROUP, payload: app.group_id });
      dispatch({ type: CANCEL_GROUP_APPLICATION, payload: app.id });
      dispatch(NotificationManager.info('Successfully canceled your group application.'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error removing group application'));
    });

export const acceptGroupApplication = id => dispatch =>
  Axios.post(`${API_URL}/group_applications/${id}/accept`)
    .then(response => {
      dispatch({ type: ACCEPT_GROUP_APPLICATION, payload: response.data });
      dispatch(NotificationManager.info('Successfully accepted the application.'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error accepting group applications'));
    });

export const declineGroupApplication = id => dispatch =>
  Axios.post(`${API_URL}/group_applications/${id}/decline`)
    .then(response => {
      dispatch({ type: DECLINE_GROUP_APPLICATION, payload: response.data });
      dispatch(NotificationManager.info('Successfully declined the application.'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error declining group applications'));
    });

export const removeFromGroup = (group_id, character_id, charName) => dispatch =>
  Axios.post(`${API_URL}/groups/${group_id}/remove_from_group`, { character_id })
    .then(response => {
      dispatch({ type: FETCH_GROUP, payload: response.data });
      dispatch(NotificationManager.info(`Successfully removed ${charName} from the group`));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error declining group applications'));
    });
