import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountSettingsForm from '../components/AccountSettingsForm';
import { updateAccountSettings as updateAccountSettingsAction } from '../actions/accounts';

const AccountSettingsContainer = ({ currentAccount, updateAccountSettings }) => (
  <AccountSettingsForm currentAccount={currentAccount} handleSubmit={updateAccountSettings} />
);

AccountSettingsContainer.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  updateAccountSettings: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentAccount } }) => ({
  currentAccount,
});

export default connect(
  mapStateToProps,
  { updateAccountSettings: updateAccountSettingsAction },
)(AccountSettingsContainer);
