import omit from 'lodash/omit';
import {
  SEARCH_GROUPS,
  FETCH_MY_GROUPS,
  FETCH_APPLIED_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  FETCH_GROUP,
  APPLY_TO_GROUP,
  SET_LFG_FILTERS,
  FETCH_GROUP_APPLICATIONS,
  CANCEL_GROUP_APPLICATION,
  REMOVE_APPLIED_GROUP,
  ACCEPT_GROUP_APPLICATION,
  DECLINE_GROUP_APPLICATION,
} from '../constants/groups';

const DEFAULT_STATE = {
  searchedGroups: [],
  myGroups: [],
  myGroupsFetched: false,
  appliedGroups: [],
  appliedGroupsFetched: false,
  filters: {
    character_id: null,
    bracket: '3v3',
    bracket_type: 'arena',
  },
  myApplications: [],
};

export const groupsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SEARCH_GROUPS:
      return {
        ...state,
        searchedGroups: action.payload,
      };

    case FETCH_MY_GROUPS:
      return {
        ...state,
        myGroups: action.payload,
        myGroupsFetched: true,
      };

    case FETCH_APPLIED_GROUPS:
      return {
        ...state,
        appliedGroups: action.payload,
        appliedGroupsFetched: true,
      };

    case FETCH_GROUP:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case CREATE_GROUP:
      return {
        ...state,
        myGroups: [...state.myGroups, action.payload],
      };

    case UPDATE_GROUP:
      return {
        ...state,
        myGroups: [...state.myGroups.filter(g => g.id !== action.payload.id), action.payload],
      };

    case DELETE_GROUP:
      return {
        ...omit(state, action.payload),
        myGroups: state.myGroups.filter(g => g.id !== action.payload),
      };

    case APPLY_TO_GROUP:
      return {
        ...state,
        searchedGroups: state.searchedGroups.filter(g => g.id !== action.payload.group.id),
        appliedGroups: state.appliedGroups.find(g => g.id === action.payload.group.id)
          ? state.appliedGroups
          : [...state.appliedGroups, action.payload.group],
        myApplications: [...state.myApplications, action.payload.application],
      };

    case SET_LFG_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case FETCH_GROUP_APPLICATIONS:
      return {
        ...state,
        myApplications: [...action.payload],
      };

    case CANCEL_GROUP_APPLICATION:
      return {
        ...state,
        myApplications: state.myApplications.filter(a => a.id !== action.payload),
      };

    case ACCEPT_GROUP_APPLICATION:
    case DECLINE_GROUP_APPLICATION:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case REMOVE_APPLIED_GROUP:
      return {
        ...state,
        appliedGroups: state.appliedGroups.filter(g => g.id !== action.payload),
      };

    default:
      return state;
  }
};
