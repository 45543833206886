import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import SignInFormContainer from '../../containers/SignInForm';
import SignUpFormContainer from '../../containers/SignUpForm';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import './AuthModal.scss';

const styles = {
  wrappingModal: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },

  toggleButton: {
    border: '1px solid #C43244',
    height: 32,
    padding: 0,
    width: 130,
    marginBottom: 45,
    borderRadius: 0,

    '&.active': {
      backgroundColor: '#C43244',
      color: '#fff',
    },
  },

  closeModalButton: {
    position: 'absolute',
    right: 0,
    margin: 0,
    padding: 0,
    marginLeft: 'auto',
    color: '#fff',
    height: 32,
    fontSize: 20,
  },
};

class AuthModal extends Component {
  static propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    modalTab: PropTypes.string.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { modalOpen, modalTab, closeAuthModal, openAuthModal, classes } = this.props;

    const forms = {
      SignIn: <SignInFormContainer />,
      SignUp: <SignUpFormContainer />,
      ForgotPassword: <ForgotPasswordForm openAuthModal={openAuthModal} />,
    };

    return (
      <Modal
        open={modalOpen}
        BackdropProps={{
          classes: { root: classes.wrappingModal },
        }}
      >
        <div className="modal-form-container">
          <div className="modal-form-image-container" />
          <div className="modal-form-content">
            <div className="modal-controls">
              {modalTab !== 'ForgotPassword' && (
                <React.Fragment>
                  <Button
                    classes={{ root: classes.toggleButton }}
                    className={modalTab === 'SignIn' ? 'active' : ''}
                    onClick={() => openAuthModal('SignIn')}
                    style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}
                  >
                    Login
                  </Button>

                  <Button
                    classes={{ root: classes.toggleButton }}
                    className={modalTab === 'SignUp' ? 'active' : ''}
                    onClick={() => openAuthModal('SignUp')}
                    style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}
                  >
                    Register
                  </Button>
                </React.Fragment>
              )}

              <IconButton
                classes={{ root: classes.closeModalButton }}
                onClick={closeAuthModal}
                style={{ color: '#C43244', position: 'relative' }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div style={{ position: 'relative' }}>
              {forms[modalTab]}

              {modalTab === 'SignIn' && (
                <Button
                  onClick={() => openAuthModal('ForgotPassword')}
                  className="forgot-password-btn btn-link"
                >
                  Forgot password?
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AuthModal);
