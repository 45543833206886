import React from 'react';
import PropTypes from 'prop-types';
import Horde from '../../assets/horde.svg';
import Alliance from '../../assets/alliance.svg';
import { FACTION_COLORS } from '../../constants/colors';

const FactionIcon = ({ faction, width, height, styleIcon }) => {
  const Icon = faction === 'Horde' ? Horde : Alliance;

  return <Icon width={width} height={height} style={styleIcon} fill={FACTION_COLORS[faction]} />;
};

FactionIcon.propTypes = {
  faction: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

FactionIcon.defaultProps = {
  width: 40,
  height: 40,
  styleIcon: {},
};

export default FactionIcon;
