import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCharacters as fetchCharactersAction } from '../actions/characters';
import { toggleLfgCollapsed as toggleLfgCollapsedAction } from '../actions/utils';
import LfgPage from '../pages/Lfg';

class LfgPageContainer extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    lfgCollapsed: PropTypes.bool.isRequired,
    toggleLfgCollapsed: PropTypes.func.isRequired,
    fetchCharacters: PropTypes.func.isRequired,
    characters: PropTypes.array.isRequired,
  };

  componentDidMount() {
    const { currentAccount, fetchCharacters, characters } = this.props;

    if (currentAccount.isSignedIn && currentAccount.bnet_connected && characters.length === 0) {
      fetchCharacters();
    }
  }

  render() {
    return <LfgPage {...this.props} />;
  }
}

const mapStateToProps = ({
  auth: { currentAccount },
  utils: { lfgCollapsed },
  characters: { connectedCharacters },
}) => ({
  lfgCollapsed,
  currentAccount,
  characters: connectedCharacters,
});

export default connect(
  mapStateToProps,
  {
    fetchCharacters: fetchCharactersAction,
    toggleLfgCollapsed: toggleLfgCollapsedAction,
  },
)(LfgPageContainer);
