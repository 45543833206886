import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import { enqueueSnackbar } from 'app/actions/notifications';
import Talent from '../Talent/';
import specIconMapping from 'constants/specializationIcons';
import './CharacterTalents.scss';

const IMG_URL = 'https://wow.zamimg.com/images/wow/icons/large';

const CharacterTalents = ({ talents }) => {
  const [selectedSpec, setSelectedSpec] = useState();
  const dispatch = useDispatch();
  const handleChange = name => setSelectedSpec(name);
  const currentSpec = talents.specializations.find(spec => spec.name === selectedSpec);

  useEffect(() => {
    if (!currentSpec) {
      setSelectedSpec(talents.active_specialization.name);
    }
  }, [talents]);

  if (!currentSpec) return null;

  return (
    <Grid container className="character-specialization bracket-stats-container">
      <div className="bracket talent-specialization">
        <div className="header">
          <h3>Specialization</h3>
        </div>

        <div className="content" style={{ padding: 0 }}>
          <div
            className={
              talents.specializations.length > 1 ? 'half-width specialization' : 'specialization'
            }
          >
            {talents.specializations.map(specialization => (
              <div
                key={specialization.name}
                className={`spec ${currentSpec.name === specialization.name ? 'selected' : ''}`}
                onClick={() => handleChange(specialization.name)}
              >
                <img
                  src={`${IMG_URL}/${specIconMapping[specialization.id]}`}
                  alt="Character specialization"
                />{' '}
                - <h3>{specialization.name}</h3>
                <CopyToClipboard
                  text={specialization.loadout_code}
                  onCopy={() => {
                    dispatch(
                      enqueueSnackbar({
                        message: 'Loadout code copied to clipboard!',
                        options: { variant: 'info' },
                      })
                    );
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    className="primary-btn copy-talents-btn"
                    size="small"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <FileCopyIcon fontSize="small" />
                    Copy talents
                  </Button>
                </CopyToClipboard>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bracket talent-list">
        {currentSpec.class_talents && (
          <div className="all-talents">
            <h3 className="header">Class Talents</h3>

            <div className="content">
              <div className="talents">
                {currentSpec.class_talents.map(talent => (
                  <div className="talent" key={talent.name}>
                    <Talent talent={talent} />
                    <span style={{ marginBottom: 5 }}>
                      {talent.name}
                      {talent.rank > 1 && (
                        <>
                          <br />
                          <small style={{ fontSize: 11, color: 'darkgray' }}>
                            Rank {talent.rank}
                          </small>
                        </>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {currentSpec.talents && (
          <div className="all-talents">
            <h3 className="header">Spec Talents</h3>

            <div className="content">
              <div className="talents">
                {currentSpec.talents
                  .sort((a, b) => a.tier - b.tier)
                  .map(talent => (
                    <div className="talent" key={talent.name}>
                      <Talent talent={talent} />
                      <span style={{ marginBottom: 5 }}>{talent.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {currentSpec.pvp_talents && (
          <div id="pvp-talents">
            <h3 className="header">PvP Talents</h3>

            <div className="content">
              <div className="talents">
                {currentSpec.pvp_talents
                  .sort((a, b) => a.slot_number - b.slot_number)
                  .map(talent => (
                    <div className="talent" key={talent.name}>
                      <Talent talent={talent} />
                      <span style={{ marginBottom: 5 }}>{talent.name}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
};

CharacterTalents.propTypes = {
  talents: PropTypes.object.isRequired,
};

export default CharacterTalents;
