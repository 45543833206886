/* eslint-disable camelcase */
import get from 'lodash/get';

import {
  ADD_CHARACTER,
  REFRESH_CHARACTER,
  FOLLOW_CHARACTER,
  UNFOLLOW_CHARACTER,
  ADD_CHARACTERS,
  ADD_CHARACTER_CLASSES,
  ADD_FOLLOWING_CHARACTERS,
  UPDATE_CONNECTED_CHARACTER,
} from '../constants/characters';

const INITIAL_STATE = {
  eu: {},
  us: {},
  connectedCharacters: [],
  characterClasses: [],
  followingCharacters: [],
  followingCharactersFetched: false,
};

export const charactersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CHARACTER:
    case REFRESH_CHARACTER: {
      const { region, realm, character } = action.payload;
      const regionKey = region.toLowerCase();
      const characterKey = character.lowercase_name;

      return {
        ...state,
        [regionKey]: {
          ...state[regionKey],
          [realm]: {
            ...state[regionKey][realm],
            [characterKey]: {
              ...character,
              bracket_stats: character.bracket_stats,
              achievements: character.achievements,
            },
          },
        },
      };
    }

    case UPDATE_CONNECTED_CHARACTER: {
      const { character } = action.payload;
      const regionKey = character.region.toLowerCase();
      const characterKey = character.lowercase_name;
      const realm = character.realm_slug;
      const storeCharacter = get(state, [regionKey, realm, characterKey]);

      const defaultState = {
        ...state,
        connectedCharacters: state.connectedCharacters.map(char =>
          char.id === character.id ? character : char
        ),
      };

      if (storeCharacter) {
        return {
          ...defaultState,
          [regionKey]: {
            ...state[regionKey],
            [realm]: {
              ...state[regionKey][realm],
              [characterKey]: {
                ...get(state, [regionKey, realm, characterKey], {}),
                alt_hidden: character.alt_hidden,
              },
            },
          },
        };
      }

      return defaultState;
    }

    case FOLLOW_CHARACTER:
    case UNFOLLOW_CHARACTER: {
      const { region, realm_slug, is_following, lowercase_name: characterKey } = action.payload;
      const regionKey = region.toLowerCase();
      const followingCharacters =
        action.type === FOLLOW_CHARACTER
          ? [...state.followingCharacters, state[regionKey][realm_slug][characterKey]]
          : // eslint-disable-next-line max-len
            state.followingCharacters.filter(
              c => c.id !== state[regionKey][realm_slug][characterKey].id
            );

      return {
        ...state,
        [regionKey]: {
          [realm_slug]: {
            ...state[regionKey][realm_slug],
            [characterKey]: {
              ...state[regionKey][realm_slug][characterKey],
              is_following,
            },
          },
        },
        followingCharacters,
      };
    }

    case ADD_CHARACTERS: {
      return {
        ...state,
        connectedCharacters: action.payload,
      };
    }

    case ADD_CHARACTER_CLASSES: {
      return {
        ...state,
        characterClasses: action.payload,
      };
    }

    case ADD_FOLLOWING_CHARACTERS:
      return {
        ...state,
        followingCharacters: action.payload,
        followingCharactersFetched: true,
      };

    default:
      break;
  }

  return state;
};
