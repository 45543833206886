import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SettingsAside from '../components/SettingsAside';
import { handleAsideToggle } from '../actions/utils';

const SettingsAsideContainer = props => <SettingsAside {...props} />;

const mapStateToProps = state => ({ isAsideOpen: state.utils.isAsideOpen });

export default withRouter(connect(
  mapStateToProps,
  { handleClick: handleAsideToggle },
)(SettingsAsideContainer));
