import React, { useState } from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Badge from '@material-ui/core/Badge';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import CloseIcon from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TimeAgo from 'react-timeago';

import { getRaceIcon, getClassIcon, getSpecIcon, getClassColor } from 'utils/helpers';
import ApplyGroupButtonContainer from 'containers/ApplyGroupButton';
import CancelGroupApplicationContainer from 'containers/CancelGroupApplication';
import BnetIcon from 'assets/integrations/bnet.svg';
import DiscordIcon from 'assets/integrations/discord.svg';
import PatreonButton from '../PatreonModal/Button';
import './Group.scss';

const useStyles = makeStyles({
  tooltip: {
    fontSize: 14,
    padding: '5px 10px',
  },

  anchorOriginTopRightRectangle: {
    top: -2,
    right: -32,
    transform: 'initial',
    transformOrigin: 0,
  },

  badge: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    fontSize: 16,
  },
});

const Group = ({
  currentAccount,
  group,
  deleteGroup,
  removeFromGroup,
  applicable,
  deletable,
  viewable,
  editable,
  cancelApplication,
  removable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChar, setIsOpenChar] = useState(false);
  const [integration, setIntegration] = useState({});
  const [selectedChar, setSelectedChar] = useState({});
  const classes = useStyles();
  // TODO: Test this
  const { id } = useParams();
  const matchGroup = useRouteMatch(`/lfg/groups/${id}`);
  const matchAppliedGroups = useRouteMatch('/lfg/applied-groups');

  const handleCopy = (id, key) => {
    setIntegration(() => ({ [id]: { [key]: true } }));
  };

  const toggleDeleteDialog = () => setIsOpen(!isOpen);
  const toggleRemoveCharDialog = () => setIsOpenChar(!isOpenChar);

  const handleSelectedChar = char => setSelectedChar(char);

  const canRemoveFromGroup = (currentAccount, group, char) => {
    if (currentAccount.id === group.account_id) {
      return char.id !== group.character_id;
    }

    return char.account_id === currentAccount.id;
  };

  const characterClasses = groupBy(group.specializations, 'character_class');
  const groupApplicationDate = group.applications.map(application => application.created_at);

  const specializations = flatten(
    Object.entries(characterClasses).map(([name, specs]) => {
      if (name === 'Druid' && specs.length === 4) {
        return { id: name, name, character_class: name };
      } else if (name === 'Demon Hunter' && specs.length === 2) {
        return { id: name, name, character_class: name };
      } else if (specs.length === 3) {
        return {
          id: specs[0].id,
          name: specs[0].character_class,
          character_class: specs[0].character_class,
        };
      } else {
        return specs.map(spec => ({
          id: spec.id,
          name: `${spec.name} ${spec.character_class}`,
          character_class: spec.character_class,
        }));
      }
    })
  );

  return (
    <div
      className="group"
      style={group.featured && !matchGroup ? { borderColor: '#ff8000', borderWidth: '2px' } : {}}
    >
      <div className="header">
        <div className="text">
          <h3>{group.title}</h3>
          <span style={{ display: 'block', marginBottom: 10, color: 'silver', fontSize: 14 }}>
            {matchAppliedGroups ? 'Applied' : 'Created'}{' '}
            {<TimeAgo date={matchAppliedGroups ? groupApplicationDate : group.created_at} />}
          </span>
          <p>{group.description}</p>
        </div>

        <div className="group-actions">
          {cancelApplication && <CancelGroupApplicationContainer group={group} />}

          <div className="actions">
            {editable && (
              <Link to={`/lfg/edit/${group.id}`}>
                <IconButton>
                  <Tooltip title="Edit" classes={{ tooltip: classes.tooltip }}>
                    <EditIcon className="icon" />
                  </Tooltip>
                </IconButton>
              </Link>
            )}

            {deletable && (
              <React.Fragment>
                <IconButton onClick={toggleDeleteDialog}>
                  <Tooltip title="Delete" classes={{ tooltip: classes.tooltip }}>
                    <DeleteIcon className="icon" />
                  </Tooltip>
                </IconButton>

                <Dialog open={isOpen} onClose={toggleDeleteDialog} className="dialog">
                  <DialogTitle className="dialog-title">
                    Are you sure you want to delete this group?
                  </DialogTitle>

                  <DialogContent style={{ padding: 30 }} />

                  <DialogActions className="actions-buttons">
                    <Button onClick={toggleDeleteDialog} className="cancel-button">
                      No
                    </Button>

                    <Button
                      onClick={() => {
                        deleteGroup(group.id).then(() => toggleDeleteDialog());
                      }}
                      className="confirm-button"
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            )}
          </div>

          {group.featured && !currentAccount.patreon && (
            <Tooltip
              title="This user supports ArenaMaster on Patreon. We featured his group to help him find teammates faster. Consider supporting us as well to unlock some interesting perks. Click the button to learn more."
              classes={{ tooltip: classes.tooltip }}
              style={{ cursor: 'pointer' }}
            >
              <div className="featured-container">
                <PatreonButton featured />
              </div>
            </Tooltip>
          )}

          {group.featured && currentAccount.patreon && <PatreonButton featured disabled />}

          <div className="group-buttons-container">
            {viewable && location.pathname === '/lfg/my-groups' && (
              <React.Fragment>
                <Link to={`/lfg/groups/${group.id}`}>
                  <Button className="view-group-btn outlined-primary">View Group Details</Button>
                </Link>

                {group.applications.length > 0 && (
                  <Link to={`/lfg/groups/${group.id}/applications`}>
                    <Button
                      className="view-group-btn outlined-primary"
                      style={{ paddingRight: '47px', width: 'fit-content' }}
                    >
                      <Badge
                        badgeContent={group.applications.length}
                        color="primary"
                        classes={{
                          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
                          badge: classes.badge,
                        }}
                      >
                        {group.applications.length === 1
                          ? 'View Group Application'
                          : 'View Group Applications'}
                      </Badge>
                    </Button>
                  </Link>
                )}
              </React.Fragment>
            )}

            {applicable && <ApplyGroupButtonContainer group={group} />}
          </div>
        </div>
      </div>

      {group.characters.map(char => (
        <a href={char.arenamaster_path} target="_blank" rel="noopener noreferrer" key={char.id}>
          <div className="members">
            <div className="member-info">
              <div className="member-name">
                <Avatar
                  alt="Character\'s image"
                  src={char.avatar_url}
                  style={{ width: '40px', height: '40px' }}
                  className="char-image"
                />

                <h3
                  style={{
                    fontSize: '20px',
                    margin: '0 10px',
                    color: getClassColor(char.character_class),
                  }}
                >
                  {char.name}-{char.realm_name}
                </h3>
              </div>
            </div>

            <div
              className="group-ratings"
              style={
                get(char, 'discord_username', '') || get(char, 'bnet_username')
                  ? {}
                  : { width: '70%', justifyContent: 'flex-end' }
              }
              id={
                location.pathname === '/lfg' ||
                (location.pathname === `/lfg/groups/${group.id}` &&
                  !char.discord_username &&
                  !char.bnet_username)
                  ? 'centered-ratings'
                  : ''
              }
            >
              <div className="member-icons">
                <ul>
                  <li>
                    <img src={getRaceIcon(char.gender, char.race)} alt="Character Race" />
                  </li>

                  <li>
                    <img src={getClassIcon(char.character_class)} alt="Character Class" />
                  </li>

                  <li>
                    <img
                      src={getSpecIcon(char.character_class, char.specialization)}
                      alt="Specialization"
                    />
                  </li>
                </ul>
              </div>

              <div className="ratings">
                <p>
                  Current Rating:<span className="rating">{get(char, 'cr', 0)}</span>
                </p>

                <p>
                  Highest Rating:<span className="rating">{get(char, 'exp', 0)}</span>
                </p>
              </div>
            </div>

            {(get(char, 'discord_username', '') || get(char, 'bnet_username')) && (
              <div className="member-integrations">
                {get(char, 'discord_username', '') && (
                  <Tooltip
                    title={get(integration, [char.id, 'discordCopied'], false) ? 'Copied' : 'Copy'}
                    classes={{ tooltip: classes.tooltip }}
                    className="discord badge"
                  >
                    <CopyToClipboard
                      text={char.discord_username}
                      onCopy={() => handleCopy(char.id, 'discordCopied')}
                    >
                      <div
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        style={
                          char.discord_username && char.bnet_username ? { marginRight: '10px' } : {}
                        }
                      >
                        <DiscordIcon width={30} height={30} />
                        {char.discord_username}
                      </div>
                    </CopyToClipboard>
                  </Tooltip>
                )}

                {get(char, 'bnet_username', '') && (
                  <Tooltip
                    title={get(integration, [char.id, 'bnetCopied'], false) ? 'Copied' : 'Copy'}
                    classes={{ tooltip: classes.tooltip }}
                    className="battle-net badge"
                  >
                    <CopyToClipboard
                      text={char.bnet_username}
                      onCopy={() => handleCopy(char.id, 'bnetCopied')}
                    >
                      <div
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <BnetIcon width={32} height={32} className="bnet-icon" fill="#fff" />
                        {char.bnet_username}
                      </div>
                    </CopyToClipboard>
                  </Tooltip>
                )}
              </div>
            )}

            {removable && canRemoveFromGroup(currentAccount, group, char) && (
              <React.Fragment>
                <Tooltip title="Remove from group?" classes={{ tooltip: classes.tooltip }}>
                  <IconButton
                    className="btn"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleRemoveCharDialog();
                      handleSelectedChar(char);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>

                <Dialog open={isOpenChar} onClose={toggleRemoveCharDialog} className="dialog">
                  <DialogTitle className="dialog-title">
                    Are you sure you want to remove the {selectedChar.name} from the group?
                  </DialogTitle>

                  <DialogContent style={{ padding: 30 }} />

                  <DialogActions className="actions-buttons">
                    <Button onClick={toggleRemoveCharDialog} className="cancel-button">
                      No
                    </Button>

                    <Button
                      onClick={() => {
                        removeFromGroup(group.id, char.id, selectedChar.name).then(() =>
                          toggleRemoveCharDialog()
                        );
                      }}
                      className="confirm-button"
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            )}
          </div>
        </a>
      ))}

      <div className="specializations">
        <div className="wanted-classes">
          <h3>
            This team is looking for following {specializations.length > 1 ? 'classes' : 'class'}:{' '}
          </h3>

          {specializations.map(spec => (
            <div className="selected-classes" key={spec.id}>
              <img src={getClassIcon(spec.character_class)} alt="Character Class" />
              <h4 style={{ marginLeft: '10px' }}>{spec.name}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Group.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  removeFromGroup: PropTypes.func.isRequired,
  applicable: PropTypes.bool,
  deletable: PropTypes.bool,
  viewable: PropTypes.bool,
  editable: PropTypes.bool,
  cancelApplication: PropTypes.bool,
  removable: PropTypes.bool,
};

Group.defaultProps = {
  applicable: true,
  deletable: false,
  viewable: true,
  cancelApplication: false,
  editable: false,
  removable: false,
};

export default Group;
