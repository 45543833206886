/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';
import { MetaTags } from 'react-meta-tags';

import {
  successNotification as successNotificationAction,
  warningNotification as warningNotificationAction,
  errorNotification as errorNotificationAction,
} from '../actions/notifications';
import {
  fetchCharacter as fetchCharacterAction,
  refreshCharacter as refreshCharacterAction,
  refreshCharacterData as refreshCharacterDataAction,
} from '../actions/characters';
import ActionCable from '../components/ActionCable/Cable';
import CharacterProfile from '../components/CharacterProfile/';
import Hero from '../components/Hero';
import LoaderContainer from '../containers/Loader';

const refreshCondition = character =>
  Object.keys(get(character, 'bracket_stats', [])).length < 3 ||
  Object.keys(get(character, 'bracket_stats', [])).filter(
    key => character.bracket_stats[key].rating > character.bracket_stats[key].exp
  ).length ||
  moment().diff(character.updated_at, 'minutes') > 10;

class CharacterProfileContainer extends Component {
  static propTypes = {
    character: PropTypes.object,
    fetchCharacter: PropTypes.func.isRequired,
    refreshCharacter: PropTypes.func.isRequired,
    refreshCharacterData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    successNotification: PropTypes.func.isRequired,
    warningNotification: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    character: null,
  };

  constructor(props) {
    super(props);

    this.onReceived = this.onReceived.bind(this);
  }

  componentDidMount() {
    const { match, location, character, fetchCharacter, refreshCharacterData } = this.props;
    const { region, realm, name } = match.params;

    if (!character) fetchCharacter(region, realm, name, location.search);

    if (character && refreshCondition(character)) {
      const { name, region, realm_slug } = character;
      refreshCharacterData(region, realm_slug, name);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { character } = nextProps;
    const { refreshCharacterData, match, fetchCharacter } = this.props;

    if (!this.props.character && character && refreshCondition(character)) {
      const { name, region, realm_slug } = character;
      refreshCharacterData(region, realm_slug, name);
    }

    if (match.url !== nextProps.match.url && !nextProps.character) {
      const { region, realm, name } = nextProps.match.params;
      fetchCharacter(region, realm, name);
    }
  }

  onReceived(character) {
    const { name, realm_name, realm_slug, status, region } = character;
    const {
      refreshCharacter,
      successNotification,
      warningNotification,
      errorNotification,
      history,
    } = this.props;

    if (status === 200) {
      refreshCharacter(region, realm_slug, character);
      successNotification('Successfully updated character info!');
    }

    if (status === 404) {
      warningNotification(`${name}-${realm_name} does not exist`);
      history.push('/');
    }

    if (status === 500) {
      errorNotification(
        'Unable to find/refresh the character. This is most likely an issue with the World of Warcraft API.'
      );
    }
  }

  render() {
    const { character } = this.props;

    if (!character) {
      return (
        <main>
          <Hero />
          <LoaderContainer styleLoader={{ top: '50%', left: '50%', margin: '-20px 0 0 -20px' }} />
        </main>
      );
    }

    const { name, realm_name, region } = character;

    return (
      <React.Fragment>
        <MetaTags>
          <title>
            {name} - {realm_name} ({region.toUpperCase()}) | ArenaMaster
          </title>
          <meta
            name="description"
            content={`Level ${character.level} ${character.race} ${character.specialization} ${character.character_class}. Highest Rating: ${character.max_exp}, Current Rating: ${character.max_cr}`}
          />
        </MetaTags>

        <Hero />
        <ActionCable
          channel={{
            channel: 'FetchCharactersChannel',
            region,
            realm_name,
            name,
          }}
          onReceived={this.onReceived}
        />

        <CharacterProfile character={character} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { region, realm, name } = match.params;

  let character;

  try {
    character = state.characters[region][realm][name];
  } catch (error) {
    character = null;
  }

  return {
    character,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchCharacter: fetchCharacterAction,
    refreshCharacter: refreshCharacterAction,
    refreshCharacterData: refreshCharacterDataAction,
    successNotification: successNotificationAction,
    warningNotification: warningNotificationAction,
    errorNotification: errorNotificationAction,
  })(CharacterProfileContainer)
);
