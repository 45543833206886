const specIconMapping = {
  71: 'ability_warrior_savageblow.jpg', // Arms Warrior
  72: 'ability_warrior_innerrage.jpg', // Fury Warrior
  73: 'ability_warrior_defensivestance.jpg', // Protection Warrior
  65: 'spell_holy_holybolt.jpg', // Holy Paladin
  66: 'ability_paladin_shieldofthetemplar.jpg', // Protection Paladin
  70: 'spell_holy_auraoflight.jpg ', // Retribution Paladin
  253: 'ability_hunter_bestialdiscipline.jpg', // Beast Mastery Hunter
  254: 'ability_hunter_focusedaim.jpg', // Marksmanship Hunter
  255: 'ability_hunter_camouflage.jpg', // Survival Huner
  259: 'ability_rogue_deadlybrew.jpg', // Assasination Rogue
  260: 'ability_rogue_waylay.jpg', // Outlaw Rogue
  261: 'ability_stealth.jpg', // Subtlety Rogue
  256: 'spell_holy_powerwordshield.jpg', // Discipline Priest
  257: 'spell_holy_guardianspirit.jpg', // Holy Priest
  258: 'spell_shadow_shadowwordpain.jpg', // Shadow Priest
  250: 'spell_deathknight_bloodpresence.jpg', // Blood DK
  251: 'spell_deathknight_frostpresence.jpg', // Frost DK
  252: 'spell_deathknight_unholypresence.jpg', // Unholy DK
  262: 'spell_nature_lightning.jpg', // Elemental Shaman
  263: 'spell_shaman_improvedstormstrike.jpg', // Enhancement Shaman
  264: 'spell_nature_magicimmunity.jpg', // Restoration Shaman
  62: 'spell_holy_magicalsentry.jpg', // Arcane Mage
  63: 'spell_fire_firebolt02.jpg', // Fire Mage
  64: 'spell_frost_frostbolt02.jpg', // Frost Mage
  265: 'spell_shadow_deathcoil.jpg', // Affliction Warlock
  266: 'spell_shadow_metamorphosis.jpg', // Demonology Warlock
  267: 'spell_shadow_rainoffire.jpg', // Destruction Warlock
  268: 'spell_monk_brewmaster_spec.jpg', // Brewmaster Monk
  270: 'spell_monk_mistweaver_spec.jpg', // Mistwaeaver Monk
  269: 'spell_monk_windwalker_spec.jpg', // Windwalker Monk
  102: 'spell_nature_starfall.jpg', // Balance Druid
  103: 'ability_druid_catform.jpg', // Feral Druid
  104: 'ability_racial_bearform.jpg', // Guardian Druid
  105: 'spell_nature_healingtouch.jpg', // Restoration Druid
  577: 'ability_demonhunter_specdps.jpg', // Havoc DH
  581: 'ability_demonhunter_spectank.jpg', // Vengeance DH
  1467: 'classicon_evoker_devastation.jpg', // Devastation Evoker
  1468: 'classicon_evoker_preservation.jpg', // Preservation Evoker
};

export default specIconMapping;
