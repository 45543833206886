import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import Axios from 'app/utils/axios';
import { getRaceIcon, getClassIcon, getClassColor, getRegionIcon } from 'utils/helpers';
import RichAd from '../Ads/RichAd';
import DynamicAd970x250 from '../Ads/DynamicAd970x250';
import StaticAd728x90 from '../Ads/StaticAd768x90';
import FactionIcon from '../FactionIcon';
import TbcTeam from '../TbcTeam';
import '../TBCRow/TBCRow.scss';
import './TbcProfile.scss';

const topAdStyles = { marginTop: 20 };
const bottomAdStyles = { marginBottom: 20 };

const TbcProfile = () => {
  const [member, setMember] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    Axios.get(`/api/v1/tbc_characters/${id}`).then(response => {
      setMember(response.data);
    });
  }, []);

  if (!member) return null;

  return (
    <>
      <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

      <Grid container className="tbc-profile">
        <RichAd />

        <Grid item xs={11} md={10} lg={8} xl={6} className="profile-card" container>
          <div className="member-name">
            <img
              src={getRegionIcon(member.region.toUpperCase())}
              width={35}
              style={{ marginRight: 10 }}
              alt="Region"
            />

            <h2>
              <span style={{ color: getClassColor(member.character_class) }}>
                {member.name} - {member.realm}
              </span>
            </h2>

            <div className="member-icons">
              <FactionIcon faction={member.faction} />

              <div className="specs">
                <Tooltip title={member.race} classes={{ tooltip: 'black-tooltip' }}>
                  <img
                    src={getRaceIcon(member.gender.toLowerCase(), member.race)}
                    alt="Character Race"
                  />
                </Tooltip>

                {member.character_class && (
                  <Tooltip title={member.character_class} classes={{ tooltip: 'black-tooltip' }}>
                    <img
                      src={getClassIcon(member.character_class)}
                      className="class-icon"
                      alt="Character Class"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          <Grid item container className="teams" spacing={2}>
            <TbcTeam bracket="2v2" member={member} />
            <TbcTeam bracket="3v3" member={member} />
            <TbcTeam bracket="5v5" member={member} />
          </Grid>
        </Grid>
      </Grid>

      <DynamicAd970x250 mobileStyle={bottomAdStyles} />
    </>
  );
};

export default TbcProfile;
