import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { PATREON_MODAL } from 'constants/utils';

const PatreonButton = ({ featured = false, disabled = false }) => {
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch({ type: PATREON_MODAL, payload: true });
  };

  return (
    <Button
      disabled={disabled}
      className={featured ? 'featured-group' : 'feature-btn'}
      onClick={handleClickOpen}
    >
      {featured ? <Star className="star " /> : <StarBorderIcon className="star" />}
      {featured ? 'Featured Group' : 'Feature This Group'}
    </Button>
  );
};

export default PatreonButton;
