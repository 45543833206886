import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const RankingsFilterButton = ({ type, currentType, buttonText, handleChange }) => (
  <Button onClick={() => handleChange(type)} className={currentType === type ? 'selected' : ''}>
    {buttonText}
  </Button>
);

RankingsFilterButton.propTypes = {
  type: PropTypes.string.isRequired,
  currentType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RankingsFilterButton;
