import React from 'react';
import { MetaTags } from 'react-meta-tags';
import RichAd from 'app/components/Ads/RichAd';
import CharacterListContainer from '../../../containers/CharacterList';

const CharactersListPage = () => (
  <div>
    {/* <RichAd vertical={false} /> */}

    <MetaTags>
      <title>My Characters | ArenaMaster</title>
    </MetaTags>

    <h1 className="title" style={{ paddingBottom: '5px' }}>
      CHARACTERS LIST
    </h1>

    <CharacterListContainer />
  </div>
);

export default CharactersListPage;
