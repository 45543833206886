import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import Hero from 'components/Hero';
import Axios from 'app/utils/axios';
import ModifiedAlert from 'app/components/Alert';
import RichAd from 'app/components/Ads/RichAd';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import StaticAd728x90 from 'app/components/Ads/StaticAd768x90';
import { openAuthModal } from 'actions/auth';
import TwitchCard from 'app/components/TwitchCard';

const bottomAdStyles = { marginTop: '-20px', marginBottom: 20 };
const topAdStyles = { marginTop: 20 };

const TwitchPage = () => {
  const [streamData, setStreamData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const isSignedIn = useSelector(state => state.auth.currentAccount.isSignedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    Axios.get('/api/v1/twitch_streams').then(response => {
      setStreamData(response.data);
      setIsLoaded(true);
    });
  }, []);

  return (
    <Grid item xl={8} className="twitch-page" style={{ margin: '0 auto', textAlign: 'center' }}>
      <RichAd />

      <MetaTags>
        <title>WoW PvP Twitch Streams | ArenaMaster</title>
        <meta name="description" content="Live Wow PvP Streams for Arena and Rated Battleground" />
      </MetaTags>

      <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

      <Hero />

      <h1 style={{ marginTop: 40 }}>Live PvP Twitch Streams</h1>

      {streamData.length === 0 && isLoaded && (
        <ModifiedAlert
          severity="info"
          styleAlert={{ margin: '10px auto', width: 'fit-content' }}
          text={
            <div>
              There are no live streams at the moment. If you are a streamer,{' '}
              {isSignedIn ? (
                <span className="twitch-link" style={{ color: '#2196f3' }}>
                  <Link to="/dashboard/integrations" alt="Integrations">
                    Connect with Twitch
                  </Link>
                </span>
              ) : (
                <React.Fragment>
                  <span onClick={() => dispatch(openAuthModal('SignUp'))} className="register-link">
                    Sign Up
                  </span>{' '}
                  for free, and Connect with Twitch
                </React.Fragment>
              )}{' '}
              to showcase your stream.
            </div>
          }
        />
      )}

      {streamData.length === 0 && !isLoaded ? (
        <CircularProgress style={{ marginTop: 30 }} />
      ) : (
        <TwitchCard streamData={streamData} />
      )}

      <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
    </Grid>
  );
};

export default TwitchPage;
