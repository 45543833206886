import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import './LegionAchievements.scss';

const LegionAchievements = ({ achievements, achievementMappings }) => {
  if (achievements.length < 1) return null;

  const completedDate = wowId => {
    const achievement = achievementMappings.find(a => a.id === wowId);

    return achievement ? achievement.date : '';
  };

  return (
    <Grid container className="legion-2k">
      <h5 className="text-shadow">Legion Elite (2k)</h5>

      {achievements.map(achievement => (
        <Tooltip
          title={completedDate(achievement.wow_id)}
          placement="top"
          key={achievement.id}
          classes={{ tooltip: 'achievements-tooltip' }}
        >
          <div>
            <img
              key={achievement.id}
              src={achievement.icon_url}
              alt={achievement.name}
              className={achievement.completed ? 'completed' : ''}
            />
            {achievement.completed && <CheckIcon className="icon" />}
            <span>S0{achievement.season}</span>
          </div>
        </Tooltip>
      ))}
    </Grid>
  );
};

LegionAchievements.propTypes = {
  achievements: PropTypes.array.isRequired,
  achievementMappings: PropTypes.array.isRequired,
};

export default LegionAchievements;
