import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

export default function configureStore(initialState) {
  const logger = createLogger({ level: 'info', collapsed: true });
  const middlewares = [logger, thunk];

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (module.hot) {
    module.hot.accept('../reducers/', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}
