/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';

const fixTalentId = id => {
  // if (id === 113724) {
  //   return 464484;
  // }

  return id;
};

const Talent = ({ talent }) => (
  <div className="item" style={{ margin: '0 10px', marginBottom: '0' }}>
    <a
      data-wh-icon-size="large"
      data-wh-rename-link="false"
      data-wh-icon-added="true"
      className=""
      href={`https://wowhead.com/spell=${talent.spell_id}`}
      target="_blank"
    >
      <span className="icon">
        <img src={`/api/v1/spells/${fixTalentId(talent.spell_id)}`} alt="Item Icon" />
        <span className="frame" />
      </span>
    </a>
  </div>
);

Talent.propTypes = {
  talent: PropTypes.object.isRequired,
};

export default Talent;
