import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import Hero from 'components/Hero';

const bottomAdStyles = { marginTop: '-20px', marginBottom: 20 };

const CookiePolicy = () => (
  <React.Fragment>
    <RichAd />

    <MetaTags>
      <title>Cookie Policy | ArenaMaster</title>
    </MetaTags>

    <Hero />

    <DynamicAd970x250 />

    <div className="footer-page">
      <Grid container justify="center">
        <Grid item xs={12} lg={8} xl={6}>
          <h2>Cookie Policy</h2>

          <p>
            A cookie is a small text file that a website saves on your computer or mobile device
            when you visit the site. This minor text file enables a site to remember your actions
            and preferences for a specified period, so you don’t have to re-enter your login or
            settings information each time you visit a new page on our site.
          </p>

          <h3>Security / Authentication</h3>

          <p>
            Some cookies and similar technology functions are necessary to ensure that ArenaMaster
            works properly for visitors and members, such as maintaining the security, safety,
            authentication, and logging in (including remembering permissions and consents you have
            granted), and ensuring the ability to securely complete transactions.
          </p>

          <h3>Performance / Analytics and Research</h3>

          <p>
            Some technologies help provide performance data on how ArenaMaster is functioning in
            order to make improvements to the platform. Data collected on-site and app functionality
            and speed, how ArenaMaster is used, and detecting and gathering reporting on bugs helps
            improve ArenaMaster and the services we offer.
          </p>

          <h3>Preferences / Localization</h3>

          <p>
            Cookies enable your personalized views and settings. Some cookies help us provide
            localized experiences - for example, to set the default region based on the country you
            are.
          </p>

          <h3>Social Networks / Marketing</h3>

          <p>
            Some technologies help you to interact with social networks you are signed into while
            using ArenaMaster, such as connecting with Battle.net, Discord, Twitch and other
            features you employ with the social networks, or that are allowed in the social
            network’s privacy policy. These may be set and controlled by the social networks, and
            your preferences with those social networks.
          </p>

          <h3>Changes to Cookie Policy</h3>

          <p>
            We periodically update this Cookie Policy to account for changed legal and operational
            circumstances, to explain new cookies and tracking technologies, and to describe how
            those changes affect our use of your information. If we are going to use information in
            a manner that is materially different from that stated at the time of collection or make
            any material changes in our privacy practices, we will notify you. We will post those
            changes through a prominent notice on our website.
          </p>
        </Grid>
      </Grid>
    </div>

    <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
  </React.Fragment>
);

export default CookiePolicy;
