import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import { saveBnetConfigured, loadBnetConfigured } from 'utils/localStorage';
import { fetchCharacters as fetchCharactersAction } from 'actions/characters';
import bnet_allow from 'assets/integrations/bnet_allow.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  message: {
    position: 'sticky',
    minHeight: 60,
    alignItems: 'center',
    fontSize: 18,
    color: '#2196f3',
    backgroundColor: '#0c0d0e',
    borderRadius: 0,
  },

  icon: {
    fontSize: '25px',
  },

  root: {
    padding: '10px 24px',
  },

  paperWidthSm: {
    maxWidth: '600px !important',
    padding: 10,
  },

  closeButton: {
    position: 'absolute',
    right: 18,
    top: 8,
  },

  closeIcon: {
    '&:hover': {
      color: '#c43244',
    },
  },
});

const BnetAlert = ({ characters, currentAccount, fetchCharacters }) => {
  const [openAlert, setOpenAlert] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const bnetConfigured = loadBnetConfigured();
  const classes = useStyles();

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (currentAccount.bnet_connected && characters.length === 0) {
      fetchCharacters().then(() => setIsFetched(true));
    }
  }, []);

  if (characters.length > 0) {
    return null;
  }

  return (
    <React.Fragment>
      {currentAccount.bnet_connected && !bnetConfigured && isFetched && (
        <div className="bnet-alert">
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  onClick={() => {
                    setOpenAlert(false);
                    saveBnetConfigured(true);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              severity="info"
              variant="outlined"
              className={(classes.icon, classes.message)}
            >
              Your integration with Battle.net seems to be missing some permissions. Please follow{' '}
              <span onClick={handleOpen}>these steps</span> on how to fix it.
            </Alert>
          </Collapse>

          <Dialog
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={{ paperWidthSm: classes.paperWidthSm }}
          >
            <DialogTitle id="alert-dialog-slide-title" classes={{ root: classes.root }}>
              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                style={{ color: '#fff', fontSize: 18, marginBottom: 0 }}
                className="description"
              >
                For the integration with Battle.net to be successful, you need to enable{' '}
                <span>Your Blizzard Account ID and Battle Tag,</span> and{' '}
                <span>Your World of Warcraft profile</span> when you're first connecting.
                <img src={bnet_allow} className="bnet-allow-img" />
                If you didn't check both boxes on your first integration, we can't import your
                characters and you'll need to remove the ArenaMaster connection in your Battle.net
                settings. You can do so by clicking on{' '}
                <a
                  href="https://account.blizzard.com/connections#authorized-applications"
                  target="_blank"
                >
                  <span>this link.</span>
                </a>{' '}
                After you do that visit the{' '}
                <Link to="/dashboard/integrations" onClick={handleClose}>
                  <span>Integrations Page</span>
                </Link>{' '}
                and click the Battle.net button to connect again.
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
};

BnetAlert.propTypes = {
  characters: PropTypes.array,
  currentAccount: PropTypes.object,
};

BnetAlert.defaultProps = {
  characters: [],
  currentAccount: {},
};

const mapStateToProps = ({ characters, auth }) => {
  const { connectedCharacters } = characters;
  const { currentAccount } = auth;

  return { characters: connectedCharacters, currentAccount };
};

export default connect(mapStateToProps, {
  fetchCharacters: fetchCharactersAction,
})(BnetAlert);
