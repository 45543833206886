/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { getClassColor, getClassIcon, getSpecIcon, getRaceIcon } from '../../utils/helpers';
import FactionIcon from '../FactionIcon';
import './CharacterSearchResult.scss';

class CharacterSearchResult extends Component {
  static propTypes = {
    character: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
  };

  render() {
    const { character, handleClick, isHighlighted } = this.props;

    if (character.type === 'new') {
      return (
        <div className={`character ${isHighlighted ? 'highlighted' : ''}`} onClick={handleClick}>
          <span className="character-name" style={{ color: '#fff' }}>
            {character.name}
          </span>

          <span className="character-details">
            <ScheduleIcon style={{ position: 'relative', top: '5px' }} /> Click to import
          </span>

          <span className="character-server" style={{ color: '#fff' }}>
            {character.realm_name}
          </span>
        </div>
      );
    }

    const {
      name, character_class, gender, race, specialization, faction, realm_name,
    } = character;

    return (
      <div className={`character ${isHighlighted ? 'highlighted' : ''}`} onClick={handleClick}>
        <span className="character-name" style={{ color: getClassColor(character_class) }}>
          {name}
        </span>

        <span className="character-details">
          <img src={getRaceIcon(gender, race)} alt="Character Race" />
          <img src={getClassIcon(character_class)} alt="Character Class" />
          <img src={getSpecIcon(character_class, specialization)} alt="Character Class" />
        </span>

        <span className="character-server" style={{ color: '#fff' }}>
          {realm_name}

          <FactionIcon faction={faction} />
        </span>
      </div>
    );
  }
}

export default CharacterSearchResult;
