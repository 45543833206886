/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import './Item.scss';

const QUALITY_MAPPING = {
  Heirloom: 7,
  Artifact: 6,
  Legendary: 5,
  Epic: 4,
  Rare: 3,
  Uncommon: 2,
  Common: 1,
  Poor: 0,
};

const Item = ({ item, characterClassId, slot }) => {
  const socket = get(item, 'sockets', []).map(s => get(s, 'item.id'));
  const enchant = get(item, 'enchantments', []).map(e => e.enchantment_id);
  const azeritePowers = get(item, 'azerite_details.selected_powers', []).map(i => i.id);
  const bonus = [...get(item, 'bonus_list', []), ...azeritePowers].join(':');
  const enchantQuery = enchant ? `&ench=${enchant}` : '';
  const gemQuery = socket ? `&gems=${socket}` : '';
  const ilvl = `&ilvl=${item.level.value}`;
  const rel = `bonus=${bonus}&azerite-powers=${characterClassId}:${azeritePowers.join(
    ':'
  )}${enchantQuery}${gemQuery}${ilvl}`;

  return (
    <div className="item">
      <a
        data-wh-icon-size="medium"
        data-wh-rename-link="false"
        data-wh-icon-added="true"
        className=""
        href={`https://wowhead.com/item=${item.item.id}`}
        rel={rel}
        target="_blank"
      >
        <span className="icon">
          <img src={`/api/v1/items/${item.item.id}`} alt="Item Icon" />
          <span className="frame" />
        </span>

        {slot !== 'azerite' && (
          <span className={`item-level quality-${QUALITY_MAPPING[item.quality.name]}`}>
            {item.level.value}
          </span>
        )}

        {slot === 'azerite' && <span className="item-level quality-4">Azerite Items</span>}
      </a>
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  characterClassId: PropTypes.number.isRequired,
  slot: PropTypes.string.isRequired,
};

export default Item;
