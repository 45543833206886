import React from 'react';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import StaticAd728x90 from 'app/components/Ads/StaticAd768x90';
import './LfgHeader.scss';

const topAdStyles = { marginTop: '20px', marginBottom: '-20px' };

const LfgHeader = ({ currentAccount, characters }) => {
  const matchNew = useRouteMatch('/lfg/new');
  const matchDetails = useRouteMatch('/lfg/groups');

  return (
    <React.Fragment>
      <div className="lfg-header">
        <ul>
          <NavLink exact to="/lfg" activeClassName="active">
            <li>Search for groups</li>
          </NavLink>

          <NavLink to="/lfg/my-groups" activeClassName="active">
            {matchDetails ? <li>Back to My Groups</li> : <li>My Groups</li>}
          </NavLink>

          <NavLink exact to="/lfg/applied-groups" activeClassName="active">
            <li>Applied Groups</li>
          </NavLink>
        </ul>

        {characters.length > 0 && (
          <Link to="/lfg/new">
            <Button
              className="create-group-button primary-btn"
              style={matchNew && currentAccount.bnet_connected ? { display: 'none' } : {}}
            >
              Create New Group
            </Button>
          </Link>
        )}
      </div>

      <StaticAd728x90 desktopStyle={topAdStyles} />
    </React.Fragment>
  );
};

LfgHeader.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  characters: PropTypes.array.isRequired,
};

export default LfgHeader;
