export const SEARCH_GROUPS = 'SEARCH_GROUPS';
export const FETCH_MY_GROUPS = 'FETCH_MY_GROUPS';
export const FETCH_APPLIED_GROUPS = 'FETCH_APPLIED_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const APPLY_TO_GROUP = 'APPLY_TO_GROUP';
export const SET_LFG_FILTERS = 'SET_LFG_FILTERS';
export const FETCH_GROUP_APPLICATIONS = 'FETCH_GROUP_APPLICATIONS';
export const CANCEL_GROUP_APPLICATION = 'CANCEL_GROUP_APPLICATION';
export const REMOVE_APPLIED_GROUP = 'REMOVE_APPLIED_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const FETCH_GROUP = 'FETCH_GROUP';
export const ACCEPT_GROUP_APPLICATION = 'ACCEPT_GROUP_APPLICATION';
export const DECLINE_GROUP_APPLICATION = 'DECLINE_GROUP_APPLICATION';
