/* eslint-disable no-undef, consistent-return */

export const loadLfgCollapsed = () => {
  try {
    const collapsed = localStorage.getItem('lfgCollapsed');

    if (collapsed === null) {
      return undefined;
    }

    return JSON.parse(collapsed);
  } catch (err) {
    return true;
  }
};

export const saveLfgCollapsed = lfgCollapsed => {
  try {
    localStorage.setItem('lfgCollapsed', lfgCollapsed);
  } catch (err) {
    // Local storage not enabled
  }
};

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export const loadBnetAccepted = () => {
  try {
    const bnetAccepted = localStorage.getItem('bnetAccepted');

    if (bnetAccepted === null || bnetAccepted === undefined) {
      return false;
    }

    return JSON.parse(bnetAccepted);
  } catch (err) {
    return false;
  }
};

export const saveBnetAccepted = bnetAccepted => {
  try {
    localStorage.setItem('bnetAccepted', bnetAccepted);
  } catch (err) {
    // Local storage not enabled
  }
};

export const loadBnetConfigured = () => {
  try {
    const bnetConfigured = localStorage.getItem('bnetConfigured');

    if (bnetConfigured === null || bnetConfigured === undefined) {
      return false;
    }

    return JSON.parse(bnetConfigured);
  } catch (err) {
    return false;
  }
};

export const saveBnetConfigured = bnetConfigured => {
  try {
    localStorage.setItem('bnetConfigured', bnetConfigured);
  } catch (err) {
    // Local storage not enabled
  }
};
