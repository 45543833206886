import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import RankingsPage from '../pages/Rankings/Rankings';
import Axios from '../utils/axios';
import { isServer, getFactionId } from '../utils/helpers';

const invertDirection = {
  asc: 'desc',
  desc: 'asc',
};

class RankingsContainer extends Component {
  static propTypes = {
    region: PropTypes.string.isRequired,
  };

  state = {
    currentBracket: '3v3',
    currentFaction: 'Both',
    perPage: 100,
    currentPage: 0,
    totalCount: 10000,
    characters: [],
    characterClasses: [],
    specializationIds: [],
    currentCharacterClassId: undefined,
    columnToSort: '',
    sortDirection: 'desc',
  };

  componentDidMount() {
    this.getCharacterData();
    this.getCharacterClasses();
    window.addEventListener('scroll', this.handleScroll); // eslint-disable-line no-undef
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.region !== nextProps.region) {
      this.getCharacterData(nextProps.region);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPage, characters } = this.state;

    if (currentPage !== prevState.currentPage || this.props.region !== prevProps.region) {
      this.goToTop();
    }

    if (characters.length !== prevState.characters.length) {
      setTimeout(this.goToTop, 200);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // eslint-disable-line no-undef
  }

  getCharacterData = (regionOverride = null) => {
    const region = regionOverride === null ? this.props.region : regionOverride;

    const {
      currentBracket,
      perPage,
      currentPage,
      currentFaction,
      currentCharacterClassId,
      specializationIds,
    } = this.state;

    const query = {
      bracket: currentBracket,
      per_page: perPage,
      page: currentPage + 1,
      character_class_id: currentCharacterClassId === null ? undefined : currentCharacterClassId,
      specialization_ids: specializationIds,
      faction_id: getFactionId(currentFaction),
    };

    const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
    const url = `/api/v1/${region}/characters/rankings?${queryString}`;

    Axios.get(url).then(response => {
      this.setState({
        characters: this.formatData(response.data, currentPage, perPage),
        totalCount: +response.headers['pagination-count'],
      });
    });
  };

  getCharacterClasses = () => {
    const url = '/api/v1/character_classes';

    Axios.get(url).then(response => {
      this.setState({
        characterClasses: response.data,
      });
    });
  };

  getSpecializationIds = characterClassId =>
    this.state.characterClasses
      .find(characterClass => characterClass.id === characterClassId)
      .specializations.map(spec => spec.id);

  handleBracketChange = bracket =>
    this.setState({ currentBracket: bracket }, () => {
      this.getCharacterData();
    });

  handleFactionChange = faction =>
    this.setState({ currentFaction: faction }, () => {
      this.getCharacterData();
    });

  handlePageChange = page =>
    this.setState({ currentPage: page }, () => {
      this.getCharacterData();
    });

  handlePerPageChange = event =>
    this.setState({ perPage: +event.target.value }, () => {
      this.getCharacterData();
    });

  handleCharacterClassChange = characterClassId => {
    this.setState(
      {
        currentCharacterClassId: characterClassId,
        specializationIds:
          characterClassId === null ? [] : this.getSpecializationIds(characterClassId),
      },
      () => {
        this.getCharacterData();
      }
    );
  };

  handleSpecializationChange = specializationId => {
    if (this.state.specializationIds.includes(specializationId)) {
      const specializationIds = this.state.specializationIds.filter(id => id !== specializationId);

      if (specializationIds.length === 0) {
        this.handleCharacterClassChange(null);
      } else {
        this.setState({ specializationIds }, () => this.getCharacterData());
      }
    } else {
      this.setState(
        prevState => ({
          specializationIds: [...prevState.specializationIds, specializationId],
        }),
        () => this.getCharacterData()
      );
    }
  };

  toggleClass = characterClassId => {
    if (this.state.currentCharacterClassId === characterClassId) {
      this.setState({
        currentCharacterClassId: undefined,
        specializationIds: [],
      });
    }
  };

  goToTop = () => {
    if (!isServer()) window.scrollTo(0, 0); // eslint-disable-line no-undef
  };

  handleScroll = () => {
    if (isServer()) return;

    const cells = Array.from(document.getElementsByClassName('table-header-cell')); // eslint-disable-line no-undef
    // eslint-disable-next-line no-undef
    if (window.scrollY >= 131 && window.innerWidth > 1024) {
      cells.forEach(cell => cell.classList.add('sticky-header'));
    } else {
      cells.forEach(cell => cell.classList.remove('sticky-header'));
    }
  };

  handleSort = columnName => {
    const { columnToSort, sortDirection } = this.state;
    this.setState({
      columnToSort: columnName,
      sortDirection: columnToSort === columnName ? invertDirection[sortDirection] : 'asc',
    });
  };

  formatData = (characters, page, perPage) =>
    characters.map((char, index) => ({
      ...char,
      rank: page * perPage + index + 1,
      win_percentage: char.played === 0 ? 0 : Math.round((char.won / char.played) * 100),
    }));

  render() {
    return (
      <RankingsPage
        {...this.state}
        handleBracketChange={this.handleBracketChange}
        handleFactionChange={this.handleFactionChange}
        handlePageChange={this.handlePageChange}
        handlePerPageChange={this.handlePerPageChange}
        handleCharacterClassChange={this.handleCharacterClassChange}
        handleSpecializationChange={this.handleSpecializationChange}
        specializationIds={this.state.specializationIds}
        toggleClass={this.toggleClass}
        handleSort={this.handleSort}
      />
    );
  }
}

const mapStateToProps = ({ utils: { region } }) => ({
  region,
});

export default connect(mapStateToProps)(RankingsContainer);
