import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Hero from 'app/components/Hero';

const NotFoundPage = () => (
  <div>
    <Hero />

    <MetaTags>
      <title>Not Found</title>
    </MetaTags>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 200,
      }}
    >
      <h2>Could not find the page you were looking for.</h2>

      <Button
        variant="contained"
        style={{ textTransform: 'none', fontSize: '16px', marginTop: 30, marginBottom: 30 }}
        className="primary-btn"
        to="/"
        component={Link}
      >
        Back to Home
      </Button>
    </div>
  </div>
);

export default NotFoundPage;
