/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { getRatingColor, getWinRateColor } from '../../utils/helpers';
import './BracketStat.scss';

const BracketStat = ({
  title,
  bracket: { rating, exp, weekly_won, weekly_lost, season_won, season_lost, rank, order },
}) => {
  const weeklyWonRate = ((weekly_won / (weekly_won + weekly_lost)) * 100).toFixed();
  const seasonWonRate = ((season_won / (season_won + season_lost)) * 100).toFixed();

  return (
    <div className="bracket">
      <div className="header">
        <h3>
          {title} {!['2v2', '3v3', 'rbg', 'RBG'].includes(title) && 'Shuffle'}
        </h3>

        {rank && (
          <strong style={{ color: '#fff', fontSize: 18 }}>
            Rank: <span style={{ color: getRatingColor(rating) }}>{rank}</span>
          </strong>
        )}
      </div>

      <div className="content">
        <div className="column">
          <span className="label">Current:</span>
          <span className="rating" style={{ color: getRatingColor(rating) }}>
            {rating ? rating : 0}
          </span>

          <div className="divider" />

          <span style={{ color: getWinRateColor(weeklyWonRate) }} className="win-rate">
            {weekly_won > 0 ? weeklyWonRate : 0}% weekly win rate
          </span>

          <div className="divider" />

          <span className="wins">{weekly_won} weekly won</span>
          <span className="losses">{weekly_lost} weekly lost</span>
        </div>

        <div className="column">
          <span className="label">Highest (All-time):</span>
          <span className="rating" style={{ color: getRatingColor(exp) }}>
            {exp ? exp : 0}
          </span>

          <div className="divider" />

          <span style={{ color: getWinRateColor(seasonWonRate) }} className="win-rate">
            {season_won > 0 ? seasonWonRate : 0}% season win rate
          </span>

          <div className="divider" />

          <span className="wins">{season_won} season won</span>
          <span className="losses">{season_lost} season lost</span>
        </div>
      </div>
    </div>
  );
};

BracketStat.propTypes = {
  title: PropTypes.string.isRequired,
  bracket: PropTypes.object.isRequired,
};

export default BracketStat;
