import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import './PatreonCTA.scss';

const PatreonCTA = ({ patreonStyle }) => {
  const isSignedIn = useSelector(state => state.auth.currentAccount.isSignedIn);
  const isPatron = useSelector(state => get(state, 'auth.currentAccount.patreon', false));

  return (
    <React.Fragment>
      {((isSignedIn && !isPatron) || !isSignedIn) && (
        <div className="patreon-cta" style={patreonStyle}>
          <a href="https://www.patreon.com/arenamaster" target="_blank" rel="noopener">
            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/patreon-btn.png"
              alt="Support on Patreon"
              width="200"
            />
          </a>

          <span className="message">
            Support us on Patreon and get the ability to make your{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>groups stand out</span> in LFG for
            your main character. You'll also get access to a{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>
              subscriber-only Discord Channel
            </span>{' '}
            where you can be a part of brainstorming future features for the app and enjoy an{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>ad-free experience.</span>
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

PatreonCTA.propTypes = {
  patreonStyle: PropTypes.object,
};

PatreonCTA.defaultProps = {
  patreonStyle: {},
};

export default PatreonCTA;
