import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import './AccountSettingsForm.scss';

const schema = yup.object().shape({
  username: yup.string(),
  email: yup
    .string()
    .email('is not a valid email')
    .required('is required'),
  password: yup.string().min(6, 'must contain at least 6 characters'),
  password_confirmation: yup
    .string()
    .min(6, 'must contain at least 6 characters')
    .oneOf([yup.ref('password')], "Passwords don't match"),
  current_password: yup
    .string()
    .min(6, 'must contain at least 6 characters')
    .required('is required'),
});

class AccountSettingsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      changePassword: false,
    };

    this.toggleChangePassword = this.toggleChangePassword.bind(this);
  }

  toggleChangePassword() {
    this.setState(prevState => ({ changePassword: !prevState.changePassword }));
  }

  render() {
    const { handleSubmit, currentAccount } = this.props;
    const { changePassword } = this.state;

    return (
      <div className="user-settings-container">
        <Formik
          enableReinitialize
          initialValues={{
            username: currentAccount.username || '',
            email: currentAccount.email,
            password: '',
            password_confirmation: '',
            current_password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-control">
                <label htmlFor="username">Username</label>
                <ErrorMessage name="username" component="span" className="error" />
                <Field name="username" type="text" label="username" autoFocus />
              </div>

              <div className="form-control">
                <label htmlFor="email">Email</label>

                <ErrorMessage name="email" component="span" className="error" />
                <Field name="email" type="email" label="email" required />
              </div>

              <div className="form-control">
                <label htmlFor="current-password">
                  Current Password (needed to save your changes)
                </label>

                <ErrorMessage name="current_password" component="span" className="error" />
                <Field name="current_password" type="password" label="current_password" />
              </div>

              {changePassword && (
                <React.Fragment>
                  <div className="form-control">
                    <label htmlFor="password">New Password</label>

                    <ErrorMessage name="password" component="span" className="error" />
                    <Field name="password" type="password" label="password" />
                  </div>

                  <div className="form-control">
                    <label htmlFor="password_confirmation">Confirm New Password</label>

                    <ErrorMessage name="password_confirmation" component="span" className="error" />
                    <Field
                      name="password_confirmation"
                      type="password"
                      label="password_confirmation"
                    />
                  </div>
                </React.Fragment>
              )}

              <span onClick={this.toggleChangePassword} className="change-password">
                {changePassword ? 'Hide change password' : 'Change password'}
              </span>

              <Button
                variant="contained"
                id="save-button"
                className="primary-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress style={{ color: '#fff' }} /> : 'Save'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default AccountSettingsForm;
