import React from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';
import './Slider.scss';

const Slider = ({ value, onChange, minValue, maxValue, step, onChangeComplete }) => (
  <InputRange
    step={step}
    maxValue={maxValue}
    minValue={minValue}
    value={value}
    onChange={onChange}
    onChangeComplete={onChangeComplete}
  />
);

Slider.propTypes = {
  step: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChangeComplete: PropTypes.func,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
  step: 10,
  minValue: 0,
  maxValue: 4000,
  onChangeComplete: () => {},
};

export default Slider;
