/* eslint-disable camelcase, react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';

import PatreonButton from 'app/components/PatreonModal/Button';
import CharacterDropdown from '../CharacterDropdown';
import Slider from '../Slider';
import 'react-input-range/lib/css/index.css';
import ModifiedAlert from '../Alert';

const useStyles = makeStyles({
  root: {
    borderColor: '#ff8000',
  },

  icon: {
    color: '#ff8000 !important',
  },

  message: {
    color: '#ff8000',
  },
});

const StepOne = ({
  fields: { bracket, character_id, title, description, min_cr, min_exp, max_cr, max_exp },
  characters,
  handleSelectCharacter,
  handleChangeBracket,
  handleChangeTitle,
  handleChangeDescription,
  handleChangeCr,
  handleChangeExp,
  goToStep,
  patreon,
}) => {
  const selectedChar = characters.find(char => char.id === character_id);
  const classes = useStyles();

  return (
    <Grid container className="new-group-container" justify="space-between">
      {!patreon ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <PatreonButton />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {get(selectedChar, 'main', false) ? (
            <ModifiedAlert
              text="This group will be featured."
              severity="success"
              classes={{ root: classes.root, icon: classes.icon, message: classes.message }}
              styleAlert={{ marginBottom: 20 }}
            />
          ) : (
            <ModifiedAlert
              text="The character must be the main for the group to be featured."
              severity="info"
              styleAlert={{ marginBottom: 20 }}
            />
          )}
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <div className="new-group-content">
          <div className="char-select">
            <h3 style={{ marginBottom: 20 }}>
              {characters.length > 1 ? 'CHOOSE CHARACTER' : 'YOUR CHARACTER'}
            </h3>

            {characters.length > 0 && (
              <CharacterDropdown
                characters={characters}
                bracket={bracket}
                handleSelectCharacter={handleSelectCharacter}
                selectedCharacterId={character_id}
              />
            )}
          </div>

          <h3>CHOOSE BRACKET</h3>

          <div id="select-buttons">
            <Button
              className={`${bracket === '2v2' ? 'active' : ''}`}
              onClick={() => handleChangeBracket('2v2')}
            >
              2v2
            </Button>

            <Button
              className={`${bracket === '3v3' ? 'active' : ''}`}
              onClick={() => handleChangeBracket('3v3')}
            >
              3v3
            </Button>

            <Tooltip title="Coming soon" placement="top">
              <Button className={`${bracket === 'rbg' ? 'active coming-soon' : 'coming-soon'}`}>
                RBG
              </Button>
            </Tooltip>
          </div>

          <div className="rating-slider">
            <h3 style={{ marginLeft: '-10px' }}>SELECT CURRENT RATING RANGE</h3>
            <Slider step={50} value={{ min: min_cr, max: max_cr }} onChange={handleChangeCr} />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={5}>
        <div className="new-group-content" style={{ marginBottom: '50px' }}>
          <div className="form-control">
            <label htmlFor="groupname" style={{ fontWeight: '600', marginBottom: 10 }}>
              GROUP NAME
            </label>

            <input
              type="text"
              placeholder="Group name"
              margin="normal"
              className="mt-0 mb-4"
              onChange={handleChangeTitle}
              value={title}
              autoFocus
              required
            />
          </div>

          <div className="group-description">
            <h3>WRITE SOME DETAILS ABOUT YOUR GROUP</h3>
            <textarea
              rows="6"
              placeholder={`I’m looking for long term partners to push ${bracket}, chill, learn and improve...`}
              onChange={handleChangeDescription}
              value={description}
              required
            />
          </div>

          <div className="rating-slider highest-rating">
            <h3 style={{ marginLeft: '-10px' }}>SELECT HIGHEST RATING RANGE</h3>
            <Slider step={50} value={{ min: min_exp, max: max_exp }} onChange={handleChangeExp} />
          </div>
        </div>
      </Grid>

      <Button className="update-btn primary-btn" onClick={() => goToStep(2)}>
        Continue
      </Button>
    </Grid>
  );
};

StepOne.propTypes = {
  characters: PropTypes.array.isRequired,
  handleChangeBracket: PropTypes.func.isRequired,
  handleChangeTitle: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleChangeCr: PropTypes.func.isRequired,
  handleChangeExp: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  patreon: PropTypes.bool.isRequired,
  handleSelectCharacter: PropTypes.func,
};

StepOne.defaultProps = {
  handleSelectCharacter: null,
};

export default StepOne;
