import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup'; // for everything

import Hero from 'components/Hero';
import { errorNotification } from 'actions/notifications';
import { handleResetPassword } from 'actions/auth';

const defaultRequired = 'is required.';

const schema = yup.object({
  password: yup
    .string()
    .required(defaultRequired)
    .min(6, 'Must be at least 6 characters'),
  password_confirmation: yup
    .string()
    .required(defaultRequired)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ResetPasswordForm = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSubmit = (values, actions) => {
    const { reset_password_token } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (!reset_password_token) {
      dispatch(errorNotification('You are not authorized to perform that action'));
      // history.push('/');
      return;
    }

    handleResetPassword(
      { ...values, reset_password_token },
      actions
    )(dispatch).then(() => history.push('/dashboard'));
  };

  return (
    <React.Fragment>
      <Hero />

      <Grid container className="contact-us-container">
        <Grid item xs={12} sm={8} md={6} lg={5} xl={4} className="contact-us-page">
          <h1>Reset Password</h1>

          <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({ isSubmitting }) => (
              <Form id="contact-us-form">
                <div className="form-control">
                  <label>Password</label>

                  <ErrorMessage name="password" component="span" className="error" />
                  <Field
                    name="password"
                    label="password"
                    placeholder="Password"
                    type="password"
                    autoFocus
                  />
                </div>

                <div className="form-control">
                  <label>Password Confirmation</label>

                  <ErrorMessage name="password" component="span" className="error" />
                  <Field
                    name="password_confirmation"
                    label="password"
                    placeholder="Password Confirmation"
                    type="password"
                  />
                </div>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  id="save-button"
                  className="primary-btn"
                >
                  {isSubmitting ? (
                    <CircularProgress style={{ color: '#fff' }} />
                  ) : (
                    'Save New Password'
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
