import Axios from '../utils/axios';
import { API_URL } from '../constants/auth';
import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  FETCH_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
} from '../constants/notifications';

export const enqueueSnackbar = notification => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const successNotification = message => (dispatch) => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'success' } }));
};

export const errorNotification = message => (dispatch) => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'error' } }));
};

export const warningNotification = message => (dispatch) => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'warning' } }));
};

export const infoNotification = message => (dispatch) => {
  dispatch(enqueueSnackbar({ message, options: { variant: 'info' } }));
};

export const NotificationManager = {
  success: successNotification,
  error: errorNotification,
  warning: warningNotification,
  info: infoNotification,
};

export const fetchNotifications = () => (dispatch) => {
  Axios.get(`${API_URL}/notifications`)
    .then((response) => {
      dispatch({ type: FETCH_NOTIFICATIONS, payload: response.data });
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your notifications'));
    });
};

export const addNotification = notification => (dispatch) => {
  dispatch({ type: ADD_NOTIFICATION, payload: notification });
};

export const markAsRead = id => (dispatch) => {
  Axios.post(`${API_URL}/notifications/${id}/mark_as_read`)
    .then(() => {
      dispatch({ type: REMOVE_NOTIFICATION, payload: id });
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your notifications'));
    });
};
