import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { refreshCharacterData } from '../actions/characters';

const styles = {
  refreshButton: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 16,
  },

  tooltip: {
    fontSize: 14,
  },
};

const RefreshCharacterButtonContainer = ({
  classes,
  handleClick,
  match: {
    params: { region, realm, name },
  },
}) => (
  <Tooltip title="Click to refresh" classes={{ tooltip: classes.tooltip }}>
    <IconButton
      classes={{ root: classes.refreshButton }}
      onClick={() => handleClick(region, realm, name, true)}
    >
      <Icon>watch_later</Icon>
    </IconButton>
  </Tooltip>
);

RefreshCharacterButtonContainer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  withStyles(styles)(
    connect(undefined, {
      handleClick: refreshCharacterData,
    })(RefreshCharacterButtonContainer)
  )
);
