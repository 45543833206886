export const CLASS_COLORS = {
  'Death Knight': '#C41F3B',
  'Demon Hunter': '#A330C9',
  Druid: '#FF7D0A',
  Hunter: '#ABD473',
  Mage: '#69CCF0',
  Monk: '#00FF96',
  Paladin: '#F58CBA',
  Priest: '#FFFFFF',
  Rogue: '#f8e71c',
  Shaman: '#0070DE',
  Warlock: '#9482C9',
  Warrior: '#C79C6E',
  Evoker: '#33937F',
};

export const FACTION_COLORS = {
  Horde: '#c72429',
  Alliance: '#40A2C7',
};

export const RATING_COLORS = {
  2700: '#ff8000',
  2400: '#a335ee',
  2100: '#0070dd',
  1750: '#27ae60',
  0: '#fff',
};

export const PLAYER_RATING_COLORS = {
  15: '#ff8000',
  10: '#a335ee',
  '7.5': '#0070dd',
  '5.5': '#27ae60',
  '2.5': '#fff',
  0: '#c0c0c0',
};
