import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import TwitchIcon from 'assets/integrations/twitch.svg';
import DiscordIcon from 'assets/integrations/discord.svg';
import BnetIcon from 'assets/integrations/bnet.svg';
import BnetDialog from '../BnetDialog';
import { loadBnetAccepted } from 'utils/localStorage';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import './AccountIntegrations.scss';

const AccountIntegrations = ({ currentAccount, region, updateDiscordRoles }) => {
  const [open, setOpen] = useState(false);
  const bnetStyle = currentAccount.bnet_connected ? { border: '2px solid #01adff' } : {};
  const discordStyle = currentAccount.discord_connected ? { border: '2px solid #7289da' } : {};
  const twitchStyle = currentAccount.twitch_connected ? { border: '2px solid #6441a5' } : {};
  const accepted = loadBnetAccepted();

  const handleDiscordClick = () => {
    updateDiscordRoles(currentAccount);
  };

  const handleOpen = () => !currentAccount.bnet_connected && !accepted && setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <div className="integrations">
      <div className="integration" style={bnetStyle}>
        <div className="text">
          <h3>Manage your characters and get access to LFG</h3>

          <p className="description">
            By connecting your ArenaMaster account with your Battle.net you'll be able to manage all
            of your max level characters and get access to the LFG system to help you find arena
            partners!
            <br />
            <br />
            You'll also get access to our roles system when you connect with Discord!
          </p>
        </div>

        <div className="accept">
          <a
            href={
              accepted || (!accepted && currentAccount.bnet_connected)
                ? `/accounts/auth/bnet?region=${region}`
                : '#'
            }
          >
            <Button
              variant="contained"
              className={currentAccount.bnet_connected ? 'bnet-btn no-hover' : 'bnet-btn'}
              onClick={handleOpen}
            >
              <div className="integration-button">
                <BnetIcon width={32} height={32} fill="#fff" />
              </div>

              {currentAccount.bnet_connected ? 'Refresh character list' : 'Connect with Battle.net'}
            </Button>
          </a>

          {(accepted || currentAccount.bnet_connected) && (
            <span onClick={() => setOpen(true)} className="remind-me">
              Remind me what should I do?
            </span>
          )}
        </div>

        <BnetDialog
          open={open}
          handleClose={handleClose}
          currentAccount={currentAccount}
          accepted={accepted}
          region={region}
        />
      </div>

      <div className="integration" style={discordStyle}>
        <div className="text">
          <h3>Join the Discord Community</h3>

          <p className="description">
            By connecting your ArenaMaster account with Discord you'll get access to a member only
            Discord Server where you can discuss strategies, comps and everything else with the WoW
            PvP community!
          </p>

          <br />

          <p className="description">
            If you connect with Battle.net first, we'll assign you appropriate roles based on your
            played classes and experience! You can always update your roles after you get new
            achievements.
          </p>
        </div>

        {!currentAccount.discord_connected ? (
          <a href="/accounts/auth/discord" className="link">
            <Button
              variant="contained"
              className={currentAccount.discord_connected ? 'discord-btn no-hover' : 'discord-btn'}
            >
              <div className="integration-button">
                <DiscordIcon width={30} height={30} />
              </div>
              Connect with Discord
            </Button>
          </a>
        ) : (
          <div className="update-discord">
            <Button
              variant="contained"
              style={{ backgroundColor: '#7289da' }}
              onClick={handleDiscordClick}
            >
              <div className="integration-button">
                <DiscordIcon width={30} height={30} />
              </div>
              Update Discord Roles
            </Button>
          </div>
        )}
      </div>

      <div className="integration" style={twitchStyle}>
        <div className="text">
          <h3>Twitch Stream Promo</h3>

          <p className="description">
            By connecting your ArenaMaster account with Twitch we'll feature your stream on all of
            your character's profiles!
          </p>
        </div>

        <a href="/accounts/auth/twitch" className="link">
          <Button
            variant="contained"
            className={currentAccount.twitch_connected ? 'twitch-btn no-hover' : 'twitch-btn'}
          >
            <div className="integration-button">
              <TwitchIcon width={30} height={30} />
            </div>
            {currentAccount.twitch_connected ? 'Connected' : 'Connect with Twitch'}
          </Button>
        </a>
      </div>

      {/* <DynamicAd970x250 /> */}
    </div>
  );
};

AccountIntegrations.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  updateDiscordRoles: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
};

export default AccountIntegrations;
