import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Item from '../Item/';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { groupItems } from '../../utils/helpers';

const useStyles = makeStyles({
  paper: {
    maxWidth: '837px !important',
  },

  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
});

// eslint-disable-next-line
export const List = ({ items, characterClassId }) => (
  <div className="item-group">
    {Object.keys(items).map(slot => (
      <Item key={slot} slot={slot} item={items[slot]} characterClassId={characterClassId} />
    ))}
  </div>
);

const CharacterItems = ({ character }) => {
  const [isOpen, setIsOpen] = useState(false);

  const items = Object.values(character.items);
  const groupedItems = groupItems(items);
  const characterClassId = character.character_class_id;
  const classes = useStyles();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  if (items.length === 0) return null;

  return (
    <Grid container className="items bracket-stats-container">
      <div className="bracket" style={{ width: '100%' }}>
        <div className="header">
          <h3>Gear</h3>

          <Button className="transmog-btn outlined-primary" onClick={handleOpen}>
            View Transmog
          </Button>

          <Dialog
            open={isOpen}
            onClose={handleClose}
            anchor="right"
            classes={{ paper: classes.paper, root: classes.root }}
            disableEnforceFocus
          >
            <img src={character.bg_url} width="100%" />

            <IconButton className="close-drawer" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Dialog>
        </div>

        <div
          className="content"
          style={{
            padding: 15,
            paddingBottom: 0,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <List items={groupedItems.azerite} characterClassId={characterClassId} />
          <List items={groupedItems.weapons} characterClassId={characterClassId} />
          <List items={groupedItems.rings} characterClassId={characterClassId} />
          <List items={groupedItems.trinkets} characterClassId={characterClassId} />
          <List items={groupedItems.regular} characterClassId={characterClassId} />
        </div>
      </div>
    </Grid>
  );
};

CharacterItems.propTypes = {
  character: PropTypes.object.isRequired,
};

export default CharacterItems;
