/* eslint-disable camelcase */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Axios from '../utils/axios';
import CharacterSearch from '../components/CharacterSearch';
import {
  successNotification as successNotificationAction,
  errorNotification as errorNotificationAction,
  infoNotification as infoNotificationAction,
} from '../actions/notifications';
import { API_URL } from '../constants/auth';
import CableContext from '../components/ActionCable/Context';

class CharacterSearchContainer extends Component {
  static propTypes = {
    region: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    successNotification: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
    infoNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      characters: [],
    };

    this.subscriptions = [];
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onReceived = this.onReceived.bind(this);
    this.subscribeSearched = this.subscribeSearched.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.region !== nextProps.region) {
      this.setState({ characters: [] });
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach(sub => this.context.subscriptions.remove(sub));
  }

  onReceived(character) {
    const { region, history, successNotification, errorNotification } = this.props;
    const { name, lowercase_name, realm_name, realm_slug, status } = character;

    if (status === 201) {
      history.push(`/${region.toLowerCase()}/${realm_slug}/${lowercase_name}`);
      successNotification(`Character ${name}-${realm_name} successfully imported!`);
    } else if (status === 404) {
      errorNotification(`Character ${name}-${realm_name} does not exist!`);
    } else if (status === 500) {
      errorNotification(
        'Unable to find/refresh the character. This is most likely an issue with the World of Warcraft API.'
      );
    }
  }

  subscribeSearched({ region, realm_name, name }) {
    const self = this;
    const subscription = this.context.subscriptions.create(
      {
        channel: 'FetchCharactersChannel',
        region,
        realm_name,
        name,
      },
      {
        received(data) {
          self.onReceived(data);
        },
      }
    );

    this.subscriptions.push(subscription);
  }

  handleSearch(query) {
    const { region, errorNotification } = this.props;

    if (query.length >= 2) {
      Axios.get(`${API_URL}/${region}/characters/autocomplete?q=${query}`)
        .then(response => {
          this.setState({ characters: get(response, 'data.autocomplete', []) });
        })
        .catch(e => {
          Rollbar.error('Failed character search', e, { query, region }); // eslint-disable-line no-undef
          errorNotification('There was an error processing your request.');
          this.setState({ characters: [] });
        });
    } else {
      this.setState({ characters: [] });
    }
  }

  handleSelect(character) {
    const { region, history, errorNotification, infoNotification } = this.props;
    const { name, lowercase_name, realm_name, realm_slug, type } = character;

    if (type === 'existing') {
      history.push(`/${region.toLowerCase()}/${realm_slug}/${lowercase_name}`);
    } else {
      this.subscribeSearched({ region, realm_name, name });

      Axios.post(`${API_URL}/${region}/${realm_slug}/${name}`)
        .then(() => infoNotification(`Enqueued character import for ${name}-${realm_name}`))
        .catch(() => errorNotification(`${name}-${realm_name} does not exist.`));
    }

    this.setState({ characters: [] });
  }

  render() {
    return (
      <React.Fragment>
        <CharacterSearch
          region={this.props.region}
          autoFocus={this.props.history.location.pathname === '/'}
          characters={this.state.characters}
          handleSearch={this.handleSearch}
          handleSelect={this.handleSelect}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  region: state.utils.region,
});

export default withRouter(
  connect(mapStateToProps, {
    successNotification: successNotificationAction,
    errorNotification: errorNotificationAction,
    infoNotification: infoNotificationAction,
  })(CharacterSearchContainer)
);

CharacterSearchContainer.contextType = CableContext;
