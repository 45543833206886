import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import FactionIcon from '../FactionIcon/';
import { getClassColor, getClassIcon, getRatingColor } from '../../utils/helpers';
import './PotentialAlts.scss';

const PotentialAlts = ({ title, alts }) => {
  if (alts.length < 1) return null;

  return (
    <div className="potential-alts">
      <h5>{title}</h5>

      <ul>
        {orderBy(alts, alt => alt.max_exp, ['desc', 'asc']).map(alt => (
          <Link
            key={alt.id}
            to={`/${alt.region.toLowerCase()}/${alt.realm_slug}/${alt.lowercase_name}`}
          >
            <li>
              <div className="left">
                <FactionIcon faction={alt.faction} width={35} height={35} />
                <img src={getClassIcon(alt.character_class)} alt="Character Class" />
                <span
                  style={{ color: getClassColor(alt.character_class), fontSize: 18 }}
                  className="alt-name"
                >
                  {alt.name}-{alt.realm_name}
                </span>
              </div>

              <div className="right" style={{ fontSize: 18 }}>
                <span style={{ color: getRatingColor(alt.max_cr), margin: '0 10px 0 5px' }}>
                  CR: {get(alt, 'max_cr', 0)}
                </span>

                <span style={{ color: getRatingColor(alt.max_exp), marginLeft: 5 }}>
                  EXP: {get(alt, 'max_exp', 0)}
                </span>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

PotentialAlts.propTypes = {
  title: PropTypes.string.isRequired,
  alts: PropTypes.array,
};

PotentialAlts.defaultProps = {
  alts: [],
};

export default PotentialAlts;
