import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { MetaTags } from 'react-meta-tags';
import Grid from '@material-ui/core/Grid';

import Hero from 'components/Hero';
import CharacterSearchContainer from 'containers/CharacterSearch';
import SignUpButtonContainer from 'containers/SignUpButton';
import TwitchIcon from 'assets/integrations/twitch.svg';
import DiscordIcon from 'assets/integrations/discord.svg';
import BnetIcon from 'assets/integrations/bnet.svg';
import heroMain from 'assets/hero-background-main2.jpg';
import PatreonCTA from 'components/PatreonCTA';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import './Home.scss';

const topAdStyles = { marginBottom: '-50px' };
const bottomAdStyles = { marginTop: '-50px', marginBottom: 20 };

const HomePage = () => {
  const isSignedIn = useSelector(state => state.auth.currentAccount.isSignedIn);

  return (
    <main role="main">
      <RichAd />

      <MetaTags>
        <title>WoW Arena PvP Inspect, Rankings & LFG System | ArenaMaster</title>
        <meta
          name="description"
          content="ArenaMaster is a World of Warcraft (WoW) PvP Inspect & Rankings web app. You can easily check rating & experience of any player and use the LFG to find arena partners!"
        />
      </MetaTags>

      <Hero bgImage={heroMain} />

      <DynamicAd970x250 mobileStyle={topAdStyles} />

      <div id="home-search-container">
        <h1>ArenaMaster WoW PvP Inspector</h1>
        <p>
          Easily search and add any character and see their detailed PvP stats <br />
          <i>even with weird accented letters</i>
        </p>

        <CharacterSearchContainer />
      </div>

      <Grid item xs={11} md={9} xl={6} style={{ margin: '0 auto 70px' }}>
        <div id="cta-container">
          <h2>ArenaMaster LFG</h2>

          <p>
            I'm sure we all know how frustrating it can be to sit in WoW LFG all day looking for
            people to play arenas and rbgs with. It doesn't help that you're blocked from actually
            playing something else while you wait to get accepted or for someone to apply to your
            group.
          </p>

          <p>
            By signing up for a <strong>FREE</strong> ArenaMaster account you'll get access to the
            improved LFG System where you'll be able to find partners with similiar rating and
            experience in just a few clicks. The days of copy-pasting your CR and EXP as well as
            verifying people that apply to your groups are over!
          </p>

          <h3 style={{ textAlign: 'center', marginTop: 30 }}>
            You'll also get access to the following one-click integrations:
          </h3>

          <ul>
            <li style={{ borderColor: '#01adff' }}>
              <h3>
                <BnetIcon width={30} height={30} fill="#fff" />
                Battle.net Integration
              </h3>

              <p>
                This unlocks access to the LFG system by importing all of your max level characters.
                You'll be able to apply to groups in 1 click with any of your characters and easily
                create groups at the same time.
              </p>
            </li>

            <li style={{ borderColor: '#7289da' }}>
              <h3>
                <DiscordIcon width={30} height={30} /> Discord Integration
              </h3>

              <p>
                By connecting with Discord you'll get added to a member only discord channel where
                you can discuss strategies, team comps, optimal talents, gear and much more with our
                evergrowing PvP community.
              </p>
            </li>

            <li style={{ borderColor: '#6441a5' }}>
              <h3>
                <TwitchIcon width={30} height={30} /> Twitch Integration
              </h3>

              <p>
                By connecting with Twitch we'll feature your stream on each of your character's
                profiles. It's a great way to promote your stream and grow your audience.
              </p>
            </li>
          </ul>

          <div className="buttons" style={isSignedIn ? { justifyContent: 'center' } : {}}>
            <Link to="/lfg">
              <Button className="lfg-button outlined-primary" variant="contained">
                Learn more about LFG
              </Button>
            </Link>

            {!isSignedIn && <SignUpButtonContainer text="Join Now" action="SignUp" />}
          </div>
        </div>

        <PatreonCTA patreonStyle={{ marginTop: 20 }} />
      </Grid>

      <DynamicAd970x250 mobileStyle={bottomAdStyles} desktopStyle={bottomAdStyles} />
    </main>
  );
};

export default HomePage;
