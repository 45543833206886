import React, { Component } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    state = {
      component: null,
    };

    componentWillMount() {
      Nprogress.start();
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: AsyncComponent } = await importComponent();

      Nprogress.done();

      if (this.mounted) {
        // eslint-disable-next-line
        this.setState({
          component: <AsyncComponent {...this.props} />,
        });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const AsyncComponent = this.state.component || <div />;

      return (
        <ReactPlaceholder type="text" rows={7} ready={AsyncComponent !== null}>
          {AsyncComponent}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
