import React from 'react';
import { connect } from 'react-redux';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import { handleAsideToggle } from '../actions/utils';

const OpenAsideButtonContainer = ({ handleClick, isAsideOpen }) =>
  !isAsideOpen && (
    <IconButton onClick={handleClick} style={{ marginTop: 30, marginLeft: -24 }}>
      <KeyboardArrowRight style={{ color: '#fff', fontSize: 35 }} />
    </IconButton>
  );

const mapStateToProps = state => ({
  isAsideOpen: state.utils.isAsideOpen,
});

export default connect(
  mapStateToProps,
  { handleClick: handleAsideToggle }
)(OpenAsideButtonContainer);
