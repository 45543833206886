import {
  SET_REGION,
  SHOW_LOADER,
  HIDE_LOADER,
  HANDLE_ASIDE_TOGGLE,
  TOGGLE_LFG_COLLAPSED,
  PATREON_MODAL,
} from '../constants/utils';
import { getCookie } from '../utils/cookies';

const region = getCookie('arenaMasterBnetRegion') || 'EU';
const lfgCollapsed = !!getCookie('arenaMasterLfgCollapsed');

const INITIAL_STATE = {
  region,
  lfgCollapsed,
  loader: false,
  isAsideOpen: true,
  patreonModalOpen: false,
};

export const utilsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REGION:
      return {
        ...state,
        region: action.region,
      };

    case SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case HANDLE_ASIDE_TOGGLE: {
      return {
        ...state,
        isAsideOpen: !state.isAsideOpen,
      };
    }

    case TOGGLE_LFG_COLLAPSED: {
      return {
        ...state,
        lfgCollapsed: action.payload,
      };
    }

    case PATREON_MODAL: {
      return {
        ...state,
        patreonModalOpen: action.payload,
      };
    }

    default:
      break;
  }

  return state;
};
