/* eslint-disable global-require, import/no-dynamic-require, camelcase */
import get from 'lodash/get';
import { REGIONS } from '../constants/regions';
import {
  FACTION_COLORS,
  CLASS_COLORS,
  RATING_COLORS,
  PLAYER_RATING_COLORS,
} from '../constants/colors';
import kyrian from '../assets/covenants/kyrian.png';
import necrolord from '../assets/covenants/necrolord.png';
import nightFae from '../assets/covenants/night-fae.png';
import venthyr from '../assets/covenants/venthyr.png';

const formatPath = name =>
  name
    .toLowerCase()
    .split(' ')
    .join('_');

export const getRegionIcon = region => REGIONS[region].icon;
export const getRegionNames = () => REGIONS.map(r => r.name);

export const getFactionColor = faction => FACTION_COLORS[faction];

export const getRaceIcon = (gender, race) =>
  require(`../assets/races/${formatPath(race)}_${gender}.png`);

export const getClassIcon = className => require(`../assets/classes/${formatPath(className)}.jpg`);
export const getClassColor = className => CLASS_COLORS[className];

export const getCovenantIcon = covenant => {
  switch (covenant) {
    case 'Kyrian':
      return kyrian;
    case 'Necrolord':
      return necrolord;
    case 'Night Fae':
      return nightFae;
    case 'Venthyr':
      return venthyr;
    default:
      '';
  }
};

export const getWinRateColor = num => {
  if (num >= 50) {
    return '#53ca41';
  } else if (num === 0 || num === 'NaN' || num === 'undefined') {
    return '#fff';
  } else {
    return '#c43244';
  }
};

export const getSpecIcon = (className, spec) =>
  require(`../assets/specializations/${formatPath(className)}/${formatPath(spec)}.png`);

export const getCharacterInfo = character => {
  const { specialization, character_class, level } = character;

  return `${level} ${specialization} ${character_class}`;
};

export const getRatingColor = rating => {
  const keys = Object.keys(RATING_COLORS).filter(ck => Number(rating) >= Number(ck));

  return RATING_COLORS[Math.max(...keys)];
};

export const getPlayersRatingColor = rating => {
  const keys = Object.keys(PLAYER_RATING_COLORS).filter(ck => Number(rating) >= Number(ck));

  return PLAYER_RATING_COLORS[Math.max(...keys)];
};

export const isServer = () => typeof window === 'undefined';

export const formatErrors = (errors = []) => {
  const formattedErrors = [];

  Object.keys(errors).forEach(key => {
    if (errors[key].length > 1) {
      formattedErrors[key] = errors[key].join(', ');
    } else {
      formattedErrors[key] = errors[key];
    }
  });

  return formattedErrors;
};

const SORT_ORDER = {
  Head: 0,
  Neck: 1,
  Shoulders: 2,
  Chest: 3,
  Back: 4,
  Wrist: 5,
  Hands: 6,
  Waist: 7,
  Legs: 8,
  Feet: 9,
  'Ring 1': 10,
  'Ring 2': 11,
  'Trinket 1': 12,
  'Trinket 2': 13,
  'Main Hand': 14,
  'Off Hand': 15,
};

export const sortSlots = slots => slots.sort((a, b) => SORT_ORDER[a] - SORT_ORDER[b]);

export const groupItems = items => {
  const sortedSlots = sortSlots(items.map(item => get(item, 'slot.name', 'head')));
  const result = {
    azerite: {},
    regular: {},
    weapons: {},
    trinkets: {},
    rings: {},
  };

  sortedSlots.forEach(slot => {
    if (['Head', 'Neck', 'Shoulders', 'Chest', 'Back'].includes(slot)) {
      result.azerite[slot] = items.find(item => item.slot.name === slot);
    }

    if (['Wrist', 'Hands', 'Waist', 'Legs', 'Feet'].includes(slot)) {
      result.regular[slot] = items.find(item => item.slot.name === slot);
    }

    if (['Ring 1', 'Ring 2'].includes(slot)) {
      result.rings[slot] = items.find(item => item.slot.name === slot);
    }

    if (['Trinket 1', 'Trinket 2'].includes(slot)) {
      result.trinkets[slot] = items.find(item => item.slot.name === slot);
    }

    if (['Main Hand', 'Off Hand'].includes(slot)) {
      result.weapons[slot] = items.find(item => item.slot.name === slot);
    }
  });

  return result;
};

export const getFactionId = faction => {
  if (faction === 'Horde') {
    return 1;
  } else if (faction === 'Alliance') {
    return 2;
  }

  return undefined;
};

export const getWinPercentage = (won, played) =>
  played === 0 ? 0 : Math.round((won / played) * 100);
