import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import qs from 'qs';
import { CircularProgress } from '@material-ui/core';
import { MetaTags } from 'react-meta-tags';

import Axios from 'utils/axios';
import { getFactionId } from 'utils/helpers';
import BracketFilters from 'components/BracketFilters';
import FactionFilters from 'components/FactionFilters';
import Slider from 'components/Slider';
import Hero from 'components/Hero';
import CustomBarChart from 'components/CustomBarChart';
import SpecRepresentation from 'components/SpecRepresentation';
import DynamicAd970x250 from 'app/components/Ads/DynamicAd970x250';
import RichAd from 'app/components/Ads/RichAd';
import StaticAd728x90 from 'app/components/Ads/StaticAd768x90';
import './LadderStats.scss';

const bottomAdStyles = { margin: '0 auto' };
const topAdStyles = { marginTop: 20 };

const LadderStats = () => {
  const [currentBracket, setCurrentBracket] = useState('3v3');
  const [currentFaction, setCurrentFaction] = useState('Both');
  const [rating, setRating] = useState({ min_cr: 1000, max_cr: 4000 });
  const [ratingComplete, setRatingComplete] = useState({ min_cr: 1000, max_cr: 4000 });
  const [chartData, setChartData] = useState([]);
  const [hasData, setHasData] = useState(true);

  const handleBracketChange = bracket => setCurrentBracket(bracket);
  const handleFactionChange = faction => setCurrentFaction(faction);
  const handleRatingChange = values => setRating({ min_cr: values.min, max_cr: values.max });
  const handleRatingChangeComplete = () =>
    setRatingComplete({ min_cr: rating.min_cr, max_cr: rating.max_cr });

  const region = useSelector(state => state.utils.region);

  const query = {
    bracket: currentBracket,
    faction_id: getFactionId(currentFaction),
    min_rating: ratingComplete.min_cr,
    max_rating: ratingComplete.max_cr,
  };

  const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
  const endpoint = `/api/v1/${region}/class_representation?${queryString}`;

  useEffect(() => {
    try {
      const getData = async () => {
        const response = await Axios.get(endpoint);
        setChartData(response.data);

        response.data.length === 0 ? setHasData(false) : setHasData(true);
      };

      getData();
    } catch (error) {
      setChartData([]);
    }
  }, [currentBracket, currentFaction, ratingComplete.min_cr, ratingComplete.max_cr, region]);

  return (
    <div className="ladder-stats">
      <RichAd />

      <MetaTags>
        <title>WoW PvP Ladder Representation | ArenaMaster</title>
        <meta
          name="description"
          content="ArenaMaster World of Warcraft Ladder Representation Stats for Arena & RBG"
        />
      </MetaTags>

      <Hero />

      <StaticAd728x90 desktopStyle={topAdStyles} mobileStyle={topAdStyles} />

      <div className="title">
        <h1>World of Warcraft Ladder Representation</h1>
      </div>

      {!hasData ? (
        <CircularProgress />
      ) : (
        <Grid item lg={11} xl={8} className="ladder-container">
          <div className="filters-container">
            <Grid item className="filters" sm={6} lg={3}>
              <BracketFilters
                currentBracket={currentBracket}
                handleBracketChange={handleBracketChange}
              />
            </Grid>

            <Grid
              item
              className="rating-slider"
              xs={12}
              sm={6}
              lg={5}
              style={{ flexDirection: 'column' }}
            >
              <h3 style={{ marginLeft: '-10px' }} className="slider-title">
                Rating Range:
              </h3>

              <Slider
                step={50}
                minValue={1000}
                value={{ min: rating.min_cr, max: rating.max_cr }}
                onChange={handleRatingChange}
                onChangeComplete={handleRatingChangeComplete}
              />
            </Grid>

            <Grid item className="filters" style={{ justifyContent: 'flex-end' }} sm={6} lg={3}>
              <FactionFilters
                currentFaction={currentFaction}
                handleFactionChange={handleFactionChange}
              />
            </Grid>
          </div>

          <Grid container className="data-container">
            <Grid item xs={12}>
              <h2 className="subtitle">{currentBracket !== 'solo' && 'Class'} Representation</h2>

              <CustomBarChart
                chartData={chartData}
                ratingComplete={ratingComplete}
                currentBracket={currentBracket}
                currentFaction={currentFaction}
              />
            </Grid>

            <Grid item xs={12}>
              <SpecRepresentation
                region={region}
                currentBracket={currentBracket}
                currentFaction={currentFaction}
                ratingComplete={ratingComplete}
                queryString={queryString}
              />
            </Grid>

            <DynamicAd970x250 desktopStyle={bottomAdStyles} mobileStyle={bottomAdStyles} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default LadderStats;
