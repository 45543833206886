import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountNotifications from '../components/AccountNotifications';
import { updateNotificationSettings as updateNotificationSettingsAction } from '../actions/accounts';

const NotificationSettingsFormContainer = ({ currentAccount, updateNotificationSettings }) => (
  <AccountNotifications currentAccount={currentAccount} handleSubmit={updateNotificationSettings} />
);

NotificationSettingsFormContainer.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  updateNotificationSettings: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentAccount } }) => ({ currentAccount });

export default connect(
  mapStateToProps,
  {
    updateNotificationSettings: updateNotificationSettingsAction,
  },
)(NotificationSettingsFormContainer);
