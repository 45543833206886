import React from 'react';
import { connect } from 'react-redux';
import { handleRegionChange as handleRegionChangeAction } from '../actions/utils';
import RegionChange from '../components/RegionChange/';

const RegionChangeContainer = props => <RegionChange {...props} />;

const mapStateToProps = ({ utils }) => {
  const { region } = utils;

  return { region };
};

export default connect(
  mapStateToProps,
  {
    handleRegionChange: handleRegionChangeAction,
  },
)(RegionChangeContainer);
