import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { applyToGroup as applyToGroupAction } from '../actions/groups';

class ApplyGroupButtonContainer extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    applyToGroup: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
  };

  state = {
    open: false,
    message: '',
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMessageChange = e => {
    this.setState({ message: e.target.value });
  };

  render() {
    const { group, applyToGroup, filters } = this.props;
    const { open, message } = this.state;

    return (
      <div className="apply-btn">
        <Button onClick={this.handleOpen} className="primary-btn">
          Apply
        </Button>

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="dialog-title"
          className="dialog"
        >
          <DialogTitle className="dialog-title">Apply for group</DialogTitle>

          <DialogContent className="dialog-content">
            <textarea
              rows={6}
              cols={50}
              placeholder="Write something about yourself, your goals, etc."
              onChange={this.handleMessageChange}
              value={message}
              autoFocus
            />
          </DialogContent>

          <DialogActions className="actions-buttons">
            <Button onClick={this.handleClose} className="cancel-button">
              Cancel
            </Button>

            <Button
              onClick={() => {
                applyToGroup(group, filters.character_id, message);
              }}
              className="confirm-button"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ groups: { filters } }) => ({
  filters,
});

export default connect(mapStateToProps, {
  applyToGroup: applyToGroupAction,
})(ApplyGroupButtonContainer);
