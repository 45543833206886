import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { trackPage } from './googleAnalytics';
import ahoy from './ahoy';

const setBodyClass = path => {
  document.getElementsByTagName('body')[0].id =
    path === '/' ? 'home-body' : `${path.split('/')[1]}-body`;
};

class GoogleAnalyticsListener extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    const { history, location } = this.props;

    trackPage(`${location.pathname}${location.search}`);

    ahoy.trackClicks();
    ahoy.trackView();

    setBodyClass(location.pathname);

    history.listen(l => {
      trackPage(l.pathname);
      setBodyClass(l.pathname);
      ahoy.trackView();
    });
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(GoogleAnalyticsListener);
