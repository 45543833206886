import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { static728x90Id, dynamic300x50Id } from 'app/constants/ads';

const StaticAd728x90 = ({ mobileStyle = {}, desktopStyle = {} }) => {
  const [size, setSize] = useState(null);
  const patreon = useSelector(state => state.auth.currentAccount.patreon);
  const ref = createRef();

  useEffect(() => {
    if (window.innerWidth < 728) {
      setSize('mobile');
    } else {
      setSize('desktop');
    }
  }, []);

  useEffect(() => {
    if (patreon || !size) return;

    window.__vm_add = window.__vm_add || [];
    window.__vm_add.push(ref.current);

    return () => {
      window.__vm_remove = window.__vm_remove || [];
      if (ref.current) window.__vm_remove.push(ref.current);
    };
  }, [patreon, size]);

  if (patreon || !size) return null;

  if (size === 'mobile') {
    return (
      <div className="dynamic-300x50-container" style={mobileStyle}>
        <div className="vm-placement" data-id={dynamic300x50Id} ref={ref}></div>
      </div>
    );
  }

  return (
    <div className="static-728x90-container" style={desktopStyle}>
      <div className="vm-placement" data-id={static728x90Id} ref={ref}></div>
    </div>
  );
};

export default StaticAd728x90;
