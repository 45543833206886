/* eslint-disable camelcase */

import { NotificationManager } from './notifications';
import Axios from '../utils/axios';
import { API_URL } from '../constants/auth';
import {
  ADD_CHARACTER,
  REFRESH_CHARACTER,
  FOLLOW_CHARACTER,
  UNFOLLOW_CHARACTER,
  ADD_CHARACTERS,
  ADD_CHARACTER_CLASSES,
  ADD_FOLLOWING_CHARACTERS,
  UPDATE_CONNECTED_CHARACTER,
} from '../constants/characters';
import history from '../store/history';
import { updateAccount } from './accounts';
import { hideLoader, showLoader } from './utils';

export const addCharacter = (region, realm, character) => ({
  type: ADD_CHARACTER,
  payload: {
    region,
    realm,
    character,
  },
});

export const updateConnectedCharacter = character => ({
  type: UPDATE_CONNECTED_CHARACTER,
  payload: {
    character,
  },
});

export const addCharacters = characters => ({
  type: ADD_CHARACTERS,
  payload: characters,
});

export const addCharacterClasses = classes => ({
  type: ADD_CHARACTER_CLASSES,
  payload: classes,
});

export const refreshCharacter = (region, realm, character) => dispatch =>
  dispatch({
    type: REFRESH_CHARACTER,
    payload: {
      region,
      realm,
      character,
    },
  });

export const fetchCharacter = (region, realm, name, search = '') => dispatch => {
  dispatch(showLoader());

  Axios.get(`${API_URL}/${region}/${realm}/${name}${search}`)
    .then(response => {
      dispatch(addCharacter(region, realm, response.data));
      dispatch(hideLoader());
    })
    .catch(error => {
      const {
        response: { status },
      } = error;

      if (status === 404) {
        history.replace('/');
        dispatch(NotificationManager.warning(`Character ${name}-${realm} does not exist.`));
      } else if (status === 500) {
        history.replace('/');
        dispatch(
          NotificationManager.error(
            'Unable to find/refresh the character. This is most likely an issue with the World of Warcraft API.'
          )
        );
      }

      dispatch(hideLoader());
    });
};

export const refreshCharacterData = (region, realm, name, showNotification = false) => dispatch => {
  Axios.get(`${API_URL}/${region}/${realm}/${name}/refresh`)
    .then(() => {
      dispatch(NotificationManager.info('Enqueued character refresh!'));
    })
    .catch(error => {
      const {
        response: {
          data: { errors },
        },
      } = error;

      if (showNotification) {
        errors.map(e => dispatch(NotificationManager.error(e)));
      }
    });
};

export const handleFollow = character => dispatch => {
  const { region, name, lowercase_name, realm_slug, realm_name, is_following } = character;
  const path = is_following ? 'unfollow' : 'follow';

  Axios.post(`${API_URL}/characters/${character.id}/${path}`)
    .then(response => {
      const { is_following } = response.data; // eslint-disable-line no-shadow

      dispatch({
        type: is_following ? FOLLOW_CHARACTER : UNFOLLOW_CHARACTER,
        payload: {
          region,
          realm_slug,
          name,
          lowercase_name,
          is_following,
        },
      });

      if (is_following) {
        dispatch(NotificationManager.success(`You are now following ${name}-${realm_name}`));
      } else {
        dispatch(NotificationManager.info(`You are no longer following ${name}-${realm_name}`));
      }
    })
    .catch(() => {
      dispatch(NotificationManager.warning(`Could not ${path} that character.`));
    });
};

export const fetchCharacters = () => dispatch => {
  dispatch(showLoader());
  return Axios.get(`${API_URL}/characters`)
    .then(response => {
      dispatch(addCharacters(response.data));
      dispatch(hideLoader());

      return response.data;
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching your characters'));
      dispatch(hideLoader());
    });
};

export const updateMainCharacter = character => dispatch => {
  Axios.post(`${API_URL}/characters/${character.id}/update_main`)
    .then(response => {
      dispatch(addCharacters(response.data));
      dispatch(updateAccount({ avatar_url: character.avatar_url }));
      dispatch(NotificationManager.success('Successfully updated main character'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error updating main char'));
    });
};

export const updateAltHidden = character => dispatch => {
  Axios.post(`${API_URL}/characters/${character.id}/toggle_alt_hidden`)
    .then(() => {
      dispatch(updateConnectedCharacter({ ...character, alt_hidden: !character.alt_hidden }));
      dispatch(NotificationManager.success('Successfully updated your alt hidden status'));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error updating alt hidden status'));
    });
};

export const fetchCharacterClasses = () => dispatch => {
  Axios.get(`${API_URL}/character_classes`)
    .then(response => {
      dispatch(addCharacterClasses(response.data));
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching character classes'));
    });
};

export const fetchFollowingCharacters = () => dispatch => {
  dispatch(showLoader());
  Axios.get(`${API_URL}/characters/following`)
    .then(response => {
      dispatch({ type: ADD_FOLLOWING_CHARACTERS, payload: response.data });
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(NotificationManager.warning('Error fetching following characters'));
      dispatch(hideLoader());
    });
};
