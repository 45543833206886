import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import './MaterialDropdown.scss';

class MaterialDropdown extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    dropdownItems: PropTypes.array.isRequired,
    onItemClicked: PropTypes.func,
    labelImage: PropTypes.string,
  };

  static defaultProps = {
    onItemClicked: null,
    labelImage: '',
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleItemClicked = this.handleItemClicked.bind(this);
  }

  state = {
    anchorEl: null,
    open: false,
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState(prevState => ({
      open: !prevState.open,
      anchorEl: prevState ? null : e.currentTarget,
    }));
  }

  handleItemClicked(e, item) {
    const { onItemClicked } = this.props;

    if (onItemClicked) {
      e.preventDefault();
      e.stopPropagation();
      onItemClicked(item.name);
    }
  }

  render() {
    const { open } = this.state;
    const { label, labelImage, dropdownItems } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Button
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          className="dropdown-button"
          onClick={this.handleClick}
        >
          {labelImage.length && <img src={labelImage} style={{ marginRight: 10 }} alt="Label" />}
          {label}
          <Icon>{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
        </Button>

        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className="menu-container"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom',
                width: '135px',
                left: '-18px',
              }}
            >
              <Paper
                style={{
                  background: '#0c0d0e',
                  boxShadow: 'none',
                }}
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList className="dropdown-menu" open={open} onClose={this.handleClose}>
                    {dropdownItems.map((dropdownItem, index) => (
                      <Link
                        to={dropdownItem.link || ''}
                        onClick={e => this.handleItemClicked(e, dropdownItem)}
                        key={index}
                      >
                        <MenuItem
                          onClick={this.handleClose}
                          className={dropdownItem.name === 'US' ? 'border-bottom-none' : ''}
                        >
                          <img
                            src={dropdownItem.image}
                            style={{ marginRight: 10 }}
                            alt="Dropdown Label"
                          />
                          {dropdownItem.name}
                        </MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default MaterialDropdown;
