import React from 'react';
import Grid from '@material-ui/core/Grid';

import DynamicAd300x250 from 'app/components/Ads/DynamicAd300x250';
import RichAd from 'app/components/Ads/RichAd';
import AccountSettingsFormContainer from 'containers/AccountSettingsForm';

const AccountPage = () => (
  <Grid container>
    {/* <RichAd vertical={false} /> */}

    <Grid item sm={12} lg={7} xl={5} style={{ width: '100%' }}>
      <h1 className="title">ACCOUNT SETTINGS</h1>

      <AccountSettingsFormContainer />
    </Grid>

    <Grid style={{ margin: '0 auto' }} item lg={5}>
      {/* <DynamicAd300x250 className="dashboard-ads" /> */}
    </Grid>
  </Grid>
);

export default AccountPage;
