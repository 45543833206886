import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccountIntegrations from 'app/components/AccountIntegrations';
import { updateDiscordRoles } from 'actions/accounts';

class AccountIntegrationsContainer extends Component {
  render() {
    return <AccountIntegrations {...this.props} />;
  }
}

const mapStateToProps = ({ auth, utils }) => {
  const { currentAccount } = auth;
  const { region } = utils;

  return { currentAccount, region };
};

export default connect(mapStateToProps, {
  updateDiscordRoles,
})(AccountIntegrationsContainer);
