import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import VideocamIcon from '@material-ui/icons/Videocam';
import Star from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';

import { getClassIcon } from 'utils/helpers';
import { getClassColor } from 'app/utils/helpers';
import './TwitchCard.scss';

const width = 384;
const height = 216;

const TwitchCard = ({ streamData }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const imageStyle = !imgLoaded ? { display: 'none' } : {};

  return (
    <div className="streamers-container">
      {streamData
        .sort((a, b) => {
          if (a.featured || a.viewer_count > b.viewer_count) {
            return -1;
          }

          return 0;
        })
        .map(streamer => (
          <div className="stream-container" key={streamer.id}>
            <a
              href={`https://twitch.tv/${streamer.user_name.toLowerCase()}`}
              className="twitch-stream-card"
              id={streamer.user_name}
              target="_blank"
            >
              <div className="stream">
                <div className="tooltip" style={streamer.featured ? { background: '#ff8000' } : {}}>
                  Click to watch the stream
                </div>

                <div
                  className="right-edge"
                  style={streamer.featured ? { borderTop: '0.6rem solid #ff8000' } : {}}
                ></div>
                <div
                  className="left-edge"
                  style={streamer.featured ? { borderRight: '0.6rem solid #ff8000' } : {}}
                ></div>
                <div
                  className="left-shadow"
                  style={streamer.featured ? { background: '#ff8000' } : {}}
                ></div>
                <div
                  className="bottom-shadow"
                  style={streamer.featured ? { background: '#ff8000' } : {}}
                ></div>

                <div className="content">
                  <div
                    className="thumbnail"
                    style={
                      !imgLoaded
                        ? {
                            borderBottom: '1px solid #6441a5',
                          }
                        : {}
                    }
                  >
                    {!imgLoaded && <VideocamIcon className="video-img" />}

                    <img
                      src={streamer.thumbnail_url
                        .replace('{width}', width)
                        .replace('{height}', height)}
                      style={imageStyle}
                      onLoad={() => setImgLoaded(true)}
                      className={streamer.featured ? 'yellow-border' : ''}
                    />

                    <div className="live">LIVE</div>
                    <div className="viewers">
                      {streamer.viewer_count} {streamer.viewer_count === 1 ? 'viewer' : 'viewers'}
                    </div>
                  </div>

                  <div className="streamer-info">
                    <div className="info">
                      <h2>{streamer.user_name}</h2>
                      {streamer.featured && (
                        <Tooltip
                          placement="top"
                          title={
                            <span>
                              <strong>{streamer.user_name}</strong> is supporting ArenaMaster via
                              Patreon! &#129395;
                            </span>
                          }
                          classes={{ tooltip: 'patreon-tooltip' }}
                        >
                          <div className="featured-stream">
                            <Star />
                            Featured
                          </div>
                        </Tooltip>
                      )}
                    </div>

                    <p>{streamer.title}</p>
                  </div>
                </div>
              </div>
            </a>

            <div className="streamer-classes">
              {streamer.characters
                .filter(char => !char.alt_hidden)
                .sort((a, b) => b.max_exp - a.max_exp)
                .map((char, index) => (
                  <Tooltip
                    title={
                      <span style={{ color: getClassColor(char.character_class) }}>
                        {char.name} - {char.realm_name}
                      </span>
                    }
                    classes={{ tooltip: 'black-tooltip' }}
                    key={index}
                  >
                    <Link
                      to={`/${char.region.toLowerCase()}/${char.realm_slug}/${char.lowercase_name}`}
                      target="_blank"
                    >
                      <div
                        className="streamer-class"
                        style={{ border: `2px solid ${getClassColor(char.character_class)}` }}
                      >
                        <img
                          src={getClassIcon(char.character_class)}
                          alt="Character Class"
                          width="30"
                          style={{
                            border: `2px solid ${getClassColor(char.character_class)}`,
                          }}
                        />

                        <span>{char.max_exp}</span>
                      </div>
                    </Link>
                  </Tooltip>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

TwitchCard.propTypes = {
  streamData: PropTypes.array.isRequired,
};

export default TwitchCard;
