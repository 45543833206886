import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CableContext from './Context';
import { isServer } from '../../utils/helpers';

class Provider extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentWillMount() {
    const { url } = this.props;
    const ssr = isServer();

    if (!ssr) {
      this.cable = require('actioncable').createConsumer(url); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    if (this.cable) {
      this.cable.disconnect();
    }
  }

  render() {
    return (
      <React.Fragment>
        <CableContext.Provider value={this.cable}>{this.props.children}</CableContext.Provider>
      </React.Fragment>
    );
  }
}

export default Provider;
