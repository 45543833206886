import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { toggleLfgCollapsed as toggleLfgCollapsedAction } from '../actions/utils';

const ShowLfgHeroButtonContainer = ({ lfgCollapsed, toggleLfgCollapsed }) =>
  !lfgCollapsed && (
    <Button id="show-more-lfg" className="outlined-primary" onClick={toggleLfgCollapsed}>
      Remind me how it works
    </Button>
  );

const mapStateToProps = ({ utils: { lfgCollapsed } }) => ({ lfgCollapsed });

export default connect(mapStateToProps, {
  toggleLfgCollapsed: toggleLfgCollapsedAction,
})(ShowLfgHeroButtonContainer);
