import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import './CharacterClassesFilters.scss';
import { getClassIcon, getSpecIcon } from '../../utils/helpers';
import { MobileToTablet, SmallDesktop } from 'app/utils/responsive';

const CharacterClassesFilters = ({
  characterClasses,
  handleCharacterClassChange,
  handleSpecializationChange,
  specializationIds,
  currentCharacterClassId,
  toggleClass,
}) => {
  const setMargin = length => {
    if (length === 2) {
      return 'move-two specializations';
    } else if (length === 4) {
      return 'move-four specializations';
    }
    return 'specializations';
  };

  return (
    <div
      className={currentCharacterClassId ? 'character-classes display center' : 'character-classes'}
      style={specializationIds.length > 0 ? { marginBottom: 70 } : {}}
    >
      {characterClasses.map(characterClass => (
        <div key={characterClass.id} className="char-class">
          <div
            key={characterClass.id}
            onClick={() => {
              handleCharacterClassChange(characterClass.id);
              toggleClass(characterClass.id);
            }}
            style={{ position: 'relative' }}
          >
            <MobileToTablet>
              <Tooltip title={characterClass.name} classes={{ tooltip: 'big-tooltip' }}>
                <img
                  src={getClassIcon(characterClass.name)}
                  className={
                    currentCharacterClassId === characterClass.id
                      ? 'selected img-style'
                      : 'img-style'
                  }
                  alt="Character Class Icon"
                  style={
                    currentCharacterClassId && currentCharacterClassId !== characterClass.id
                      ? { display: 'none' }
                      : {}
                  }
                />
              </Tooltip>
            </MobileToTablet>

            <SmallDesktop>
              <Tooltip title={characterClass.name} classes={{ tooltip: 'big-tooltip' }}>
                <img
                  src={getClassIcon(characterClass.name)}
                  className={
                    currentCharacterClassId === characterClass.id
                      ? 'selected img-style'
                      : 'img-style'
                  }
                  alt="Character Class Icon"
                />
              </Tooltip>
            </SmallDesktop>
          </div>

          {currentCharacterClassId === characterClass.id && (
            <div className={setMargin(characterClass.specializations.length)}>
              {characterClass.specializations.map(spec => (
                <div
                  key={spec.id}
                  className={
                    specializationIds.includes(spec.id)
                      ? 'selected specialization'
                      : 'specialization'
                  }
                  onClick={() => handleSpecializationChange(spec.id)}
                >
                  <Tooltip title={spec.name} classes={{ tooltip: 'big-tooltip' }} key={spec.id}>
                    <img
                      src={getSpecIcon(characterClass.name, spec.name)}
                      alt="Character specialization"
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

CharacterClassesFilters.propTypes = {
  characterClasses: PropTypes.array.isRequired,
  currentCharacterClassId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  handleCharacterClassChange: PropTypes.func.isRequired,
  handleSpecializationChange: PropTypes.func.isRequired,
  toggleClass: PropTypes.func.isRequired,
};

CharacterClassesFilters.defaultProps = {
  currentCharacterClassId: null,
};

export default CharacterClassesFilters;
