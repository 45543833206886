import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import GroupContainer from '../../containers/Group';
import ModifiedAlert from '../Alert';
import LoaderContainer from '../../containers/Loader';

const GroupList = ({ groups, deletable, editable, applicable, viewable, cancelApplication }) => {
  const matchMyGroups = useRouteMatch('/lfg/my-groups');
  const bnetConnected = useSelector(state => state.auth.currentAccount.bnet_connected);

  return (
    <div style={{ position: 'relative' }}>
      <LoaderContainer styleLoader={{ marginTop: '40px' }} />

      {!bnetConnected && (
        <ModifiedAlert text="You need to connect with Battle.net first." severity="info" />
      )}

      {groups.length === 0 && bnetConnected && (
        <ModifiedAlert
          text={
            matchMyGroups
              ? "You haven't created any group."
              : "No groups are matching your character's specialization, experience, and current rating."
          }
          severity="info"
          data={groups}
          styleAlert={{ marginBottom: '20px' }}
        />
      )}

      {groups.length > 0 &&
        groups
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map(group => (
            <GroupContainer
              group={group}
              key={group.id}
              deletable={deletable}
              applicable={applicable}
              viewable={viewable}
              editable={editable}
              cancelApplication={cancelApplication}
            />
          ))}
    </div>
  );
};

GroupList.propTypes = {
  groups: PropTypes.array.isRequired,
  deletable: PropTypes.bool,
  editable: PropTypes.bool,
  applicable: PropTypes.bool,
  viewable: PropTypes.bool,
  cancelApplication: PropTypes.bool,
};

GroupList.defaultProps = {
  applicable: true,
  viewable: true,
  deletable: false,
  cancelApplication: false,
  editable: false,
};

export default GroupList;
