import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1820,
    },
  },

  palette: {
    secondary: { main: '#fff' },
    primary: { main: '#c43244' },
  },

  typography: { fontFamily: 'Catamaran' },

  overrides: {
    MUIDataTableBodyCell: {
      root: {
        border: '1px solid #333842',
        fontSize: '14px',
        color: '#fff',
      },
    },

    MUIDataTable: {
      paper: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(12, 13, 14, .4)',
        marginTop: '30px',
      },
    },

    MUIDataTableHead: {
      main: {
        fontWeight: 'bold',
      },
    },

    MUIDataTableHeadRow: {
      root: {
        border: 0,
      },
    },

    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#151b20',
        fontWeight: 'bold',
        fontSize: '16px',
        borderBottom: 0,
      },

      toolButton: {
        '&:hover': {
          color: '#c43244',
        },
      },

      sortActive: {
        color: '#fff',
      },

      sortAction: {
        display: 'block',
      },
    },

    MuiTableSortLabel: {
      iconDirectionDesc: {
        color: '#fff',
        '&:hover': {
          opacity: 1,
        },
      },

      iconDirectionAsc: {
        color: '#fff',
        opacity: 1,
      },
    },

    MuiTablePagination: {
      input: {
        backgroundColor: '#fff',
        margin: '0 10px',
      },

      caption: {
        fontSize: '14px',
        color: '#fff',
      },

      select: {
        color: '#000',
      },

      selectIcon: {
        color: '#000',
      },

      actions: {
        cursor: 'pointer',
      },
    },
  },
});

const App = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={3500}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  </MuiThemeProvider>
);

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
