import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleFollow as handleFollowAction } from '../actions/characters';
import CharacterInfo from '../components/CharacterInfo/';

const CharacterInfoContainer = props => <CharacterInfo {...props} />;

CharacterInfoContainer.propTypes = {
  character: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  handleFollow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { currentAccount } }) => ({
  currentAccount,
});

export default connect(
  mapStateToProps,
  {
    handleFollow: handleFollowAction,
  },
)(CharacterInfoContainer);
