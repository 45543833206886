export const API_URL = '/api/v1';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const FORGOT_PASSWORD_REDIRECT_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://arenamaster.io/reset-password'
    : 'http://localhost:3000/reset-password';
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
