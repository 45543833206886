import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import ModifiedAlert from '../components/Alert';
import {
  fetchAppliedGroups as fetchAppliedGroupsAction,
  fetchGroupApplications as fetchGroupApplicationsAction,
} from '../actions/groups';
import GroupList from '../components/GroupList';
import { warningNotification } from 'app/actions/notifications';
import LoaderContainer from '../containers/Loader';
import PatreonCTA from '../components/PatreonCTA';

class AppliedGroupsPageContainer extends Component {
  static propTypes = {
    groups: PropTypes.array.isRequired,
    appliedGroupsFetched: PropTypes.bool.isRequired,
    fetchAppliedGroups: PropTypes.func.isRequired,
    fetchGroupApplications: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
    warningNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      appliedGroupsFetched,
      fetchAppliedGroups,
      fetchGroupApplications,
      warningNotification,
      currentAccount,
    } = this.props;

    if (!currentAccount.bnet_connected) {
      warningNotification('You must connect with Battle.net to use the LFG.');
      return;
    }

    if (!appliedGroupsFetched.length) {
      fetchAppliedGroups().then(() => {
        fetchGroupApplications();
      });
    }
  }

  render() {
    const { currentAccount, appliedGroups, groups } = this.props;

    return (
      <React.Fragment>
        <MetaTags>
          <title>Applied Groups | ArenaMaster</title>
        </MetaTags>

        <LoaderContainer />

        <PatreonCTA />

        {!currentAccount.bnet_connected ? (
          <Grid item md={12}>
            <ModifiedAlert
              text="You need to connect with Battle.net first."
              severity="info"
              data={appliedGroups}
            />
          </Grid>
        ) : (
          <React.Fragment>
            {groups.length === 0 ? (
              <ModifiedAlert
                text="You haven't applied to any group."
                severity="info"
                data={groups}
              />
            ) : (
              <Grid item md={12}>
                <GroupList
                  {...this.props}
                  viewable
                  cancelApplication
                  deletable={false}
                  applicable={false}
                />
              </Grid>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ groups: { appliedGroups, appliedGroupsFetched }, auth }) => {
  const { currentAccount } = auth;

  return {
    groups: appliedGroups,
    appliedGroupsFetched,
    currentAccount,
  };
};

export default connect(mapStateToProps, {
  fetchAppliedGroups: fetchAppliedGroupsAction,
  fetchGroupApplications: fetchGroupApplicationsAction,
  warningNotification,
})(AppliedGroupsPageContainer);
