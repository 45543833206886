import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';

import AvatarImage from 'assets/avatar.png';
import './ProfileDropdown.scss';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

const ProfileDropdown = ({ currentAccount, handleSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{ height: '100%', padding: '15px' }}
        onClick={handleClick}
      >
        <div className="profile-dropdown">
          <Avatar
            alt="Characters image"
            src={currentAccount.avatar_url || AvatarImage}
            className={currentAccount.avatar_url ? '' : 'default'}
            style={{ width: '30px', height: '30px' }}
          />

          <div className="character-info">{currentAccount.username || currentAccount.email}</div>

          <Icon style={{ color: '#fff' }}>
            {open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </Icon>
        </div>
      </Button>

      <Popper open={open} anchorEl={anchorEl} transition disablePortal style={{ zIndex: 9999 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                background: '#0c0d0e',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className="menu-items">
                  <Link to="/dashboard" onClick={handleClose}>
                    <MenuItem>Dashboard</MenuItem>
                  </Link>

                  <Link to="/dashboard/integrations" onClick={handleClose}>
                    <MenuItem>Integrations</MenuItem>
                  </Link>

                  <Link to="/dashboard/characters" onClick={handleClose}>
                    <MenuItem>Character List</MenuItem>
                  </Link>

                  <Link to="/dashboard/following" onClick={handleClose}>
                    <MenuItem>Following</MenuItem>
                  </Link>

                  {currentAccount.admin && [
                    <MenuItem key={1}>
                      <a href="/admin" target="_blank">
                        Admin Panel
                      </a>
                    </MenuItem>,
                    <MenuItem key={2}>
                      <a href="/sidekiq" target="_blank">
                        Sidekiq
                      </a>
                    </MenuItem>,
                    <MenuItem key={3}>
                      <a
                        href="https://46.101.221.169:9090"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        System
                      </a>
                    </MenuItem>,
                  ]}
                  <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ProfileDropdown.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default ProfileDropdown;
