import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { PATREON_MODAL } from 'constants/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  paperWidthSm: {
    position: 'relative !important',
    top: '0 !important',
    right: '0 !important',
    maxWidth: '600px !important',
    padding: 10,
    paddingBottom: 20,
  },

  closeButton: {
    position: 'absolute',
    right: 10,
    top: 14,
  },

  closeIcon: {
    '&:hover': {
      color: '#c43244',
    },
  },
});

const PatreonModal = () => {
  const dispatch = useDispatch();
  const { patreonModalOpen } = useSelector(state => state.utils);
  const classes = useStyles();

  const handleClose = () => {
    dispatch({ type: PATREON_MODAL, payload: false });
  };

  return (
    <div className="patreon-dialog">
      <Dialog
        open={patreonModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Become a Patron?
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: '#fff', fontSize: 18 }}
          >
            Support us on Patreon and get the ability to make your{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>groups stand out</span> in LFG for
            your main character.
            <br />
            <br />
            You'll also get access to a{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>
              subscriber-only Discord Channel
            </span>{' '}
            where you can be a part of brainstorming future features for the app and enjoy an{' '}
            <span style={{ color: '#f96854', fontWeight: '700' }}>ad-free experience.</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <a href="https://www.patreon.com/arenamaster" target="_blank" rel="noopener">
            <img
              src="https://arenamaster.s3.eu-central-1.amazonaws.com/addon/patreon-btn.png"
              alt="Support on Patreon"
              width="250"
            />
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PatreonModal;
