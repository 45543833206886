import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import SettingsAsideContainer from 'containers/SettingsAside';
import Hero from 'components/Hero';
import AccountPage from './Account';
import NotificationsPage from './Notifications';
import OpenAsideButtonContainer from 'containers/OpenAsideButton';
import IntegrationsPage from './Integrations';
import CharactersListPage from './CharactersList';
import FollowingPageContainer from 'containers/FollowingPage';
import { isServer } from 'utils/helpers';
import './Settings.scss';

const SettingsPage = () => {
  const isMobile = !isServer() && window.innerWidth < 768; // eslint-disable-line no-undef

  return (
    <React.Fragment>
      <MetaTags>
        <title>Account Settings | ArenaMaster</title>
      </MetaTags>

      <Hero />
      <Grid container className="settings-page">
        <Grid item sm={4} lg={3}>
          <SettingsAsideContainer />
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          <div className="settings-page-content">
            {isMobile && <OpenAsideButtonContainer />}

            <Switch>
              <Route path="/dashboard" exact component={AccountPage} />
              <Route path="/dashboard/notifications" exact component={NotificationsPage} />
              <Route path="/dashboard/integrations" exact component={IntegrationsPage} />
              <Route path="/dashboard/characters" exact component={CharactersListPage} />
              <Route path="/dashboard/following" exact component={FollowingPageContainer} />
            </Switch>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SettingsPage;
