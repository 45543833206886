import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import SettingsPage from '../pages/Settings/';

const SettingsPageContainer = props =>
  (props.currentAccount.isSignedIn ? <SettingsPage {...props} /> : <Redirect to="/" />);

SettingsPageContainer.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
};

const mapStateToProps = ({ utils: { region }, auth: { currentAccount } }) => ({
  region,
  currentAccount,
});

export default connect(
  mapStateToProps,
  {},
)(SettingsPageContainer);
