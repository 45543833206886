/* eslint-disable camelcase */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LfgFilters from '../components/LfgFilters';
import {
  searchGroups as searchGroupsAction,
  resetSearchGroups as resetSearchGroupsAction,
  setLfgFilters as setLfgFiltersAction,
} from '../actions/groups';

class LfgFiltersContainer extends Component {
  constructor(props) {
    super(props);

    const mainCharacter = props.characters.find(char => char.main);
    if (mainCharacter) {
      props.setLfgFilters({ character_id: mainCharacter.id });
    }
  }

  componentDidMount() {
    const { resetSearchGroups, searchGroups } = this.props;
    const { bracket, character_id } = this.props.filters;

    if (character_id && bracket) {
      searchGroups({ bracket, character_id });
    } else {
      resetSearchGroups();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.characters.length && !this.props.characters.length) {
      const mainCharacter = nextProps.characters.find(char => char.main);

      if (mainCharacter) {
        this.props.setLfgFilters({ character_id: mainCharacter.id });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    const { filters: prevFilters } = prevProps;

    if (
      filters.bracket &&
      filters.character_id &&
      (prevFilters.bracket !== filters.bracket || prevFilters.character_id !== filters.character_id)
    ) {
      this.props.searchGroups({ bracket: filters.bracket, character_id: filters.character_id });
    }
  }

  render() {
    return <LfgFilters {...this.props} />;
  }
}

LfgFiltersContainer.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  searchGroups: PropTypes.func.isRequired,
  resetSearchGroups: PropTypes.func.isRequired,
  characters: PropTypes.array.isRequired,
  setLfgFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  auth, utils, characters, groups,
}) => {
  const { currentAccount } = auth;
  const { region } = utils;
  const { connectedCharacters } = characters;
  const { filters } = groups;

  return {
    currentAccount,
    region,
    characters: connectedCharacters,
    filters,
  };
};

export default connect(
  mapStateToProps,
  {
    searchGroups: searchGroupsAction,
    resetSearchGroups: resetSearchGroupsAction,
    setLfgFilters: setLfgFiltersAction,
  },
)(LfgFiltersContainer);
