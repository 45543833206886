import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleSignIn as handleSignInAction } from '../actions/auth';
import SignInForm from '../components/SignInForm';

const SignInFormContainer = props => <SignInForm {...props} />;

SignInFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(
  undefined,
  {
    handleSubmit: handleSignInAction,
  },
)(SignInFormContainer);
