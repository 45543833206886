import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup'; // for everything

import ModifiedAlert from 'app/components/Alert';
import '../SignInForm/SignInForm.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('is not a valid email')
    .required('is required'),
  password: yup.string().required('is required'),
});

const SignUpForm = ({ handleSubmit }) => (
  <div className="SignUpForm">
    <h1>Register</h1>
    <p>Join our PvP Community and get access to the LFG and all the cool stuff that's coming up!</p>

    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-control">
            <label>Email</label>

            <ErrorMessage name="email" component="span" className="error" />
            <Field name="email" label="email" placeholder="Account Email" autoFocus />
          </div>

          <div className="form-control">
            <label>Password</label>

            <ErrorMessage name="password" component="span" className="error" />
            <Field
              name="password"
              label="password"
              type="password"
              placeholder="Account Password"
            />
          </div>

          <div className="email-alert">
            <ModifiedAlert
              text="We hate spam and know you do too. We'll keep your email safe and only send you important emails related to your usage of the app."
              severity="info"
              styleAlert={{ marginBottom: 20, fontSize: 16 }}
            />
          </div>

          <Button className="primary-btn" style={{ width: '100%', marginBottom: 20 }} type="submit">
            {isSubmitting ? <CircularProgress style={{ color: '#fff' }} /> : 'Register'}
          </Button>

          <p style={{ fontSize: 14, lineHeight: '16px' }}>
            By clicking Register, you agree to our{' '}
            <Link to="/terms" style={{ color: '#c43244', textDecoration: 'underline' }}>
              Terms
            </Link>{' '}
            and{' '}
            <Link to="/privacy" style={{ color: '#c43244', textDecoration: 'underline' }}>
              Privacy Policy
            </Link>
            , as well as our{' '}
            <Link to="/cookie-policy" style={{ color: '#c43244', textDecoration: 'underline' }}>
              Cookie Policy
            </Link>
            .
          </p>
        </Form>
      )}
    </Formik>
  </div>
);

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
