import React from 'react';
import Grid from '@material-ui/core/Grid';
import LfgFiltersContainer from '../../../containers/LfgFilters';
import GroupSearchContainer from '../../../containers/GroupSearch';
import PatreonCTA from 'app/components/PatreonCTA';
import RichAd from 'app/components/Ads/RichAd';

const SearchGroups = () => (
  <React.Fragment>
    <RichAd vertical={false} />

    <PatreonCTA />

    <Grid item xs={12} sm={12} md={6} lg={4}>
      <LfgFiltersContainer />
    </Grid>

    <Grid item xs={12} sm={12} md={6} lg={8}>
      <GroupSearchContainer />
    </Grid>
  </React.Fragment>
);

export default SearchGroups;
