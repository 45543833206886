import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MetaTags } from 'react-meta-tags';

import {
  fetchCharacterClasses as fetchCharacterClassesAction,
  fetchCharacters,
} from '../actions/characters';
import {
  createGroup as createGroupAction,
  updateGroup as updateGroupAction,
} from '../actions/groups';
import { warningNotification as warningNotificationAction } from '../actions/notifications';
import NewGroup from '../components/NewGroup';
import { isServer } from '../utils/helpers';

class NewGroupPageContainer extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    characters: PropTypes.array.isRequired,
    characterClasses: PropTypes.array.isRequired,
    fetchCharacterClasses: PropTypes.func.isRequired,
    warningNotification: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired,
    group: PropTypes.object,
  };

  static defaultProps = {
    group: null,
  };

  state = {
    step: 1,
    specialization_ids: [],
    title: '',
    description: '',
    bracket: '3v3',
    character_id: null,
    min_cr: 0,
    max_cr: 4000,
    min_exp: 0,
    max_exp: 4000,
  };

  componentDidMount() {
    const { characters, characterClasses, fetchCharacterClasses, group } = this.props;

    if (characterClasses.length === 0) {
      fetchCharacterClasses();
    }

    if (characters.length === 0) {
      fetchCharacters();
    }

    // TODO: write in promise style
    if (group) {
      this.setState({ ...group }); // eslint-disable-line
    } else if (characters.length > 0 && !this.state.character_id) {
      const mainCharacter = characters.find(char => char.main);

      if (mainCharacter) {
        this.setState({ character_id: mainCharacter.id }); // eslint-disable-line
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.characters.length && !this.props.characters.length) {
      const mainCharacter = nextProps.characters.find(char => char.main);

      if (mainCharacter) {
        this.setState({ character_id: mainCharacter.id });
      }
    }
  }

  handleChangeSpecialization = id => event => {
    const { checked } = event.target;

    if (checked) {
      this.setState(prevState => ({ specialization_ids: [...prevState.specialization_ids, id] }));
    } else {
      this.setState(prevState => ({
        specialization_ids: prevState.specialization_ids.filter(specId => specId !== id),
      }));
    }
  };

  handleSelectCharacter = id => {
    this.setState({ character_id: id });
  };

  handleChangeBracket = bracket => {
    this.setState({ bracket });
  };

  goToStep = step => {
    const valid = step === 2 ? this.validateStepOne() : true;

    if (valid) {
      this.setState({ step });
      if (!isServer()) window.scrollTo(0, 0); // eslint-disable-line no-undef
    } else {
      this.props.warningNotification('Please fill in all the fields before proceeding to step 2.');
    }
  };

  handleChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  handleChangeDescription = event => {
    this.setState({ description: event.target.value });
  };

  handleChangeCr = values => {
    this.setState({ min_cr: values.min, max_cr: values.max });
  };

  handleChangeExp = values => {
    this.setState({ min_exp: values.min, max_exp: values.max });
  };

  validateStepOne = () => {
    const { title, description } = this.state;

    if (title.length && description.length) {
      return true;
    }

    return false;
  };

  validateStepTwo = () => !!this.state.specialization_ids.length;

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const { group, createGroup, updateGroup } = this.props;

    if (this.validateStepOne() && this.validateStepTwo()) {
      if (group) {
        updateGroup(group.id, { ...this.state });
      } else {
        createGroup({ ...this.state });
      }
    }
  };

  render() {
    return (
      <Grid container>
        <MetaTags>
          <title>Create Group | ArenaMaster</title>
        </MetaTags>

        <Grid item sm={12}>
          <NewGroup
            {...this.props}
            fields={this.state}
            handleChangeBracket={this.handleChangeBracket}
            handleChangeCr={this.handleChangeCr}
            handleChangeDescription={this.handleChangeDescription}
            handleChangeExp={this.handleChangeExp}
            handleChangeSpecialization={this.handleChangeSpecialization}
            handleChangeTitle={this.handleChangeTitle}
            handleSelectCharacter={this.handleSelectCharacter}
            handleSubmit={this.handleSubmit}
            goToStep={this.goToStep}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  auth: { currentAccount },
  characters: { connectedCharacters, characterClasses },
}) => ({
  currentAccount,
  characters: connectedCharacters,
  characterClasses,
});

export default connect(mapStateToProps, {
  fetchCharacterClasses: fetchCharacterClassesAction,
  warningNotification: warningNotificationAction,
  createGroup: createGroupAction,
  updateGroup: updateGroupAction,
})(NewGroupPageContainer);
