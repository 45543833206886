/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getClassIcon, getClassColor } from '../../utils/helpers';
import LoaderContainer from '../../containers/Loader';

const StepTwo = ({
  group,
  characterClasses,
  fields: { specialization_ids },
  handleChangeSpecialization,
  handleSubmit,
}) => (
  <div className="specializations-container">
    {characterClasses.map(cc => (
      <div className="class-specialization" key={cc.id}>
        <div className="class-name">
          <img src={getClassIcon(cc.name)} alt={cc.name} />
          {cc.name}
        </div>

        {cc.specializations.map(spec => (
          <FormControlLabel
            key={spec.id}
            className="class-checkboxes"
            style={{
              fill: specialization_ids.includes(spec.id) ? getClassColor(cc.name) : '#fff',
            }}
            label={spec.name}
            control={
              <div style={{ position: 'relative' }}>
                <Checkbox
                  checked={specialization_ids.includes(spec.id)}
                  onChange={handleChangeSpecialization(spec.id)}
                  value="checked"
                />
              </div>
            }
          />
        ))}
      </div>
    ))}

    <Button
      className={group ? 'update-btn primary-btn' : 'create-group-button primary-btn'}
      onClick={handleSubmit}
    >
      <LoaderContainer styleLoader={{ color: '#fff' }} /> {group ? 'Update Group' : 'Create Group'}
    </Button>
  </div>
);

StepTwo.propTypes = {
  characterClasses: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  handleChangeSpecialization: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  group: PropTypes.object,
};

StepTwo.defaultProps = {
  group: null,
};

export default StepTwo;
