/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import ModifiedAlert from '../Alert';
import './NewGroup.scss';

const NewGroup = props => {
  const {
    group,
    fields: { step },
    goToStep,
    currentAccount,
  } = props;

  return (
    <Grid container>
      {!currentAccount.bnet_connected ? (
        <ModifiedAlert text="You need to connect with Battle.net first." severity="info" />
      ) : (
        <Grid item sm={12}>
          <div id="new-group">
            <div className="new-group-header">
              <div className="header-options">
                <h2>{group ? 'UPDATE GROUP' : 'CREATE GROUP'}</h2>

                <div className="steps">
                  <Button className="step-button" onClick={() => goToStep(1)}>
                    <div className={step === 1 ? 'active' : ''}>1</div>
                    <span>Rating range and details</span>
                  </Button>

                  <Button className="step-button" onClick={() => goToStep(2)}>
                    <div className={step === 2 ? 'active' : ''}>2</div>
                    <span>Select preferred classes</span>
                  </Button>
                </div>

                <Link to="/lfg">
                  <IconButton className="close-icon">
                    <CloseIcon />
                  </IconButton>
                </Link>
              </div>
            </div>

            <React.Fragment>
              {step === 1 && <StepOne {...props} patreon={currentAccount.patreon} />}
              {step === 2 && <StepTwo {...props} />}
            </React.Fragment>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

NewGroup.propTypes = {
  goToStep: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  group: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
};

NewGroup.defaultProps = {
  group: null,
};

export default NewGroup;
