/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import SwitchButton from '../../components/SwitchButton';
import LoaderContainer from '../../containers/Loader';
import { isServer } from '../../utils/helpers';
import notificationInstructions from 'assets/notification-instructions.png';
import './AccountNotifications.scss';

class AccountNotifications extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      settings: {
        web_notifications,
        new_follower,
        lfg_email_notifications,
        addon_update,
        feature_announcements,
      },
    } = props.currentAccount;

    this.state = {
      web_notifications,
      new_follower,
      lfg_email_notifications,
      addon_update,
      feature_announcements,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestWebNotifications = this.requestWebNotifications.bind(this);
  }

  handleChange(name) {
    this.setState(prevState => ({ [name]: !prevState[name] }));
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const { handleSubmit } = this.props;

    handleSubmit({
      settings: {
        ...this.state,
      },
    });
  }

  requestWebNotifications() {
    if (isServer()) return;
    if (!('Notification' in window)) return; // eslint-disable-line no-undef

    const { web_notifications } = this.state;
    const { handleSubmit } = this.props;

    // eslint-disable-next-line no-undef
    Notification.requestPermission().then(response => {
      if (response === 'granted' && !web_notifications) {
        this.setState({ web_notifications: true });
        new Notification('Web notifications look like this!', { body: 'Pretty cool ey?' }); // eslint-disable-line
      } else if (response === 'granted' && web_notifications) {
        this.setState({ web_notifications: false });
      }

      handleSubmit({
        settings: {
          ...this.state,
        },
      });
    });
  }

  render() {
    const {
      web_notifications,
      addon_update,
      new_follower,
      lfg_email_notifications,
      feature_announcements,
    } = this.state;

    return (
      <div className="notifications">
        <form onSubmit={this.handleSubmit}>
          <div className="switch-button-box" style={{ marginBottom: 12 }}>
            <h5 style={{ marginBottom: '10px' }}>Web Notifications</h5>
            <p>Do you want to get notifications even when alt-tabbed?</p>
            <SwitchButton checked={web_notifications} handleChange={this.requestWebNotifications} />
          </div>

          <div style={{ color: '#c0c0c0', marginBottom: 48 }}>
            If the switch for the web notifications isn't working,{' '}
            <Tooltip
              placement="top"
              title={<img src={notificationInstructions} alt="Browser Notifications Tooltip" />}
              classes={{ tooltip: 'browser-notification-tooltip' }}
            >
              <span className="span-hover">enable notifications</span>
            </Tooltip>{' '}
            in the top left.
          </div>

          <h5 style={{ marginBottom: '10px', fontSize: '18px' }}>TYPE OF NOTIFICATIONS</h5>
          <p style={{ marginBottom: '15px' }}>
            Choose what kind of notifications you want to receive.
          </p>

          <div className="switch-button-box">
            <h5>Important Announcement Emails</h5>
            <p style={{ marginTop: 10, position: 'relative' }}>
              Get notified via email when the website and addon get new features or we have an
              important announcement.
            </p>

            <SwitchButton
              checked={feature_announcements}
              handleChange={() => this.handleChange('feature_announcements')}
            />
          </div>

          <div className="switch-button-box">
            <h5>LFG Email Notifications</h5>
            <p style={{ marginTop: 10, position: 'relative' }}>
              Get notified via email when you have a new group application, or some of your
              applications get approved.
            </p>

            <SwitchButton
              checked={lfg_email_notifications}
              handleChange={() => this.handleChange('lfg_email_notifications')}
            />
          </div>

          <div className="switch-button-box">
            <h5>Addon Updates</h5>
            <p style={{ marginTop: 10, position: 'relative' }}>
              Get notified in-app when a new <strong>major</strong> version of the addon becomes
              available on CurseForge.
            </p>

            <SwitchButton
              checked={addon_update}
              handleChange={() => this.handleChange('addon_update')}
            />
          </div>

          <div className="switch-button-box">
            <h5>New Follower</h5>
            <p style={{ marginTop: 10, position: 'relative' }}>
              Get notified in-app when someone follows one of your characters.
            </p>

            <SwitchButton
              checked={new_follower}
              handleChange={() => this.handleChange('new_follower')}
            />
          </div>

          <Button
            variant="contained"
            id="save-button"
            type="submit"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className="primary-btn"
          >
            <LoaderContainer styleLoader={{ color: '#fff' }} /> Save
          </Button>
        </form>
      </div>
    );
  }
}

export default AccountNotifications;
