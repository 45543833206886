import React, { useState } from 'react';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';

import SignUpButtonContainer from 'containers/SignUpButton';
import RegionChangeContainer from 'containers/RegionChange';
import { MobileToSmallLaptop } from 'app/utils/responsive';
import { isServer } from 'utils/helpers';
import ProfileDropdown from '../ProfileDropdown';
import CharacterSearchContainer from 'containers/CharacterSearch';
import NotificationsButton from '../NotificatonsButton';
import './Menu.scss';

const Menu = ({ region, currentAccount, handleSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const matchTbcRankings = useRouteMatch('/tbc-rankings/');
  const [connectedCount, setConnectedCount] = useState(0);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const wrappedHandleClick = () => (!isServer() && window.innerWidth < 1140 ? handleClick() : null);

  return (
    <React.Fragment>
      <div id="menu-container" className={isOpen ? 'open' : 'close'}>
        <NavLink
          className="menu-link"
          activeClassName="selected-link"
          to="/lfg"
          onClick={wrappedHandleClick}
        >
          Looking for Group?
        </NavLink>

        <NavLink
          to="/rankings"
          className="menu-link"
          activeClassName="selected-link"
          style={{ paddingLeft: 0 }}
          onClick={wrappedHandleClick}
        >
          Rankings
        </NavLink>

        <NavLink
          className="menu-link"
          activeClassName="selected-link"
          to="/addon"
          style={{ paddingLeft: 0 }}
          onClick={wrappedHandleClick}
        >
          Addon
        </NavLink>

        <NavLink
          className="menu-link"
          activeClassName="selected-link"
          to="/ladder-stats"
          style={{ paddingLeft: 0 }}
          onClick={wrappedHandleClick}
        >
          Ladder Stats
        </NavLink>

        <NavLink
          className="menu-link"
          activeClassName="selected-link"
          to="/twitch"
          style={{ paddingLeft: 0 }}
          onClick={wrappedHandleClick}
        >
          Twitch
        </NavLink>

        {/* <NavLink
          className="menu-link"
          activeClassName="selected-link"
          to={`/tbc-rankings/${region.toLowerCase()}/3v3`}
          style={{ paddingLeft: 0 }}
          onClick={wrappedHandleClick}
          isActive={() => (matchTbcRankings ? true : false)}
        >
          TBC Rankings
        </NavLink> */}

        {['/home-test', '/addon-test'].includes(location.pathname) && (
          <React.Fragment>
            <NavLink
              to="/home-test"
              className="menu-link"
              activeClassName="selected-link"
              style={{ paddingLeft: 0 }}
              onClick={wrappedHandleClick}
            >
              Home Test
            </NavLink>

            <NavLink
              to="/addon-test"
              className="menu-link"
              activeClassName="selected-link"
              style={{ paddingLeft: 0 }}
              onClick={wrappedHandleClick}
            >
              Addon Test
            </NavLink>
          </React.Fragment>
        )}

        {location.pathname !== '/' && <CharacterSearchContainer />}

        <Grid
          className="register"
          style={{ marginLeft: location.pathname === '/' ? 'auto' : '0' }}
          item
        >
          {currentAccount.isSignedIn && currentAccount.admin && (
            <Chip
              style={{ marginRight: 10 }}
              size="medium"
              label={`Connected: ${+connectedCount + 1}`}
              color="primary"
            />
          )}

          <RegionChangeContainer />

          {!currentAccount.isSignedIn && (
            <React.Fragment>
              <SignUpButtonContainer />
            </React.Fragment>
          )}

          {currentAccount.isSignedIn && (
            <React.Fragment>
              <NotificationsButton />

              <ProfileDropdown currentAccount={currentAccount} handleSignOut={handleSignOut} />
            </React.Fragment>
          )}
        </Grid>
      </div>

      <MobileToSmallLaptop>
        <IconButton onClick={handleClick} style={{ marginLeft: 'auto' }}>
          {isOpen ? (
            <CloseIcon fontSize="large" style={{ color: '#c43244' }} />
          ) : (
            <MenuIcon fontSize="large" style={{ color: '#c43244' }} />
          )}
        </IconButton>
      </MobileToSmallLaptop>
    </React.Fragment>
  );
};

Menu.propTypes = {
  region: PropTypes.string.isRequired,
  currentAccount: PropTypes.object.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default Menu;
