import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import './Hero.scss';

const Hero = ({ bgImage }) => {
  const matchHome = useRouteMatch('/');

  return (
    <div
      role="main"
      id="hero"
      style={
        bgImage
          ? {
              backgroundImage: `url(${bgImage})`,
            }
          : {}
      }
      className={bgImage && !matchHome.isExact ? 'hero-tbc' : ''}
    />
  );
};

Hero.propTypes = {
  bgImage: PropTypes.string,
};

Hero.defaultProps = {
  bgImage: null,
};

export default Hero;
