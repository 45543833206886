import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import { openAuthModal } from '../actions/auth';

const SignUpButtonContainer = ({ handleClick, text, action }) => (
  <Button className="register-button primary-btn" onClick={() => handleClick(action)}>
    {text}
  </Button>
);

SignUpButtonContainer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  action: PropTypes.string,
};

SignUpButtonContainer.defaultProps = {
  text: 'Login / Register',
  action: 'SignIn',
};

export default connect(undefined, {
  handleClick: openAuthModal,
})(SignUpButtonContainer);
